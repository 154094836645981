<template>
  <div>
    <div class="my-5">
      <div class="columns is-centered" v-if="students.length === 0">
        <div class="column is-10">
          <div class="card is-border-light-grey">
            <div class="card-content">
              <div class="content">
                <div class="subtitle has-text-centered">
                  No se encontraron estudiantes con esta información.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-for="(student, index) in paginatedStudents" :key="index">
        <div class="columns is-centered">
          <div class="column is-10">
            <StudentCard :student="student"></StudentCard>
          </div>
        </div>
      </div>
    </div>
    <b-pagination
      v-if="students.length > perPage"
      v-model="currentPage"
      :total="students.length"
      :per-page="perPage"
      order="is-right"
    >
    </b-pagination>
  </div>
</template>
<script>
import StudentCard from "./StudentCard.vue";

export default {
  components: { StudentCard },
  props: ["students"],
  data() {
    return {
      currentPage: 1,
      perPage: 5,
    };
  },
  computed: {
    paginatedStudents() {
      return this.students.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
  },
};
</script>
