/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

// import Vue from 'vue'
// import App from '../app.vue'

// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     render: h => h(App)
//   }).$mount()
//   document.body.appendChild(app.$el)

//   console.log(app)
// })

//----------------------------------------------------------------------------------------------------------------------
// Import from node modules
import {turbolinksAdapterMixin} from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import App from '../app.vue'
import Buefy from 'buefy'
//import 'buefy/dist/buefy.css'
import '../stylesheets/styles.scss'
import '../stylesheets/estilos'
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import Multiselect from 'vue-multiselect'
import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
import {
  required,
  email,
  min_value,
  max_value,
  integer,
  numeric,
  min,
  max,
  regex,
  alpha_num,
  length,
  alpha_dash,
  between
} from 'vee-validate/dist/rules';

//----------------------------------------------------------------------------------------------------------------------
// Use and create components for integrate them into views
library.add(fas)
//Vue.use(TurbolinksAdapter)
Vue.component('vue-fontawesome', FontAwesomeIcon);
Vue.component('multiselect', Multiselect)
Vue.use(Buefy, {defaultIconPack: 'fas'})

//----------------------------------------------------------------------------------------------------------------------
// Import set up for CRSF
import setupCSRF from "./setupCSRF";

//----------------------------------------------------------------------------------------------------------------------
// Components created in folder /components must be imported below
// Components
import welcome from '../components/welcome.vue'
import MenuRequestsReviewer from "../components/Requests/MenuRequestsReviewer";

import platform from "../components/platform.vue";

import SpecificRequestReviewer from "../components/Requests/reviewer/SpecificRequestReviewer.vue";
import ListRequests from "../components/Requests/ListRequests";
import NewRequest from "../components/Requests/NewRequest"
import VerifyCode from "../components/DocumentToken/VerifyCode.vue";
import CreateRequestType from "../components/RequestType/CreateRequestType";
import PageSidebar from '../components/page_sidebar';
import ListRequestTypes from '../components/RequestType/ListRequestTypes'
import ShowRequestType from "../components/RequestType/ShowRequestType";
import MyRequests from "../components/Requests/applicant/MyRequests.vue"
import ChooseRequestType from "../components/RequestType/ChooseRequestType"
import ChooseRequestTypeStudent from "../components/RequestType/ChooseRequestTypeStudent"
import ChooseRequestTypeStudentGcc from "../components/RequestType/ChooseRequestTypeStudentGcc"
import ShowRequestApplicant from "../components/Requests/applicant/ShowRequestApplicant.vue"
import Statistics from "../components/RequestType/Statistics"
import RevisionHistory from "../components/Requests/RevisionHistory"
import EditRequestType from "../components/RequestType/EditRequestType"
import TutorialCreateRequest from "../components/Tutorials/TutorialCreateRequest"
import GsdHome from "../components/Gsd/Home"
import GsdFaq from "../components/Gsd/Faq"
import GsdContactUs from "../components/Gsd/ContactUs"
import GsdAboutUs from "../components/Gsd/AboutUs"
import GsdAppointment from "../components/Gsd/Appointment";
import GccHome from "../components/Gcc/Home"
import GccFaq from "../components/Gcc/Faq"
import GccContactUs from "../components/Gcc/ContactUs"
import audit from "../components/Audit/audit"
import GeneralAudit from "../components/Audit/GeneralAudit"
import RequestAudit from "../components/Audit/RequestAudit"
import UserAudit from "../components/Audit/UserAudit"
import UserManagement from "../components/Dashboards/UserManagement.vue"
import GccStatistics from "../components/Statistics/GccStatistics.vue"
import StudentManagement from "../components/Dashboards/StudentManagement.vue";
import TokenManagement from "../components/Dashboards/TokenManagement.vue";
import LoginInfo from "../components/LoginInfo.vue";
import EnableStudents from "../components/Students/EnableStudents.vue";
import IndexEnabledStudents from "../components/Students/IndexEnabledStudents.vue";
import RequestTypeSearch from "../components/RequestType/RequestTypeSearch.vue";
import UserCodeManagement from "../components/Dashboards/UserCodeManagement.vue"
import StudentSearch from "../components/Students/StudentSearch.vue";

// Signatures
import SimpleDigitalSignatureView from "../components/Signatures/SimpleDigitalSignatureView.vue";
import MassiveSimpleDigitalSignatureView from "../components/Signatures/MassiveSimpleDigitalSignatureView.vue";
import MassiveMultipleSimpleDigitalSignatureView from "../components/Signatures/MassiveMultipleSimpleDigitalSignatureView.vue";

import PaymentSummary from "../components/Requests/PaymentSummary.vue";


//----------------------------------------------------------------------------------------------------------------------
// Import validators from Vee Validate
extend('between', {...between, message: "Valor no se encuentra entre los números permitidos"});
extend('email', {...email, message: 'No es un email válido'});
extend('min_value', {...min_value, params: ['min'], message: "El valor no puede ser menor a {min}.",});
extend('max_value', {...max_value, params: ['max'], message: "El valor no puede ser mayor a {max}.",});
extend('required', {...required, message: 'Este campo es requerido'});
extend('numeric', {...numeric, message: 'Este campo debe ser númerico'});
extend('positive', value => {
  if (value >= 0) {
    return true;
  }
  return 'Este campo debe ser mayor a 0';
});
extend('integer', {...integer, message: 'Este campo debe ser un número entero'});
extend('alpha_num', {...alpha_num, message: 'Este campo debe ser un número o letra'});
extend('alpha_dash', {...alpha_dash, message: 'Este campo debe ser un número, letra, espacios, guiones o guión bajo'});
extend('length', {
  validate(value, {length}) {
    return value.length === length;
  }, params: ['length'], message: 'El campo debe tener {length} caracteres'
});
extend('min', {
  validate(value, {length}) {
    return value.length >= length;
  }, params: ['length'], message: 'El campo debe tener al menos {length} caracteres'
});
extend('max', {
  validate(value, {length}) {
    return value.length <= length;
  }, params: ['length'], message: 'El campo debe tener máximo {length} caracteres'
});
extend('regex', {...regex, message: 'El campo tiene caracteres invalidos'});
extend('url_form', value => {
  if (String(value).startsWith('https://docs.google.com/forms/d/')) {
    return true
  }
  return 'Debe ingresar una url valida'
})

extend('same_as', {
  params: ['target'],
  validate: (value, {target}) => value === target,
  message: 'Los campos no coinciden'
});


extend('at_least_two_filled', {
  validate(value, { name, lastname, surname }) {
    const fields = [name, lastname, surname];
    const filledFields = fields.filter(field => field && field.trim() !== '');
    return filledFields.length >= 2;
  },
  params: ['name', 'lastname', 'surname'],
  message: 'Al menos dos de los tres campos deben tener información.'
});


//----------------------------------------------------------------------------------------------------------------------
// Create Vue App and add components
document.addEventListener('turbolinks:load', () => {

  setupCSRF();
  const app = new Vue({
    el: '#app',
    data: () => {
      return {
        globalToggle: false,
        message: "Can you say hello?"
      }
    },
    mixins: [turbolinksAdapterMixin],
    // Components used in views *.html.erb must be added below
    components: {
      App,
      VerifyCode,
      welcome,
      platform,
      CreateRequestType,
      MenuRequestsReviewer,
      SpecificRequestReviewer,
      ListRequests,
      NewRequest,
      PageSidebar,
      ListRequestTypes,
      ShowRequestType,
      MyRequests,
      ChooseRequestType,
      ChooseRequestTypeStudent,
      ChooseRequestTypeStudentGcc,
      ShowRequestApplicant,
      Statistics,
      RevisionHistory,
      EditRequestType,
      TutorialCreateRequest,
      GsdFaq,
      GsdHome,
      GsdContactUs,
      GsdAboutUs,
      GsdAppointment,
      GccFaq,
      GccHome,
      GccContactUs,
      audit,
      GeneralAudit,
      RequestAudit,
      UserAudit,
      UserManagement,
      GccStatistics,
      StudentManagement,
      TokenManagement,
      LoginInfo,
      SimpleDigitalSignatureView,
      MassiveSimpleDigitalSignatureView,
      EnableStudents,
      IndexEnabledStudents,
      RequestTypeSearch,
      MassiveMultipleSimpleDigitalSignatureView,
      UserCodeManagement,
      PaymentSummary,
      StudentSearch,

        },
        mounted() {
            const mediaQueryTablet = window.matchMedia("(max-width: 1023px)");
            this.isTablet = mediaQueryTablet.matches;
            mediaQueryTablet.addEventListener("change", (event) => {
                this.isTablet = event.matches;
            });
        }
    })
})


// The above code uses Vue without the compiler, which means you cannot
// use Vue to target elements in your existing html templates. You would
// need to always use single file components.
// To be able to target elements in your existing html/erb templates,
// comment out the above code and uncomment the below
// Add <%= javascript_pack_tag 'hello_vue' %> to your layout
// Then add this markup to your html template:
//
// <div id='hello'>
//   {{message}}
//   <app></app>
// </div>


// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })
//
//
//
// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//
// import TurbolinksAdapter from 'vue-turbolinks'
// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// Vue.use(TurbolinksAdapter)
//
// document.addEventListener('turbolinks:load', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: () => {
//       return {
//         message: "Can you say hello?"
//       }
//     },
//     components: { App }
//   })
// })
