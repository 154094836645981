<template>
  <!-- Table Section -->

  <b-table
    :data="requests == null ? [] : requests"
    :paginated="true"
    pagination-position="bottom"
    per-page="10"
    :bordered="false"
    :narrowed="false"
    :focusable="false"
    :mobile-cards="true"
    :detailed="applicant_information"
    detail-key="id"
    :show-detail-icon="showDetailIcon"
    detail-icon="plus-circle"
    :opened-detailed="defaultOpenedDetails"
    :backend-pagination="backendPagination"
    @page-change="onPageChange"
    :total="totalRequests"
    :current-page="currentPageTable"
  >
    <!-- Column Codigo-->
    <b-table-column
      field="code"
      :visible="true"
      label="Código"
      :searchable="!backendPagination"
      :sortable="!backendPagination"
      centered
    >
      <template slot="searchable" slot-scope="props">
        <b-input
          v-model="props.filters[props.column.field]"
          placeholder="Buscar por código"
          icon="search"
          size="is-small"
        />
      </template>
      <template v-slot="props">
        {{ props.row.code != null ? props.row.code : "-" }}
      </template>
    </b-table-column>
    <b-table-column
      v-if="reviewer_info"
      field="reviewer_name"
      :visible="true"
      label="Revisión por"
      :searchable="!backendPagination"
      :sortable="!backendPagination"
      centered
    >
      <template slot="searchable" slot-scope="props">
        <b-input
          v-model="props.filters[props.column.field]"
          placeholder="Buscar por código"
          icon="search"
          size="is-small"
        />
      </template>
      <template v-slot="props">
        {{ props.row.request_state_reviewer != null ? props.row.request_state_reviewer : "-" }}
      </template>
    </b-table-column>
    <!-- Column Solicitante -->
    <b-table-column
      v-if="applicant_information"
      field="applicant"
      :visible="true"
      centered
      label="Solicitante"
      width="10em"
      :searchable="!backendPagination"
      :sortable="!backendPagination"
    >
      <template slot="searchable" slot-scope="props">
        <b-input
          v-model="props.filters[props.column.field]"
          placeholder="Buscar por solicitante"
          icon="search"
          size="is-small"
        />
      </template>
      <template v-slot="props">
        {{ props.row.applicant != null ? props.row.applicant : "-" }}
      </template>
    </b-table-column>
    <b-table-column
      v-if="applicant_information"
      field="run"
      :visible="true"
      centered
      label="RUT"
      :searchable="!backendPagination"
      :sortable="!backendPagination"
    >
      <template slot="searchable" slot-scope="props">
        <b-input
          v-model="props.filters[props.column.field]"
          placeholder="Buscar por run"
          icon="search"
          size="is-small"
        />
      </template>
      <template v-slot="props">
        {{ props.row.run != null ? props.row.run : "-" }}
      </template>
    </b-table-column>

    <b-table-column
      field="request_type"
      :visible="true"
      label="Tipo de Solicitud"
      centered
      :searchable="!backendPagination"
      :sortable="!backendPagination"
      width="10em"
    >
      <template slot="searchable" slot-scope="props">
        <b-input
          v-model="props.filters[props.column.field]"
          placeholder="Buscar por tipo de solicitud"
          icon="search"
          size="is-small"
        />
      </template>
      <template v-slot="props">
        {{ props.row.request_type != null ? props.row.request_type : "-" }}
      </template>
    </b-table-column>

    <b-table-column
      field="unit"
      :visible="true"
      label="Unidad"
      :searchable="!backendPagination"
      :sortable="!backendPagination"
      centered
    >
      <template slot="searchable" slot-scope="props">
        <b-input
          v-model="props.filters[props.column.field]"
          placeholder="Buscar por unidad"
          icon="search"
          size="is-small"
        />
      </template>
      <template v-slot="props">
        {{ props.row.unit != null ? props.row.unit : "-" }}
      </template>
    </b-table-column>

    <b-table-column
      field="progress_of_request"
      :visible="true"
      label="Estado"
      :searchable="!backendPagination"
      :sortable="!backendPagination"
      centered
    >
      <template slot="searchable" slot-scope="props">
        <b-input
          v-model="props.filters[props.column.field]"
          placeholder="Buscar por progreso"
          icon="search"
          size="is-small"
        />
      </template>
      <template v-slot="props">
        <!--        Se aplica la clase de centrado si no esta la informacion del solicitante, en caso de revisor se coloca a la izquierda-->
        <div
          :class="{
            columns: true,
            'has-text-centered': !applicant_information,
            'is-pulled-left': applicant_information,
          }"
        >
          <div class="column mx-0 px-0">
            <b-tag :class="getStateInfo(props.row.progress_of_request).color">
              <b-icon :icon="getStateInfo(props.row.progress_of_request).icon">
              </b-icon>
              {{ props.row.progress_of_request }}
            </b-tag>
          </div>
          <div
            v-if="props.row.can_view_extras"
            class="column is-narrow mx-0 px-0"
          >
            <b-icon
              v-if="props.row.priority"
              icon="star"
              type="is-warning"
            ></b-icon>
            <b-icon
              v-if="props.row.pending_appeal"
              icon="comments"
              type="is-info"
            ></b-icon>
            <b-tooltip
              multilined
              label="El cambio de estado a 'Aceptada' se encuentra programado para el siguiente día hábil a las 08:00 hrs."
              type="is-info"
            >
              <b-icon
                v-if="props.row.is_scheduled"
                icon="clock"
                type="is-info"
              ></b-icon>
            </b-tooltip>
          </div>
        </div>
      </template>
    </b-table-column>

    <b-table-column
      field="start_date"
      :visible="true"
      label="Fecha Inicio"
      :searchable="!backendPagination"
      :sortable="!backendPagination"
      centered
      :custom-sort="customSortStartDate"
    >
      <template slot="searchable" slot-scope="props">
        <b-input
          v-model="props.filters[props.column.field]"
          placeholder="Buscar por fecha"
          icon="search"
          size="is-small"
        />
      </template>
      <template v-slot="props">
        {{ props.row.start_date != null ? props.row.start_date : "-" }}
      </template>
    </b-table-column>
    <b-table-column
      v-if="applicant_information"
      field="end_date"
      :visible="true"
      label="Fecha finalización"
      :searchable="!backendPagination"
      :sortable="!backendPagination"
      centered
      :custom-sort="customSortEndDate"
    >
      <template slot="searchable" slot-scope="props">
        <b-input
          v-model="props.filters[props.column.field]"
          placeholder="Buscar por fecha"
          icon="search"
          size="is-small"
        />
      </template>
      <template v-slot="props">
        {{ props.row.end_date != null ? props.row.end_date : "-" }}
      </template>
    </b-table-column>

    <b-table-column
      field="id"
      :visible="true"
      label="Acciones"
      centered
      v-slot="props"
    >
      <div class="is-flex is-wrap-wrap">
        <!--        Si esta la columna de informacion del solicitante es la vista de revisores-->
        <template v-if="applicant_information">
          <!--          Si no esta revisando o esta finalizada se ve el ojo-->
          <b-button
            v-if="!props.row.is_reviewing || props.row.is_finished"
            class="mr-1 is-secondary is-light is-border-cornflowerblue"
            tag="a"
            :href="'/requests/' + props.row.id"
            target="_blank"
          >
            <b-icon type="is-secondary" icon="eye" size="is-medium"> </b-icon>
          </b-button>
          <!--          Si esta revisando se ve el de revisión-->
          <b-button
            v-else
            class="mr-1 is-success is-light is-border-darkgreen"
            tag="a"
            :href="'/requests/' + props.row.id"
            target="_blank"
          >
            <b-icon type="is-green" icon="file-signature" size="is-medium">
            </b-icon>
          </b-button>

          <!--Si puede revisar, no esta haciendolo ni esta finalizada y se debe colocar la columna de accciones se muestra la posibilidad de asignarse la soliccitud-->
          <b-button
            v-if="
              props.row.can_review &&
              !props.row.is_reviewing &&
              !props.row.is_finished &&
              props.row.progress_of_request !== 'Detenida' &&
              actions
            "
            class="mr-1 is-primary is-light is-border-orange"
            @click="
              openDialogApproveState(
                props.row.request_state_id,
                props.row.id,
                props.row.request_state_reviewer
              )
            "
          >
            <b-icon type="is-primary" icon="file-signature" size="is-medium">
            </b-icon>
          </b-button>
        </template>
        <template v-else>
          <b-button
            class="mr-1 is-secondary is-light is-border-cornflowerblue"
            tag="a"
            :href="'/requests/' + props.row.id"
            target="_blank"
          >
            <b-icon
              class="mr-1"
              type="is-secondary"
              icon="eye"
              size="is-medium"
            >
            </b-icon>
            Ver detalles
          </b-button>
        </template>

        <!--        <a-->
        <!--          v-if="props.row.can_review && actions"-->
        <!--          @click="-->
        <!--            openDialogApproveState(-->
        <!--              props.row.request_state_id,-->
        <!--              props.row.id,-->
        <!--              props.row.request_state_reviewer-->
        <!--            )-->
        <!--          "-->
        <!--        >-->
        <!--          <b-icon-->
        <!--            type="is-primary"-->
        <!--            icon="tasks"-->
        <!--            size="is-medium"-->
        <!--            style="border: 1px solid black; border-radius: 10px"-->
        <!--          >-->
        <!--          </b-icon>-->
        <!--        </a>-->
      </div>
    </b-table-column>

    <template #detail="props">
      <article class="media">
        <div class="media-content">
          <div class="content">
            <p>
              <strong>
                La solicitud se encuentra
                {{
                  props.row.progress_of_request == "Detenida" ? "detenida" : ""
                }}
                en el paso
                {{ props.row.state }}
                {{
                  getSteps(props.row.request_state_id, props.row.request_states)
                }}
              </strong>

              <br />
              Información del paso: {{ props.row.state_information }}
              <br />
              Justificación de la solicitud: {{ props.row.description }}
            </p>
          </div>
        </div>
      </article>
    </template>

    <template #empty>
      <div class="has-text-centered">No se encontraron solicitudes</div>
    </template>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </b-table>
</template>

<script>
import axios from "axios";
import { getStateInfo } from "../../packs/utilities";
export default {
  name: "TableListRequests",
  props: [
    "requests",
    "actions",
    "reviewer_info",
    "applicant_information",
    "backendPagination",
    "totalRequests",
    "loading"
  ],
  data: function () {
    return {
      currentPageTable: 1,
      defaultOpenedDetails: [],
      showDetailIcon: true,
      isLoading: false,
    };
  },
  methods: {
    getStateInfo,
    formatDate(date) {
      date = date.substring(0, 10);
      if (date != null && date !== "" && date !== undefined) {
        var arreglo = date.split("-");
        return arreglo[2] + "-" + arreglo[1] + "-" + arreglo[0];
      } else {
        return "";
      }
    },

    getSteps(request_state_id, request_states) {
      let count = 1;

      for (let i = 0; i < request_states.length; i++) {
        if (request_states[i].id != request_state_id) {
          count = count + 1;
        } else {
          i = request_states.length;
        }
      }

      return "(Paso " + count + " de " + request_states.length + ")";
    },

    openDialogApproveState(requestStateId, requestId, reviewer) {
      if (reviewer == null) {
        this.$buefy.dialog.confirm({
          title: "Asignar solicitud",
          message:
            "Si continua, esta solicitud le será asignada para su revisión en el paso actual y solo usted podrá revisarla. ¿Desea continuar?",
          cancelText: "Cancelar",
          onConfirm: () => {
            this.reviewState(requestStateId, requestId);
          },
        });
      } else {
        this.isLoading = true;
        window.location.href = "/requests/" + requestId;
      }
    },

    reviewState(request_state_id, request_id) {
      this.isLoading = true;
      axios
        .post("/requests/review_state.json", {
          request_state_id: request_state_id,
        })
        .then((response) => {
          window.location.href = "/requests/" + request_id;
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: "Error",
            message: error.response.data.message,
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
            onConfirm: () => {
              window.location.href = "/requests";
            },
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    compareDate(a, b) {
      if (a == null) {
        return -1;
      }
      if (b == null) {
        return 1;
      }
      const dateA = new Date(a.split("-").reverse().join("-"));
      const dateB = new Date(b.split("-").reverse().join("-"));
      return dateA - dateB;
    },

    customSortStartDate(a, b, isAsc) {
      return isAsc
        ? this.compareDate(a.start_date, b.start_date)
        : this.compareDate(b.start_date, a.start_date);
    },
    customSortEndDate(a, b, isAsc) {
      return isAsc
        ? this.compareDate(a.end_date, b.end_date)
        : this.compareDate(b.end_date, a.end_date);
    },
    onPageChange(page) {
      this.currentPageTable = page;
      this.$emit("pageChange", page);
    }
  },
};
</script>
<style></style>
