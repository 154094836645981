<template>
  <div>
    <div class="columns is-centered pb-0 mb-0">
      <div class="column is-full">
        <b-carousel
          icon-size="is-large"
          :interval="6000"
          indicator-style="is-lines"
          :pause-info="false"
        >
          <template v-for="(carousel, i) in carousels">
            <b-carousel-item v-if="carousel.condition" :key="i">
              <a :href="carousel.link">
                <section
                  :class="`hero is-medium is-bold`"
                  :style="`background-color: ${carousel.color};`"
                >
                  <b-image class="image" :src="carousel.image"></b-image>
                </section>
              </a>
            </b-carousel-item>
          </template>
        </b-carousel>
      </div>
    </div>

    <div
      class="container"
      style="padding: 0.1rem; background-color: #f2f2f2; height: 100%"
    >
      <div class="section pt-1" style="background-color: white; height: 100%">
        <!-- Titulo de la sección -->
        <div class="is-flex is-justify-content-center">
          <h1 class="subtitle is-2 has-text-secondary">
            Sistema de Administración de Solicitudes
          </h1>
        </div>
        <!-- Separador -->
        <hr class="solid" />
        <div class="columns is-desktop is-centered">
          <div
            v-if="canListRequests"
            class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-5-fullhd"
          >
            <a
              class="button is-customgrey is-large is-fullwidth"
              :href="list_requests_path"
            >
              <span>
                <i class="fas fa-file-signature mx-2"></i>
              </span>
              <span> Gestión de solicitudes </span>
            </a>
          </div>
          <div
            v-if="canCreateRequest"
            class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-5-fullhd"
          >
            <a
              class="button is-customgrey is-large is-fullwidth"
              :href="new_requests_path"
            >
              <span>
                <i class="fas fa-file-alt mx-2"></i>
              </span>
              <span> Crear Solicitud </span>
            </a>
          </div>
        </div>
        <div class="columns is-desktop is-centered">
          <div
              v-if="canViewStatistics"
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-5-fullhd"
          >
            <a
                class="button is-customgrey is-large is-fullwidth"
                :href="src_summary_path"
            >
              <span>
                <i class="fas fa-chart-line mx-2"></i>
              </span>
              <span> Reportería SRC </span>
            </a>
          </div>
          <div
              v-if="canSearchStudents"
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-5-fullhd"
          >
            <a
                class="button is-customgrey is-large is-fullwidth"
                :href="search_students_path"
            >
              <span>
                <i class="fas fa-user-graduate mx-2"></i>
              </span>
              <span> Búsqueda de estudiantes SIAC </span>
            </a>
          </div>
        </div>
        <div class="columns is-desktop is-centered">
          <div
              v-if="canViewPayments"
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-5-fullhd"
          >
            <a
                class="button is-customgrey is-large is-fullwidth"
                :href="payment_summary_path"
            >
              <span>
                <i class="fas fa-receipt mx-2"></i>
              </span>
              <span> Listado de pagos </span>
            </a>
          </div>
        </div>

          <div
            v-if="!canCreateRequest && !canCreateRequest && !canListRequests && !canDigitalSignature"
            class="column is full"
          >
            <b-message title="No existen permisos" type="is-danger" has-icon>
              Comuníquese con los desarrolladores a
              proyectosinformaticos@usach.cl para que puedan otorgarle algun rol
            </b-message>
          </div>


        <div v-if="canDigitalMassiveSignature || canDigitalSignature">
          <div class="is-flex is-justify-content-center">
            <h2 class="subtitle is-3 has-text-secondary">
              Firma Digital Simple
            </h2>
          </div>
          <div class="column is-8 is-offset-2">
            <hr class="solid" style="margin: 1px 0;" />
          </div>
          <div class="columns is-multiline is-desktop is-centered">
            <div
                v-if="canDigitalSignature"
                class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-one-third-fullhd"
              >
                <a
                  class="button is-customgrey is-large is-fullwidth"
                  style="line-height: 1em;"
                  :href="digital_signature_path"
                >
                  <span>
                    <i class="fas fa-file-pdf mx-2"></i>
                  </span>
                  <span> Un Documento </span>
                </a>
              </div>
              <div
                  v-if="canDigitalMassiveSignature"
                  class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-one-third-fullhd"
              >
                <a
                    class="button is-customgrey is-large is-fullwidth"
                    :href="massive_digital_signature_path"
                    style="line-height: 1em;"
                >
                  <span>
                    <i class="fas fa-copy mx-2"></i>
                  </span>
                  <span> Muchos Documentos del Mismo Tipo</span>
                </a>
              </div>
          </div>
        </div>
      </div>
      <div
        class="container is-flex is-flex-align-items-flex-end mt-auto is-justify-content-center"
        style="height: 150px"
      >
        <a class="button mt-auto is-primary" href="/public/verify-code">
          <span>
            <i class="fas fa-search mx-2"></i>
          </span>
          <span>
            Verificar Emisión de Documentos con Código de Verificación
          </span>
        </a>
      </div>
    </div>
    <div
      v-if="permissionSuperadmin"
      class="container is-flex is-flex-align-items-flex-end mt-auto is-justify-content-center"
      style="height: 150px"
    >
      <a class="button mt-auto is-secondary" href="/documents/auth/drive">
        <span>
          <i class="fab fa-google-drive mx-2"></i>
        </span>
        <span> Obtener credenciales de Google Drive y Apps Scripts </span>
      </a>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MenuRequestsReviewer",
  props: [
    "canListRequests",
    "canCreateRequest",
    "canSearchRequest",
    "canViewStatistics",
    "canCreateRequestType",
    "permissionSuperadmin",
    "canDigitalSignature",
    "canSearchStudents",
    "canDigitalMassiveSignature",
    "canViewPayments",
  ],
  data: function () {
    return {
      list_requests_path: "/requests",
      new_requests_path: "/requests/request_types",
      digital_signature_path: "/simple-digital-signature",
      massive_digital_signature_path: "/massive-multiple-simple-digital-signature",
      search_requests_path: "/requests/search",
      search_students_path: "/people/students",
      src_summary_path: "/request_types/src/statistics",
      payment_summary_path: "/payment-details/reviewer/summary",
      isModalSelectRequestTypeActive: false,
      requestTypes: ["", "Nombramiento"],
      selectedRequestType: null,

      carousels: [
        {
          image: "shorcuts_images/default.png",
          link: "/#",
          condition: true,
          color: "#EE8600",
        },
        {
          image: "shorcuts_images/reviewer/listado_solicitudes.png",
          link: "/requests",
          condition: this.canListRequests,
          color: "#4F8FDE",
        }, //azul
        {
          image: "shorcuts_images/reviewer/estadisticas_solicitudes.png",
          link: "/request_types/src/statistics",
          condition: this.canViewStatistics,
          color: "#7957D5",
        }, //mo4rado
        {
          image: "shorcuts_images/reviewer/nuevo_tipo_solicitud.png",
          link: "/request_types/new",
          condition: this.canCreateRequestType,
          color: "#009D72",
        }, // verde
      ],
    };
  },

  created() {
    document.title = "Inicio";
    // this.getRequestTypes();
  },

  methods: {
    async getRequestTypes() {
      await axios
        .get("/request_types.json")
        .then((response) => {
          this.requestTypes = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.requestTypes = [];
        });
    },
    debug() {},
    getSteps(request_state_id, request_states) {
      let count = 1;

      for (let i = 0; i < request_states.length; i++) {
        if (request_states[i].id != request_state_id) {
          count = count + 1;
        } else {
          i = request_states.length;
        }
      }

      return "(Paso " + count + " de " + request_states.length + ")";
    },
    listRequests() {
      //This functions is called when button "Listar Solicitudes" is pressed
      //do something
      //call backend
    },
    openModalSelectRequestType() {
      //This functions is called when button "crear Solicitud" is pressed
      //do something
      this.isModalSelectRequestTypeActive = true;
    },
    redirectToNewRequest() {
      //This functions is called when button "Buscar Solicitud" is pressed
      if (this.selectedRequestType == null) {
        // There's no type request selected
        console.log("No se ha seleccionado Tipo de Solicitud");
      } else {
        window.location.href = "/requests/request_types";
      }
    },
  },
};
</script>

<style>
/* Solid border */
hr.solid {
  border-top: 2px solid #bbb;
  width: 100%;
}
</style>

<style scoped>
.button {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-text {
  text-align: center;
}
</style>
