<template>
  <b-tag :class="getStateInfo(progressName).color">
    <b-icon :icon="getStateInfo(progressName).icon"> </b-icon>
    {{ progressName }}
  </b-tag>
</template>
<script>
import { getStateInfo } from "../../packs/utilities";
export default {
  props: ["progressName"],
  methods: {
    getStateInfo,
  },
};
</script>
