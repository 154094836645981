<template>
    <div class="container is-fullheight" style="padding: 2rem;background-color: #F2F2F2;">
        <div class="section" style="background-color: white; height: 100%" ref="createRequestType">

            <!-- Titulo de la sección -->
            <div class="is-flex is-justify-content-flex-start">
                <h1 class="subtitle is-1 has-text-secondary">
                    Tipos de Solicitudes
                </h1>
            </div>

            <!-- Separador -->
            <hr class="solid">
          <div class="columns">
            <div v-if="canToggleGcc" class="column is-narrow px-1">
              <b-button v-if="!findOriginDisabled('SRC')" type = "is-danger" icon-left="times" @click="confirmDialog('GCC',false)">
                Deshabilitar solicitudes GCC
              </b-button>
              <b-button v-else type = "is-success" icon-left="check" @click="confirmDialog('GCC',true)">
                Habilitar solicitudes GCC
              </b-button>
            </div>
            <div v-if="canToggleGsd" class="column is-narrow px-1">
              <b-button v-if="!findOriginDisabled('MSD')" type = "is-danger" icon-left="times" @click="confirmDialog('GSD',false)">
                Deshabilitar solicitudes GSD
              </b-button>
              <b-button v-else type = "is-success" icon-left="check" @click="confirmDialog('GSD',true)">
                Habilitar solicitudes GSD
              </b-button>
            </div>
          </div>
            <hr class="solid">
            <!-- Table Section -->

            <b-table :data="requestTypes == null ? [] : requestTypes"
                     :paginated="true"
                     :pagination-simple=false
                     pagination-position="bottom"
                     :current-page="currentPageTable"
                     per-page="15"
                     :bordered="false"
                     :striped="true"
                     :narrowed="false"
                     :hoverable="true"
                     :loading="isLoading == null ? true : false"
                     :focusable="false"
                     :mobile-cards="true">
                <!-- Column Name -->
                <b-table-column field="name" :visible="true" label="Nombre" width="250" sortable searchable>
                    <template slot="searchable" slot-scope="props">
                        <b-input v-model="props.filters[props.column.field]" placeholder="Buscar por nombre"
                                 icon="search" size="is-small"/>
                    </template>
                    <template v-slot="props">
                        {{ props.row.name }}
                    </template>
                </b-table-column>

                <!-- Column Description -->
                <b-table-column field="descripción" :visible="true" width="550" label="Descripción">
                    <template v-slot="props">
                    <span class="is-flex is-align-content-center">
                        {{ props.row.description }}
                    </span>
                    </template>

                </b-table-column>
              <b-table-column field="origen" :visible="true" label="Origen">
                <template v-slot="props">
                    <span class="is-flex is-align-content-center">
                        {{ findOrigin(props.row.origin_id).name }}
                    </span>
                </template>

              </b-table-column>
                <!-- Column Description -->
                <b-table-column field="disabled" :visible="true" label="Paso">
                    <template v-slot="props">
                    <b-tag v-if="!props.row.disabled && !findOrigin(props.row.origin_id).disabled" class="is-flex is-align-content-center" type="is-success">
                      Habilitada
                    </b-tag>
                        <b-tag v-else class="is-flex is-align-content-center" type="is-danger  ">
                        Deshabilitada
                    </b-tag>
                    </template>

                </b-table-column>

                <b-table-column field="id_folder_drive" :visible="true" label="Url Drive" sortable>
                    <template v-slot="props">
                        <a :href="'https://drive.google.com/drive/u/0/folders/'+props.row.id_folder_drive"
                           class="button is-ghost p-0" target="_blank">
                            Abrir en Drive
                            <i class="fas fa-external-link-alt ml-2"></i>
                        </a>

                    </template>

                </b-table-column>

                <b-table-column field="id" :visible="true" label="Acciones" centered sortable v-slot="props">
                    <a :href="'/request_types/show-admin/'+props.row.id" target="_blank">
                    <span class="has-text-secondary">
                        <i class="fas fa-eye mx-1"></i>
                    </span>
                    </a>
                    <a :href="'/request_types/'+props.row.id+'/edit'" target="_blank">
                    <span class="has-text-secondary">
                        <i class="fas fa-pencil-alt mx-1"></i>
                    </span>
                    </a>
                </b-table-column>
                <template #empty>
                    <div class="has-text-centered">No se encontraron solicitudes</div>
                </template>
            </b-table>

            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
        </div>
    </div>
</template>

<script>
import axios from "axios"

export default {
    name: "ListRequestTypes",
    props: ['canToggleGcc','canToggleGsd'],
    data: () => {
        return {
            requestTypes: null,
            states: [],
            isLoading: false,
            currentPageTable: 1,
            origins: []
        }
    },
    created() {
      document.title = "Listado de solicitudes"
      this.getRequestTypes();
      this.getOrigins();
    },
    methods: {
      async getRequestTypes(){
        this.isLoading = true;
        return await axios.get("/request_types/index-admin.json").then(
            response => {
              this.requestTypes = response.data;
              this.isLoading = false;
            }
        ).catch(
            error => {
              console.log(error);
              this.isLoading = false;
            }
        );
      },
      async getOrigins() {
        this.isLoading = true;
        return await axios
            .get("/origins.json")
            .then((response) => {
              this.origins = response.data;
              this.isLoading = false;
            })
            .catch((error) => {
              console.log(error);

              this.isLoading = false;
            });
      },

      findOrigin(id){
        const origin = this.origins.find((origin) => origin.id === id);
        if (origin) {
          return origin;
        } else {
          return {"name":"Sin origen","disabled":false}; // O cualquier otro valor que desees retornar si no se encuentra el origen
        }
      },
      findOriginDisabled( name) {
        const origin = this.origins.find((origin) => origin.name === name);
        if (origin) {
          return origin.disabled;
        } else {
          return null; // O cualquier otro valor que desees retornar si no se encuentra el origen
        }
      },
      confirmDialog (module_name,disabled){
        let title= "Habilitar solicitudes"
        let message = "Esta acción habilitará la posibilidad de iniciar solicitudes del módulo "+module_name+"."
        let action = "habilitado"
        if (!disabled){
          title= "Deshabilitar solicitudes"
          message = "Esta acción deshabilitará la posibilidad de iniciar solicitudes del módulo "+module_name+"."
          action="deshabilitado"
        }
        this.$buefy.dialog.confirm({
          title: title,
          message: message,
          cancelText: "Cancelar",
          confirmText: "Aceptar",
          type: "is-success",
          onConfirm: () => {
            if(module_name == "SRC"){
              this.toggleGccModule(action)
            }
            else if(module_name == "MSD"){
              this.toggleGsdModule(action)
            }
          }
        }
        );

      },
      toggleGccModule(action)
        {
          this.isLoading = true
          axios
              .post("/origins/toggle-gcc.json")
              .then(() => {
                this.$buefy.dialog.confirm({
                  title: "Listo",
                  message: "Se ha "+ action+" el módulo correctamente.",
                  type: "is-success",
                  hasIcon: true,
                  icon: "check-circle",
                  iconPack: "fa",
                  ariaRole: "alertdialog",
                  ariaModal: true,
                  canCancel: false,
                });
                window.location.reload();

              })
              .catch((e) => {
                console.log(e)
                this.$buefy.dialog.alert({
                  title: "Error",
                  message:
                      "El módulo no ha sido "+action+" correctamente, no se hizo ningún cambio.",
                  type: "is-danger",
                  hasIcon: true,
                  icon: "times-circle",
                  iconPack: "fa",
                  ariaRole: "alertdialog",
                  ariaModal: true,
                });

              })
              .finally(() => {
                this.isLoading = false;
              })

      },
      toggleGsdModule(action)
      {
        this.isLoading = true
        axios
            .post("/origins/toggle-gsd.json")
            .then(() => {
              this.$buefy.dialog.confirm({
                title: "Listo",
                message: "Se ha "+ action+" el módulo correctamente.",
                type: "is-success",
                hasIcon: true,
                icon: "check-circle",
                iconPack: "fa",
                ariaRole: "alertdialog",
                ariaModal: true,
                canCancel: false,
              });
              window.location.reload();

            })
            .catch((e) => {
              console.log(e)
              this.$buefy.dialog.alert({
                title: "Error",
                message:
                    "El módulo no ha sido "+action+" correctamente, no se hizo ningún cambio.",
                type: "is-danger",
                hasIcon: true,
                icon: "times-circle",
                iconPack: "fa",
                ariaRole: "alertdialog",
                ariaModal: true,
              });

            })
            .finally(() => {
              this.isLoading = false;
            })

      },
    }
}
</script>
