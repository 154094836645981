<template>
  <div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <div ref="turnstile" class="turnstile" :data-sitekey="cloudflareSitekey"></div>
  </div>
</template>
<script>
export default {
  props: ["cloudflareSitekey"],
  data() {
    return {
      captchaToken: "",
      isLoading: false,
    };
  },
  methods: {
    loadTurnstile() {
      const script = document.createElement("script");
      script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js";
      script.async = true;
      document.head.appendChild(script);
      script.onload = () => {
        window.turnstile.render(this.$refs.turnstile, {
          sitekey: this.cloudflareSitekey,
          callback: this.onCaptchaVerified,
        });
      };
    },
    onCaptchaVerified(token) {
      this.captchaToken = token;
      this.$emit("captcha-verified", token);
    },
  },
  mounted() {
    this.isLoading = true;
    this.loadTurnstile();
    this.isLoading = false;
  },
};
</script>
