<template>
<div>
    <section>
        <b-sidebar type="is-light" :fullheight=true v-model="open" id="sidebar-menu">
            <div class="p-1">
                <img :style="{
                  height: '8rem',
                  width: 'auto',
                  objectFit: 'contain',
                }"
                src="/logo_negro.png" class="contain">

                <b-menu>
                    <b-menu-list  label="Rol activo">
                      <div v-if="isAuthorized('ChangeProfile')" >
                      <multiselect v-if=" cargosActivos!=null && cargosActivos.length>0 && cargoActivoSeleccionado!=null"
                                   v-model="cargoActivoSeleccionado"
                                   :options="cargosActivos.filter(function(cargo){return !cargo.name.includes('ALUMNO') }).map(type => type.id)"
                                   :custom-label="opt => cargosActivos.find(x => x.id === opt).name"
                                   @input="changeActiveProfile"
                                   placeholder="Seleccione cargo"
                                   selectLabel=""
                                   selectedLabel=""
                                   deselectLabel=""
                                   :allow-empty="false">
                            <template v-slot:noResult>
                                No se encuentra el rol
                            </template>
                            <template v-slot:noOptions>
                                No existen datos
                            </template>
                        </multiselect>
                      </div>
                      <div v-else>
                        {{getCargoName(cargoActivo)}}
                      </div>
                    </b-menu-list>



                    <b-menu-list label="">
                        <!-- Dropdown de administración-->
                        <b-menu-item v-if="isAuthorized('Administrate') || isAuthorized('UsersManagement')" icon="fas fa-tools">
                          <template slot="label" slot-scope="props">
                            Administración
                            <b-icon class="is-pulled-right" :icon="props.expanded ? 'caret-up' : 'caret-down'"></b-icon>
                          </template>
                          <b-menu-item v-if="isAuthorized('CreateRequestType') || isAuthorized('StatisticsRequestType') || isAuthorized('ListRequestTypesAdmin')" icon="file-signature">
                            <template slot="label" slot-scope="props">
                              Administración de Solicitudes
                              <b-icon class="pb-6 is-pulled-right" :icon="props.expanded ? 'caret-up' : 'caret-down'"></b-icon>
                            </template>
                            <b-menu-item v-if="isAuthorized('CreateRequestType')" href="/request_types/new" icon="edit" label="Crear nuevo tipo de solicitud"></b-menu-item>
                            <b-menu-item v-if="isAuthorized('StatisticsRequestType')" href="/request_types/statistics" icon="chart-line" label="Estadísticas tipos de solicitud"></b-menu-item>
                            <b-menu-item v-if="isAuthorized('ListRequestTypesAdmin')" href="/request_types/index-admin" icon="list" label="Ver Tipos de Solicitudes"></b-menu-item>
                          </b-menu-item>
                          <b-menu-item v-if="isAuthorized('ViewAudit') || isAuthorized('ViewUsers') || isAuthorized('ViewCodeUsers')" class="is-vcentered" icon="users-cog">
                            <template slot="label" slot-scope="props">
                              Control de Seguimiento
                              <b-icon class="pb-6 is-pulled-right" :icon="props.expanded ? 'caret-up' : 'caret-down'"></b-icon>
                            </template>
                            <b-menu-item v-if="isAuthorized('ViewAudit')" href="/audit" icon="chart-line" label="Auditoría"></b-menu-item>
                            <b-menu-item v-if="isAuthorized('ViewUsers')" href="/dashboard/user-management" icon="user" label="Control de usuarios"></b-menu-item>
                            <b-menu-item v-if="isAuthorized('ViewCodeUsers')" href="/dashboard/user-code-management" icon="qrcode" label="Getión de usuarios con códigos Authenticator"></b-menu-item>
                          </b-menu-item>
                        </b-menu-item>

                      <!-- Dropdown de administración de estudiantes -->
                      <b-menu-item v-if="isAuthorized('ViewUsers') || isAuthorized('EnableStudent') || isAuthorized('StudentSearch')" icon="user-graduate">
                        <template slot="label" slot-scope="props">
                          Estudiantes
                          <b-icon class="is-pulled-right" :icon="props.expanded ? 'caret-up' : 'caret-down'"></b-icon>
                        </template>
                        <b-menu-item v-if="isAuthorized('ViewUsers')" href="/dashboard/student-management" icon="user" label="Administración de Estudiantes"></b-menu-item>
                        <b-menu-item v-if="isAuthorized('StudentSearch')" href="/people/students" icon="search" label="Búsqueda de Estudiantes SIAC"></b-menu-item>
                        <b-menu-item v-if="isAuthorized('EnableStudent')" href="/people/enable-email" icon="graduation-cap" label="Cambio de Correo de Estudiantes"></b-menu-item>
                        <b-menu-item v-if="isAuthorized('EnableStudent')" href="/people/enabled" icon="at" label="Estudiantes con Correo Personal"></b-menu-item>
                      </b-menu-item>

                      <b-menu-item v-if="isAuthorized('StatisticsRequestType') || isAuthorized('GCCStatisticsRequestType') " icon="fas fa-chart-line">
                        <template slot="label" slot-scope="props">
                          Reportería
                          <b-icon class="is-pulled-right" :icon="props.expanded ? 'caret-up' : 'caret-down'"></b-icon>
                        </template>

                        <b-menu-item v-if="isAuthorized('StatisticsRequestType')" href="/request_types/statistics" icon="chart-line" label="Reportería tipos de solicitud"></b-menu-item>
                        <b-menu-item v-if="isAuthorized('GCCStatisticsRequestType')" href="/request_types/src/statistics" icon="chart-line" label="Reportería SRC"></b-menu-item>

                      </b-menu-item>

                      <b-menu-item  v-if="!isAuthorized('Reviewer')" href="/" icon="list" label="Seleccionar plataforma"></b-menu-item>
                      <b-menu-item  v-if="!isAuthorized('Reviewer')" href="/requests/my_requests" icon="fas fa-file-invoice" label="Mis solicitudes"></b-menu-item>

                      <!--SIDEBAR UTILIDADES-->
                      <template v-if="isAuthorized('SimpleDigitalSignature') || isAuthorized('SimpleDigitalMasiveSignature')">
                        <b-menu-list label="Utilidades" >
                          <b-menu-item icon="fas fa-signature">
                            <template #label="props">
                                Firma de Documentos
                                <b-icon class="is-pulled-right" :icon="props.expanded ? 'caret-up' : 'caret-down'"></b-icon>
                            </template>

                            <b-menu-item icon="file-signature">
                                <template slot="label" slot-scope="props">Firmas Digitales Simples<b-icon class="pb-6 is-pulled-right" :icon="props.expanded ? 'caret-up' : 'caret-down'"></b-icon>
                                </template>
                                <b-menu-item v-if="isAuthorized('SimpleDigitalSignature')" href="/simple-digital-signature" icon="file-pdf" label="Un documento"></b-menu-item>
                                <b-menu-item v-if="isAuthorized('SimpleDigitalMasiveSignature')" href="/massive-multiple-simple-digital-signature" icon="copy" label="Muchos documentos del mismo tipo"></b-menu-item>
                            </b-menu-item>
                          </b-menu-item>
                        </b-menu-list>
                      </template>
                      <!--SIDEBAR GSD-->
                      <template v-if="page === 'gsd' || isAuthorized('Administrate') || isAuthorized('Administrate_GSD')">
                        <p class="has-background-custromgrey" v-if="isAuthorized('Administrate') || isAuthorized('Administrate_GSD')">

                          <br>Sección MSD<br>
                        </p>

                        <b-menu-item  href="/msd/home" icon="home" label="Inicio"></b-menu-item>
                        <b-menu-item icon="fas fa-file-invoice" >
                          <template slot="label" slot-scope="props">
                            Solicitudes
                            <b-icon class="is-pulled-right" :icon="props.expanded ? 'caret-up' : 'caret-down'"></b-icon>
                          </template>
                          <b-menu-item v-if="isAuthorized('CreateRequest') || isAuthorized('Administrate')" href="/gsd/request_types" icon="edit" label="Iniciar Solicitud"></b-menu-item>
                          <b-menu-item v-if="isAuthorized('ListRequests') || isAuthorized('Administrate')" href="/requests" icon="file-signature" label="Gestión de solicitudes"></b-menu-item>
                        </b-menu-item >
                        <b-menu-item  href="/msd/appointment" icon="clock" label="Reserva de hora"></b-menu-item>
                        <b-menu-item  href="/msd/faq" icon="question" label="Preguntas Frecuentes"></b-menu-item>
                        <b-menu-item  href="/msd/about_us" icon="users" label="¿Quiénes Somos?"></b-menu-item>
                        <b-menu-item  href="/msd/contact_us" icon="address-book" label="Contactos"></b-menu-item>

                      </template>
                      <!--SIDEBAR GCC-->

                      <template v-if="page === 'src'|| isAuthorized('Administrate') || isAuthorized('Administrate_GCC') " >
                        <p class="has-background-custromgrey" v-if="isAuthorized('Administrate') || isAuthorized('Administrate_GCC')">
                          <br>
                          Sección SRC
                          <br>
                        </p>

                        <b-menu-item  href="/src/home" icon="home" label="Inicio"></b-menu-item>
                        <b-menu-item v-if="isAuthorized('ViewTokens')" href="/dashboard/token-management" icon="search" label="Códigos de verificación"></b-menu-item>
                        <b-menu-item icon="fas fa-file-invoice">
                          <template slot="label" slot-scope="props">
                            Solicitudes
                            <b-icon class="is-pulled-right" :icon="props.expanded ? 'caret-up' : 'caret-down'"></b-icon>
                          </template>
                          <b-menu-item v-if="isAuthorized('CreateRequest') || isAuthorized('Administrate')" href="/src/request_types" icon="edit" label="Iniciar Solicitud"></b-menu-item>
                          <b-menu-item v-if="isAuthorized('ListRequests') || isAuthorized('Administrate')" href="/requests" icon="file-signature" label="Gestión de solicitudes"></b-menu-item>
                          <b-menu-item v-if="isAuthorized('PaymentSummary') || isAuthorized('Administrate')" href="/payment-details/reviewer/summary" icon="receipt" label="Listado de Pagos Realizados"></b-menu-item>
                        </b-menu-item >
                        <b-menu-item  href="/src/faq" icon="question" label="Preguntas Frecuentes"></b-menu-item>
                        <b-menu-item  href="/src/contact_us" icon="address-book" label="Contactos"></b-menu-item>


                      </template>

                      <!--SIDEBAR OTROS-->
                      <template v-if="page !== 'src' && page !== 'msd'">
                        <b-menu-item v-if="isAuthorized('ListRequests') || isAuthorized('Administrate')" href="/requests" icon="file-signature" label="Gestión de solicitudes"></b-menu-item>
                        <b-menu-item v-if="isAuthorized('PaymentSummary') || isAuthorized('Administrate')" href="/payment-details/reviewer/summary" icon="receipt" label="Listado de Pagos Realizados"></b-menu-item>
                      </template>
                        <!--condiciones alumno-->

                    </b-menu-list>


                </b-menu>
            </div>
<!--            <footer class="footer p-1" id="sidebar-footer">
                <b-menu-list label="Ayuda">
                    <b-menu-item href="/tutorials" icon="book-open" label="Manuales"></b-menu-item>
                    <b-menu-item href="https://www.google.com" icon="exclamation-circle" label="Reporte de errores"></b-menu-item>
                    <b-menu-item @click="showModal =  true" icon="address-book" label="Contacto"></b-menu-item>
                    <b-menu-item href="/home" icon="home" icon-pack="fab" label="v1.0.0"></b-menu-item>
                </b-menu-list>
            </footer>-->
        </b-sidebar>

      <b-button v-if="!$root.isTablet" class="is primary" @click="open = true" icon-left="bars"></b-button>
      <b-button v-else @click="open = true" icon-left="bars"> </b-button>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
        <b-modal v-model="showModal" has-modal-card trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal>
            <template #default="props">
                <contact-modal @close="props.close"></contact-modal>
            </template>
        </b-modal>
    </section>
</div>
</template>

<script>
import axios from "axios";
import ContactModal from "./contact_modal";
export default {
    components: {
        ContactModal,
    },
    data() {

        return {
            open: false,
            isLoading: false,
            cargoActivoSeleccionado: null,
            showModal: false,
            page:""
        }
    },
    created() {
        this.isLoading = true;
        this.cargoActivoSeleccionado = this.cargoActivo
        this.isLoading = false;
        this.page = this.currentPage
    },
    props: [
        'userId', 'permisos', 'personId', 'cargosActivos', 'cargoActivo', 'unidadActiva','currentPage'

    ],
    methods: {
        getCargoName(cargo_id){
          let cargo = this.cargosActivos.find(x => x.id === cargo_id);
          if (cargo!==undefined){
            return cargo.name;
          }
          return "¡ERROR CON EL ROL, CONSULTE CON DESARROLLADOR!"
        },
        isAuthorized(nombre) {
            var objeto = this.permisos.find(obj => obj.nombre === nombre)
            if (objeto !== undefined) {
                return objeto.value;
            } else {
                return false
            }
            return true
        },
        /*toMyData(){
          return "/people/"+this.personId
        },
        toMyValidations(){
          return "/validations/user/"+this.userId
        },
        toMyUnitValidation(){
          return "/validations/my-unit/"
        },
        toMyUnit(){
          return "/admin/units/"+this.unidadActiva
        },
        toMyLifeRecord(){
          return "/people/"+this.personId+"/my-life-record"
        },
        toMyAcademicData(){
          return "/people/"+this.personId+"/my-academic-data"
        },
        addAcademicActivityAuthorized(){
          return !!(this.isAuthorized('AddProject') || this.isAuthorized('AddPublication') ||
              this.isAuthorized('AddProduct') || this.isAuthorized('AddPatent') || this.isAuthorized('AddDisclosure'));
        },*/
        changeActiveProfile() {
          this.isLoading = true;
            axios
                .post("/users/" + this.userId + "/change-profile", {
                    active_profile: this.cargoActivoSeleccionado
                })
                .then(response => {
                    window.location.reload(false);
                }).
            catch(error => {
                var elmnt = document.getElementById("app");
                elmnt.scrollIntoView();
                this.isLoading = false;
            })
              .finally(() => {
              });
        }

    },
    computed: {

    }
}
</script>

<style>
#sidebar-footer{
    height: 190px;
}
</style>
