<template>
  <div
    class="container is-fullheight"
    style="padding: 2rem; background-color: #f2f2f2"
  >
    <div
      class="section"
      style="background-color: white; height: 100%"
      ref="createRequestType"
    >
      <!-- Titulo de la sección -->
      <div class="is-flex is-justify-content-flex-start">
        <h1 class="subtitle is-1 has-text-secondary">
          Crear Tipo de Solicitud
        </h1>
      </div>

      <!-- Separador -->
      <hr class="solid" />

      <!-- B-steps -->
      <b-steps
        v-model="activeStep"
        :animated="isAnimated"
        :has-navigation="false"
        :rounded="isRounded"
        :label-position="labelPosition"
        :mobile-mode="mobileMode"
      >
        <!-- Step: Datos de la solicitud -->
        <b-step-item step="1" label="Datos" :clickable="isStepsClickable">
          <div
            class="is-flex is-align-items-center is-justify-content-space-between is-flex-wrap-wrap my-4"
          >
            <div class="field is-flex mb-0 mt-2">
              <h1 class="title is-4">
                Definir datos de la solicitud
                <b-tooltip
                  label="En este paso se debe definir los datos principales de la solicitud. También se deben asociar documentos que estarán disponibles en todo el proceso de la solicitud."
                  multilined
                >
                  <i class="fas fa-info-circle"> </i>
                </b-tooltip>
              </h1>
            </div>
          </div>
          <ValidationObserver v-slot="{ handleSubmit }">
            <div class="columns">
              <div class="column is-one-third">
                <!--ORIGEN DE LA SOLICITUD-->
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-field label="Origen de la solicitud *">
                    <multiselect
                      v-model="selectOrigin.selectedOrigin"
                      :options="selectOrigin.optionsOrigins"
                      track-by="id"
                      label="real_name"
                      :multiple="false"
                      placeholder="Seleccione una opción"
                      selectLabel=""
                      selectedLabel="Seleccionado"
                      deselectLabel="Presione para deseleccionar"
                    >
                      <template v-slot:noOptions> No existen datos</template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                  </b-field>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
                <!--SI REQUIERE ROLES-->
              </div>

              <div
                v-if="
                  selectOrigin.selectedOrigin &&
                  selectOrigin.selectedOrigin.has_role_type
                "
                class="column is-one-third"
              >
                <!--tipo esgtudiant-->
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  :disabled="requieredRoles === '0'"
                >
                  <b-field label="Tipo de solicitud*">
                    <multiselect
                      v-model="selectRoleType.selectedRoleType"
                      :options="selectRoleType.optionsRoleTypes"
                      track-by="id"
                      label="alt_name"
                      :multiple="false"
                      placeholder="Seleccione una opción"
                      selectLabel=""
                      selectedLabel="Seleccionado"
                      deselectLabel="Presione para deseleccionar"
                    >
                      <template v-slot:noOptions> No existen datos</template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                  </b-field>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <div
                v-if="
                  selectOrigin.selectedOrigin &&
                  selectRoleType.selectedRoleType &&
                  selectOrigin.selectedOrigin.has_role_type &&
                  selectRoleType.selectedRoleType.has_role_state
                "
                class="column is-one-third"
              >
                <!--ESTADO ESTUDIANTE-->
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  :disabled="requieredStates === '0'"
                >
                  <b-field label="Estado del estudiante*">
                    <multiselect
                      v-model="selectRoleState.selectedRolState"
                      :options="selectRoleState.optionsRoleState"
                      track-by="id"
                      label="alt_name"
                      :multiple="false"
                      placeholder="Seleccione una opción"
                      selectLabel=""
                      selectedLabel="Seleccionado"
                      deselectLabel="Presione para deseleccionar"
                    >
                      <template v-slot:noOptions> No existen datos</template>
                      <span slot="noResult">
                        No se encontraron elementos.
                      </span>
                    </multiselect>
                  </b-field>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <form @submit.prevent="handleSubmit(setStatesStep)">
              <div>
                <!-- Input Nombre de la solicitud -->
                <div class="field">
                  <label class="label">Nombre de la Solicitud *</label>
                  <div class="control">
                    <ValidationProvider
                      rules="required|max:250"
                      v-slot="{ errors }"
                    >
                      <input
                        class="input"
                        v-model="inputNameRequest"
                        type="text"
                        placeholder="Ingrese un nombre"
                      />
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>

                <!-- Input Descripcion de la solicitud -->
                <div class="field mt-4">
                  <label class="label"
                    >Descripción pequeña (Descripcion vista al pasar el mouse
                    por una solicitud)</label
                  >
                  <div class="control">
                    <ValidationProvider rules="max:250" v-slot="{ errors }">
                      <textarea
                        class="textarea"
                        v-model="inputDescription"
                        placeholder="Ingrese una descripcion"
                      ></textarea>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <!-- Input Descripcion larga de la solicitud -->
                <div class="field mt-4">
                  <label class="label"
                    >Descripción larga (Esta información se verá al inicio de la
                    solicitud)</label
                  >
                  <div class="control">
                    <ValidationProvider rules="max:4000" v-slot="{ errors }">
                      <textarea
                        class="textarea"
                        v-model="inputLongDescription"
                        placeholder="Ingrese un texto para que el usuario lea antes de que inicie la solicitud"
                      ></textarea>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <!--------------------------------------------------------------------------------------------------------->
              <!-- Section to upload files -->
              <div class="mt-4">
                <b-field
                  label="Subir archivos, plantillas y documentos genéricos"
                >
                  <div class="columns is-multiline">
                    <div class="column is-full">
                      Esta plantilla quedará disponible a lo largo de toda la
                      solicitud para el estudiantado; por ejemplo, si para un
                      paso es requerido un documento, podría ser esta plantilla
                      y se encontrará disponible para descargar a través de un
                      botón en la parte superior de la solicitud.
                      <br />
                      *Este documento quedará subido de manera pública en drive
                      para su acceso
                    </div>
                    <div class="column is-full has-text-left">
                      <b-upload v-model="dropFiles" multiple drag-drop>
                        <section class="section">
                          <div class="content has-text-centered">
                            <p>
                              <b-icon icon="upload" size="is-large"></b-icon>
                            </p>
                            <p>
                              Arrastre sus archivos aquí o haga click para
                              subirlos
                            </p>
                          </div>
                        </section>
                      </b-upload>
                    </div>
                  </div>
                </b-field>
                <div class="tags">
                  <span
                    v-for="(file, index) in dropFiles"
                    :key="index"
                    class="tag is-secondary"
                  >
                    {{ file.name }}
                    <button
                      class="delete is-small"
                      type="button"
                      @click="deleteDropFile(index)"
                    ></button>
                  </span>
                </div>
              </div>

              <div class="my-4">
                <b-field
                  label="¿Guardar documentos de respuesta final en Google Drive?"
                >
                  <div>
                    Si desea que el documento de respuesta final sea almacenado
                    en la nube de Google Drive, por favor marque esta opción.
                  </div>
                </b-field>
                <div class="columns">
                  <div class="column has-text-left">
                    <b-switch v-model="saveResponseInDrive" type="is-primary">
                      {{ saveResponseInDrive ? "Sí" : "No" }}
                    </b-switch>
                  </div>
                </div>
              </div>

              <div class="my-4">
                <b-field label="¿Crear un código de verificación?">
                  <div>
                    Si desea que el documento de respuesta final incluya un
                    código de verificación, por favor marque esta opción. Si el
                    documento no lo necesita, desmarquela.
                  </div>
                </b-field>
                <div class="columns">
                  <div class="column has-text-left">
                    <b-switch v-model="needsToken" type="is-primary">
                      {{ needsToken ? "Sí" : "No" }}
                    </b-switch>
                  </div>
                </div>
              </div>

              <div class="is-flex is-justify-content-center">
                <button
                  class="button is-secondary mx-1"
                  disabled
                  @click="setPreviousStep"
                >
                  Volver
                </button>
                <button
                  class="button is-primary mx-1"
                  type="submit"
                  @click="getDefaultInternalPosition"
                >
                  Siguiente
                </button>
              </div>
            </form>
          </ValidationObserver>
        </b-step-item>

        <!-- Step: Define States -->
        <b-step-item step="2" label="Pasos" :clickable="isStepsClickable">
          <div class="columns is-multiline">
            <div class="column is-half">
              <div
                class="is-flex is-align-items-center is-justify-content-space-between is-flex-wrap-wrap mt-4"
              >
                <div class="field is-flex mb-0 mt-2">
                  <h1 class="title is-4">
                    Definir Pasos de la Solicitud
                    <b-tooltip
                      label="En esta sección se deben definir la cantidad de pasos por los que pasa una solicitud, así como asociar los revisores del estado, documentos y formularios asociados a estos."
                      multilined
                    >
                      <i class="fas fa-info-circle"> </i>
                    </b-tooltip>
                  </h1>
                </div>
              </div>
            </div>

            <div class="column is-half has-text-right">
              <b-button
                class="mt-4"
                type="is-info is-light is-border-cornflowerblue"
                icon-left="plus"
                @click="isModalCreateStateActive = true"
              >
                Agregar nuevo paso
              </b-button>
            </div>
            <div class="column is-full">
              <b-notification type="is-warning" has-icon icon-size="medium">
                Se debe añadir un formulario de Google Forms obligatoriamente en
                el primer paso para recolectar datos.
              </b-notification>
            </div>
          </div>

          <b-message v-if="selectedStates.length <= 1" type="is-info" has-icon>
            No hay ningún paso asociado a la solicitud. Para añadir presiona el
            botón <b>crear paso</b> para añadir nuevos pasos al proceso de la
            solicitud.
          </b-message>
          <b-message
            v-if="validationStepState !== ''"
            type="is-danger"
            has-icon
            aria-close-label="Close message"
          >
            {{ validationStepState }}
          </b-message>
          <div
            class="my-2"
            v-for="(state, state_index) in selectedStates"
            :key="state_index"
          >
            <b-collapse
              class="card"
              animation="slide"
              aria-id="contentIdForA11y3"
            >
              <template #trigger="props">
                <div
                  class="card-header"
                  :class="state_index === 0 ? 'has-background-secondary' : ''"
                  role="button"
                  aria-controls="contentIdForA11y3"
                >
                  <p
                    class="card-header-title"
                    :class="state_index === 0 ? 'has-text-white' : ''"
                  >
                    {{ state.real_name }}
                    <a @click="editState(state_index)"
                      ><i
                        class="fas fa-pencil-alt mx-3"
                        :class="state_index === 0 ? 'has-text-white' : ''"
                      ></i>
                    </a>
                    <a
                      v-if="state_index !== 0"
                      @click="removeState(state_index)"
                    >
                      <span class="icon has-text-danger">
                        <i class="fas fa-trash-alt is-danger mx-3"> </i>
                      </span>
                    </a>
                  </p>
                  <a
                    class="card-header-icon"
                    :class="state_index === 0 ? 'has-text-white' : ''"
                  >
                    <b-icon
                      icon-pack="fas"
                      :icon="props.open ? 'caret-down' : 'caret-up'"
                    >
                    </b-icon>
                  </a>
                </div>
              </template>

              <div class="card-content">
                <div class="content">
                  {{ state.information }}
                  <br />
                  <br />
                  <div class="is-flex mb-1">
                    <p style="color: gray; font-size: 14px">
                      Duración:
                      {{
                        state.duration === 0
                          ? "No tiene"
                          : state.duration + " días"
                      }}
                    </p>
                  </div>
                  <div class="is-flex is-flex-wrap-wrap">
                    <p class="my-auto" style="color: gray; font-size: 14px">
                      Revisores:
                    </p>
                    <b-tag
                      class="m-1"
                      v-for="(ip, index) in state.internal_positions"
                      :key="index"
                      type="is-primary"
                      closable
                      aria-close-label="Close tag"
                      @close="
                        () => {
                          state.internal_positions.splice(index, 1);
                        }
                      "
                    >
                      {{ ip.name }}
                    </b-tag>
                  </div>
                  <div class="is-flex is-flex-wrap-wrap">
                    <p class="my-auto" style="color: gray; font-size: 14px">
                      Documentos:
                    </p>
                    <b-tag
                      class="is-secondary m-1"
                      v-for="(ip, index) in state.documents"
                      :key="index"
                      type="is-primary"
                      closable
                      aria-close-label="Close tag"
                      @close="
                        () => {
                          state.documents.splice(index, 1);
                        }
                      "
                    >
                      {{ ip.name }}
                    </b-tag>
                  </div>
                  <div class="is-flex is-flex-wrap-wrap">
                    <p class="my-auto" style="color: gray; font-size: 14px">
                      Formularios:
                    </p>
                    <b-tag
                      class="is-info m-1"
                      v-for="(ip, index) in state.forms"
                      :key="index"
                      type="is-primary"
                      closable
                      aria-close-label="Close tag"
                      @close="
                        () => {
                          state.forms.splice(index, 1);
                        }
                      "
                    >
                      {{ ip.name }}
                    </b-tag>
                  </div>
                </div>
              </div>
              <footer class="card-footer is-flex is-flex-wrap-wrap">
                <a
                  class="card-footer-item"
                  @click="addItemsToState(state_index, 'Revisores')"
                  ><b-icon class="pr-2" icon="user-plus"></b-icon>Añadir
                  Revisores</a
                >
                <a
                  class="card-footer-item"
                  @click="addItemsToState(state_index, 'Documentos')"
                  ><b-icon class="pr-2" icon="file-upload"></b-icon>Añadir
                  Documentos Requeridos</a
                >
                <a
                  class="card-footer-item"
                  @click="addItemsToState(state_index, 'Formularios')"
                  ><b-icon class="pr-2" icon="check-square"></b-icon>Añadir
                  Formularios
                </a>
              </footer>
            </b-collapse>
          </div>
          <div class="is-flex is-justify-content-center">
            <button class="button is-secondary mx-1" @click="setPreviousStep">
              Volver
            </button>
            <button class="button is-primary mx-1" @click="setStepOrderStates">
              Siguiente
            </button>
          </div>
        </b-step-item>

        <b-step-item
          step="3"
          label="Flujo"
          :clickable="false"
          :type="{ 'is-success': isProfileSuccess }"
        >
          <div
            class="is-flex is-align-items-center is-justify-content-space-between is-flex-wrap-wrap my-4"
          >
            <div class="field is-flex mb-0 mt-2">
              <h1 class="title is-4">
                Ordenar pasos de la solicitud
                <b-tooltip
                  label="En esta sección se deben ordenar los pasos creados en el paso anterior. Puedes arrastrar los pasos a la izquierda y luego reeordenarlos en la derecha "
                  multilined
                >
                  <i class="fas fa-info-circle"> </i>
                </b-tooltip>
              </h1>
            </div>
            <div class="is-flex my-2">
              <button
                :disabled="orderedStates.length === 0 ? false : true"
                class="button is-primary is-small"
                @click="keepTheOrder()"
              >
                Mantener el mismo orden
              </button>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <draggable
                class="list-group is-border-light-grey p-2"
                :list="selectedStates"
                group="states"
                @change="
                  () => {
                    statesNotOrdered = '';
                  }
                "
                :style="selectedStates.length === 0 ? 'height:100px' : ''"
              >
                <div
                  class="my-2"
                  v-for="(state, state_index) in selectedStates"
                  :key="state_index"
                >
                  <b-collapse
                    class="card"
                    :open="false"
                    animation="slide"
                    aria-id="contentIdForA11y3"
                  >
                    <template #trigger="props">
                      <div
                        class="card-header"
                        role="button"
                        aria-controls="contentIdForA11y3"
                      >
                        <p class="card-header-title">
                          {{ state.real_name }}
                        </p>
                        <a class="card-header-icon">
                          <b-icon
                            icon-pack="fas"
                            :icon="props.open ? 'caret-down' : 'caret-up'"
                          >
                          </b-icon>
                        </a>
                      </div>
                    </template>

                    <div class="card-content">
                      <div class="content">
                        aqui va la info: {{ state.information }}
                        <br />
                        <br />
                        <div class="is-flex is-flex-wrap-wrap">
                          <p
                            class="my-auto"
                            style="color: gray; font-size: 14px"
                          >
                            Duración:
                            {{
                              state.duration === 0 ? "No tiene" : state.duration
                            }}
                          </p>
                        </div>
                        <div class="is-flex is-flex-wrap-wrap">
                          <p
                            class="my-auto"
                            style="color: gray; font-size: 14px"
                          >
                            Revisores:
                          </p>
                          <b-tag
                            class="m-1"
                            v-for="(ip, index) in state.internal_positions"
                            :key="index"
                            type="is-primary"
                            closable
                            aria-close-label="Close tag"
                            @close="
                              () => {
                                state.internal_positions.splice(index, 1);
                              }
                            "
                          >
                            {{ ip.name }}
                          </b-tag>
                        </div>
                        <div class="is-flex is-flex-wrap-wrap">
                          <p
                            class="my-auto"
                            style="color: gray; font-size: 14px"
                          >
                            Documentos:
                          </p>
                          <b-tag
                            class="is-secondary m-1"
                            v-for="(ip, index) in state.documents"
                            :key="index"
                            type="is-primary"
                            closable
                            aria-close-label="Close tag"
                            @close="
                              () => {
                                state.documents.splice(index, 1);
                              }
                            "
                          >
                            {{ ip.name }}
                          </b-tag>
                        </div>
                        <div class="is-flex is-flex-wrap-wrap">
                          <p
                            class="my-auto"
                            style="color: gray; font-size: 14px"
                          >
                            Formularios:
                          </p>
                          <b-tag
                            class="is-info m-1"
                            v-for="(ip, index) in state.forms"
                            :key="index"
                            type="is-primary"
                            closable
                            aria-close-label="Close tag"
                            @close="
                              () => {
                                state.forms.splice(index, 1);
                              }
                            "
                          >
                            {{ ip.name }}
                          </b-tag>
                        </div>
                      </div>
                    </div>
                  </b-collapse>
                </div>
              </draggable>
              <span class="validation-alert">{{ statesNotOrdered }}</span>
            </div>

            <div class="column is-6">
              <div class="drop-states-div">
                <b-collapse
                  class="card m-2"
                  :open="false"
                  animation="slide"
                  aria-id="contentIdForA11y3"
                >
                  <template #trigger="props">
                    <div
                      class="card-header has-background-secondary"
                      role="button"
                      aria-controls="contentIdForA11y3"
                    >
                      <p class="card-header-title has-text-white">
                        {{ initialState.real_name }}
                      </p>
                      <a class="card-header-icon has-text-white">
                        <b-icon
                          icon-pack="fas"
                          :icon="props.open ? 'caret-down' : 'caret-up'"
                        >
                        </b-icon>
                      </a>
                    </div>
                  </template>

                  <div class="card-content">
                    <div class="content">
                      {{ initialState.information }}
                      <br />
                      <br />
                      <div class="is-flex is-flex-wrap-wrap">
                        <p class="my-auto" style="color: gray; font-size: 14px">
                          Duración:
                          {{
                            initialState.duration === 0
                              ? "No tiene"
                              : initialState.duration
                          }}
                        </p>
                      </div>
                      <div class="is-flex is-flex-wrap-wrap">
                        <p class="my-auto" style="color: gray; font-size: 14px">
                          Revisores:
                        </p>
                        <b-tag
                          class="m-1"
                          v-for="(ip, index) in initialState.internal_positions"
                          :key="index"
                          type="is-primary"
                          closable
                          aria-close-label="Close tag"
                          @close="
                            () => {
                              initialState.internal_positions.splice(index, 1);
                            }
                          "
                        >
                          {{ ip.name }}
                        </b-tag>
                      </div>
                      <div class="is-flex is-flex-wrap-wrap">
                        <p class="my-auto" style="color: gray; font-size: 14px">
                          Documentos:
                        </p>
                        <b-tag
                          class="is-secondary m-1"
                          v-for="(ip, index) in initialState.documents"
                          :key="index"
                          type="is-primary"
                          closable
                          aria-close-label="Close tag"
                          @close="
                            () => {
                              initialState.documents.splice(index, 1);
                            }
                          "
                        >
                          {{ ip.name }}
                        </b-tag>
                      </div>
                      <div class="is-flex is-flex-wrap-wrap">
                        <p class="my-auto" style="color: gray; font-size: 14px">
                          Formularios:
                        </p>
                        <b-tag
                          class="is-info m-1"
                          v-for="(ip, index) in initialState.forms"
                          :key="index"
                          type="is-primary"
                          closable
                          aria-close-label="Close tag"
                          @close="
                            () => {
                              initialState.forms.splice(index, 1);
                            }
                          "
                        >
                          {{ ip.name }}
                        </b-tag>
                      </div>
                    </div>
                  </div>
                </b-collapse>
                <div
                  v-if="orderedStates.length === 0"
                  class="is-flex is-justify-content-center my-2"
                >
                  <p>Arrastra los paso aquí en el orden que desees.</p>
                </div>
                <draggable
                  class="list-group is-border-light-grey p-2"
                  :list="orderedStates"
                  group="states"
                  :style="orderedStates.length === 0 ? 'height:100px' : ''"
                >
                  <div
                    class="my-2"
                    v-for="(state, state_index) in orderedStates"
                    :key="state_index"
                  >
                    <b-collapse
                      class="card"
                      :open="false"
                      animation="slide"
                      aria-id="contentIdForA11y3"
                    >
                      <template #trigger="props">
                        <div
                          class="card-header has-background-success-light"
                          role="button"
                          aria-controls="contentIdForA11y3"
                        >
                          <p class="card-header-title">
                            {{ state_index }} - {{ state.real_name }}
                          </p>
                          <a class="card-header-icon">
                            <b-icon
                              icon-pack="fas"
                              :icon="props.open ? 'caret-down' : 'caret-up'"
                            >
                            </b-icon>
                          </a>
                        </div>
                      </template>

                      <div class="card-content">
                        <div class="content">
                          {{ state.information }}
                          <br />
                          <br />
                          <div class="is-flex">
                            <p style="color: gray; font-size: 14px">
                              Duración:
                              {{
                                state.duration === 0
                                  ? "No tiene"
                                  : state.duration
                              }}
                            </p>
                          </div>
                          <div class="is-flex">
                            <p style="color: gray; font-size: 14px">
                              Revisores:
                            </p>
                            <b-tag
                              class="mx-1"
                              v-for="(ip, index) in state.internal_positions"
                              :key="index"
                              type="is-primary"
                              closable
                              aria-close-label="Close tag"
                              @close="
                                () => {
                                  state.internal_positions.splice(index, 1);
                                }
                              "
                            >
                              {{ ip.name }}
                            </b-tag>
                          </div>
                          <div class="is-flex">
                            <p style="color: gray; font-size: 14px">
                              Documentos:
                            </p>
                            <b-tag
                              class="is-secondary mx-1"
                              v-for="(ip, index) in state.documents"
                              :key="index"
                              type="is-primary"
                              closable
                              aria-close-label="Close tag"
                              @close="
                                () => {
                                  state.documents.splice(index, 1);
                                }
                              "
                            >
                              {{ ip.name }}
                            </b-tag>
                          </div>
                          <div class="is-flex">
                            <p style="color: gray; font-size: 14px">
                              Formularios:
                            </p>
                            <b-tag
                              class="is-info mx-1"
                              v-for="(ip, index) in state.forms"
                              :key="index"
                              type="is-primary"
                              closable
                              aria-close-label="Close tag"
                              @close="
                                () => {
                                  state.forms.splice(index, 1);
                                }
                              "
                            >
                              {{ ip.name }}
                            </b-tag>
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </draggable>
              </div>
            </div>
          </div>

          <div class="is-flex is-justify-content-center">
            <button class="button is-secondary mx-1" @click="setPreviousStep">
              Volver
            </button>
            <button class="button is-primary mx-1" @click="setAccessStep">
              Siguiente
            </button>
          </div>
        </b-step-item>

        <b-step-item step="4" label="Permisos" :clickable="false">
          <div
            class="is-flex is-align-items-center is-justify-content-space-between is-flex-wrap-wrap my-4"
          >
            <div class="field is-flex mb-0 mt-2">
              <h1 class="title is-4">
                Controlar acceso a la solicitud
                <b-tooltip
                  label="En este paso se debe definir los roles que tienen permisos para iniciar solicitudes de este tipo. Ejemplo: Analista OVV, Profesores por hora, etc."
                  multilined
                >
                  <i class="fas fa-info-circle"> </i>
                </b-tooltip>
              </h1>
            </div>
          </div>
          <b-message
            v-if="alertInternalPosition !== ''"
            type="is-danger"
            has-icon
            aria-close-label="Close message"
          >
            {{ alertInternalPosition }}
          </b-message>
          <div class="control my-3">
            <label class="label mb-3"
              >Seleccione los roles que podrán iniciar esta solicitud *</label
            >

            <b-table
              :data="
                optionsInternalPositions == null ? [] : optionsInternalPositions
              "
              :paginated="true"
              :pagination-simple="false"
              pagination-position="bottom"
              :current-page="currentPageTable"
              per-page="15"
              :checked-rows.sync="selectedInternalPositions"
              :checkable="true"
              :bordered="false"
              :striped="true"
              :narrowed="false"
              :hoverable="true"
              :loading="isLoading == null ? true : false"
              :focusable="false"
              :mobile-cards="true"
              @check-all="selectAll"
            >
              <!-- Column Name -->
              <b-table-column
                field="name"
                :visible="true"
                label="Nombre"
                width="400"
                sortable
                searchable
              >
                <template slot="searchable" slot-scope="props">
                  <b-input
                    v-model="props.filters[props.column.field]"
                    placeholder="Buscar por nombre"
                    icon="search"
                    size="is-small"
                  />
                </template>
                <template v-slot="props">
                  {{ props.row.name }}
                </template>
              </b-table-column>

              <!-- Column Description -->
              <b-table-column
                field="descripción"
                :visible="true"
                width="550"
                label="Descripción"
              >
                <template v-slot="props">
                  <span class="is-flex is-align-content-center">
                    {{ props.row.description }}
                  </span>
                </template>
              </b-table-column>

              <template #empty>
                <div class="has-text-centered">
                  No se encontraron solicitudes
                </div>
              </template>
            </b-table>
          </div>

          <div class="is-flex is-justify-content-center">
            <button class="button is-secondary mx-1" @click="setPreviousStep">
              Volver
            </button>
            <button class="button is-primary mx-1" @click="createRequestType">
              Crear
            </button>
          </div>
        </b-step-item>
      </b-steps>

      <!-- Modals -->
      <b-modal
        v-model="isModalCreateStateActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
      >
        <template #default="props">
          <modal-create-state
            v-on:newstate="updateStates"
            modal-header="Crear paso"
            @close="props.close"
          >
          </modal-create-state>
        </template>
      </b-modal>

      <b-modal
        v-model="isModalEditStateActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
      >
        <template #default="props">
          <modal-edit-state
            modal-header="Editar paso"
            :stateToEdit="currentState"
            @close="props.close"
          >
          </modal-edit-state>
        </template>
      </b-modal>

      <b-modal
        v-model="isModalAddItemsActive"
        :destroy-on-hide="true"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
        style="height: auto"
        width="100%"
        class="custom-modal"
      >
        <template #default="props">
          <modal-add-items
            style="width: 90vw"
            v-on:stateWithInternalPositions="updateStateInternalPositions()"
            :modal-header="addItem"
            :forms="inputFormLinks"
            :currentState="currentState"
            @close="props.close"
          >
          </modal-add-items>
        </template>
      </b-modal>

      <b-loading
        :is-full-page="true"
        v-model="isLoading"
        :can-cancel="false"
      ></b-loading>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import ModalCreateState from "../States/ModalCreateState";
import ModalAddItems from "./ModalAddItems";
import ModalEditState from "../States/ModalEditState";
import { verifyFile } from "../../packs/utilities";

export default {
  name: "CreateRequestType",
  components: {
    ModalCreateState,
    draggable,
    ModalAddItems,
    ModalEditState,
  },
  data: function () {
    return {
      // variable for loading sping while fetching data from backend
      isLoading: false,
      validationStepState: "",
      addItem: "EE",
      currentState: {},
      requieredRoles: "0",
      requieredStates: "0",
      selectOrigin: {
        optionsOrigins: [],
        selectedOrigin: null,
      },
      selectRoleType: {
        optionsRoleTypes: [],
        selectedRoleTypes: null,
      },
      selectRoleState: {
        optionsRoleState: [],
        selectedRolState: null,
      },
      //variables for inputs from user
      dropFiles: [],
      initialState: {},
      selectedStates: [
        {
          name: "RECEPCIÓN",
          real_name: "Recepción de la solicitud",
          information:
            "Paso donde se recepcionan los documentos para comenzar a gestionar la solicitud.",
          duration: 0,
          internal_positions: [],
          documents: [],
          forms: [],
        },
        {
          name: "RESOLUCIÓN",
          real_name: "Resolución de la solicitud",
          information: "Se acepta o se rechaza la solicitud",
          duration: 0,
          internal_positions: [],
          documents: [],
          forms: [],
        },
      ],
      optionsStates: [],
      inputFormLinks: [
        {
          name: "",
          link: "",
        },
      ],
      inputNameRequest: "",
      inputDescription: "",
      inputLongDescription: "",
      formData: {},

      // Variables for errores in post or get
      validationErrors: {},
      //Modal data
      isModalCreateStateActive: false,
      isModalAddItemsActive: false,
      isModalEditStateActive: false,

      //Steps data
      activeStep: 0,
      statesNotOrdered: "",

      showSocial: false,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: false,

      hasNavigation: true,
      customNavigation: true,
      isProfileSuccess: false,

      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      labelPosition: "bottom",
      mobileMode: "minimalist",
      orderedStates: [],
      selectedInternalPositions: [],
      optionsInternalPositions: [],
      alertInternalPosition: "",
      currentPageTable: 1,

      needsToken: true,
      saveResponseInDrive: true,
    };
  },

  async created() {
    document.title = "Creación de solicitud";
    this.isLoading = true;
    await this.getOrigins();
    await this.getRoleStates();
    await this.getRoleTypes();

    //Bring states from bakend
    //Meke axios to rails backend
    await this.getStates();

    await this.getInternalPositions();
  },

  methods: {
    async getStates() {
      this.isLoading = true;
      return await axios
        .get("/states.json")
        .then((response) => {
          // save fetched states into this.optionStates
          this.optionsStates = response.data;
          this.isLoading = false;
        })
        //manage errors
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    getDefaultInternalPosition() {
      if (this.selectOrigin.selectedOrigin != null) {
        if (this.selectOrigin.selectedOrigin.name == "SRC") {
          let registrador_curricular = this.optionsInternalPositions.filter(
            (x) => {
              return x.name == "REGISTRADOR CURRICULAR";
            }
          );

          if (registrador_curricular.length === 1) {
            registrador_curricular = registrador_curricular[0];

            for (let i = 0; i < this.selectedStates.length; i++) {
              let state = this.selectedStates[i];
              if (
                state.internal_positions.indexOf(registrador_curricular) == -1
              ) {
                state.internal_positions.push(registrador_curricular);
              }
            }
          }
          return;
        }
      }
      return;
    },
    async getInternalPositions() {
      this.isLoading = true;
      return await axios
        .get("/internal_positions.json")
        .then((response) => {
          // save fetched states into this.optionStates
          this.optionsInternalPositions = response.data.filter((x) => {
            return x.name != "SUPERADMIN";
          });
          this.isLoading = false;
        })
        //manage errors
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    async getOrigins() {
      this.isLoading = true;
      return await axios
        .get("/origins.json")
        .then((response) => {
          this.selectOrigin.optionsOrigins = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.selectOrigin.optionsOrigins = [];
          this.isLoading = false;
        });
    },
    async getRoleStates() {
      this.isLoading = true;
      return await axios
        .get("/role_states.json")
        .then((response) => {
          this.selectRoleState.optionsRoleState = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.selectRoleState.optionsRoleState = [];
          this.isLoading = false;
        });
    },
    async getRoleTypes() {
      this.isLoading = true;
      return await axios
        .get("/role_types.json")
        .then((response) => {
          this.selectRoleType.optionsRoleTypes = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.selectRoleType.optionsRoleTypes = [];
          this.isLoading = false;
        });
    },
    addItemsToState(stateIndex, header) {
      this.currentState = this.selectedStates[stateIndex];
      this.addItem = header;
      this.isModalAddItemsActive = true;
    },

    updateStateInternalPositions(index) {
      console.log(index);
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    addFormLink() {
      this.inputFormLinks.push({
        name: "",
        link: "",
      });
    },
    deleteFormLink() {
      this.inputFormLinks.pop();
      if (this.inputFormLinks.length === 0) {
        this.activeStep = 0;
      }
    },

    uploadFiles() {
      this.formData = new FormData();
      try {
        this.dropFiles.forEach((file) => {
          this.formData.append("docs", file);
        });
      } catch (error) {
        console.log("Hubo un problema al subir los archivos");
        this.formData.append("docs", null);
      }
    },

    createRequestType() {
      if (!this.checkInternalPositions()) {
        return;
      }
      /*VERIFICACION FRON ARCHIVOS*/
      this.dropFiles.forEach((doc) => {
        let error = verifyFile(doc);
        if (error) {
          this.$buefy.dialog.alert({
            title: "Error en archivos",
            message: `Error en: ${doc.name}: ${error}`,
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
          return;
        }
      });

      if (this.initialState.forms.length == 0) {
        this.$buefy.dialog.alert({
          title: "Error en Paso 1",
          message: `Se necesita adjuntar un Google Forms`,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
        return;
      }

      //console.log("this is createTypeRequest")
      this.isLoading = true;
      let name = this.inputNameRequest.toUpperCase().normalize("NFD");

      this.formData = new FormData();
      this.formData.append("name", name);
      this.formData.append("real_name", this.inputNameRequest);
      this.formData.append("description", this.inputDescription);
      this.formData.append("long_description", this.inputLongDescription);
      this.formData.append(
        "internal_positions",
        JSON.stringify(this.selectedInternalPositions)
      );

      this.orderedStates = [this.initialState].concat(this.orderedStates);

      this.formData.append("states", JSON.stringify(this.orderedStates));
      this.formData.append("origin", this.selectOrigin.selectedOrigin.id);

      if (
        this.selectOrigin.selectedOrigin &&
        this.selectOrigin.selectedOrigin.name == "SRC"
      ) {
        this.formData.append(
          "role_type",
          this.selectRoleType.selectedRoleType.id
        );
      } else {
        this.formData.append("role_type", 0);
      }

      if (
        this.selectRoleType.selectedRoleType &&
        this.selectRoleType.selectedRoleType.has_role_state
      ) {
        this.formData.append(
          "role_state",
          this.selectRoleState.selectedRolState.id
        );
      } else {
        this.formData.append("role_state", 0);
      }

      this.formData.append("needs_token", this.needsToken);
      this.formData.append("save_response_in_drive", this.saveResponseInDrive);

      //this.inputFormLinks.forEach(form => {
      //  this.formData.append("forms[]", form);
      //});
      //this.formData.append("forms", JSON.stringify(this.inputFormLinks))
      this.dropFiles.forEach((file) => {
        this.formData.append("files[]", file);
      });

      axios
        .post("/request_types.json", this.formData)
        .then((response) => {
          this.$buefy.dialog.confirm({
            title: "Listo",
            message: "La solicitud se ha creado exitosamente.",
            type: "is-success",
            hasIcon: true,
            icon: "check-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
            canCancel: false,
            onConfirm: () => {
              window.location.href = "/request_types/index-admin";
            },
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.validationErrors = error.response.data;
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.createRequestType.scrollTop = 0;
          this.$buefy.dialog.alert({
            title: "Error",
            message:
              "Hubo errores al crear la solicitud, favor revisar formato",
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
          this.isLoading = false;
        });
    },

    updateStates(newState) {
      this.validationStepState = "";
      this.optionsStates.push(newState);
      this.selectedStates.push(newState);
    },

    editState(stateIndex) {
      this.currentState = this.selectedStates[stateIndex];
      this.isModalEditStateActive = true;
    },

    removeState(index) {
      this.selectedStates.splice(index, 1);
    },

    setStatesStep() {
      this.activeStep += 1;
    },
    setPreviousStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1;
      }
      if (this.activeStep === 1) {
        this.selectedStates = this.selectedStates.concat(this.orderedStates);
        this.selectedStates = [this.initialState].concat(this.selectedStates);
        this.orderedStates = [];
      }
    },
    setStepOrderStates() {
      if (this.selectedStates.length === 1) {
        this.validationStepState =
          "Debe existir al menos un paso más que el estado inicial.";
        var elmnt = document.getElementById("app");
        elmnt.scrollIntoView();
        this.$refs.createRequestType.scrollTop = 0;
        return;
      }

      for (let i = 0; i < this.selectedStates.length; i++) {
        if (this.selectedStates[i].internal_positions.length === 0) {
          this.validationStepState =
            "Todos los pasos deben tener al menos un revisor.";
          var elmnt = document.getElementById("app");
          elmnt.scrollIntoView();
          this.$refs.createRequestType.scrollTop = 0;
          return;
        }
      }
      this.initialState = this.selectedStates[0];
      if (this.initialState.forms.length === 0) {
        this.validationStepState =
          "Debe existir al menos un formulario en el paso inicial.";
        var elmnt = document.getElementById("app");
        elmnt.scrollIntoView();
        this.$refs.createRequestType.scrollTop = 0;
        return;
      }
      this.selectedStates.splice(0, 1);
      this.validationStepState = "";
      this.keepTheOrder();
      this.activeStep += 1;
    },

    setAccessStep() {
      if (this.selectedStates.length > 0) {
        this.statesNotOrdered = "Deben estar todos los pasos ordenados";
        return;
      }
      this.statesNotOrdered = "";
      this.activeStep += 1;
    },

    keepTheOrder() {
      this.orderedStates = this.selectedStates;
      this.selectedStates = [];
      this.statesNotOrdered = "";
    },

    checkInternalPositions() {
      if (this.selectedInternalPositions.length === 0) {
        this.alertInternalPosition =
          "Debes seleccionar al menos un rol que pueda crear este tipo de solicitud.";
        return false;
      }
      if (this.selectedInternalPositions.length > 0) {
        this.alertInternalPosition = "";
        return true;
      }
    },

    selectAll(rows) {
      if (rows.length > this.selectedInternalPositions.length) {
        this.$nextTick(() => {
          this.selectedInternalPositions = this.optionsInternalPositions;
        });
      } else {
        this.$nextTick(() => {
          this.selectedInternalPositions = [];
        });
      }
    },
  },
};
</script>

<style scoped>
.drop-states-div {
  background: rgb(242, 242, 242);
  background: radial-gradient(
    circle,
    rgba(242, 242, 242, 1) 0%,
    rgba(242, 242, 242, 1) 100%
  );
}
</style>
