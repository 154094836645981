var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container box section mt-5"},[_c('div',{staticClass:"title is-2"},[_vm._v("Búsqueda de Estudiantes SIAC")]),_vm._v(" "),_c('hr',{staticClass:"solid mb-5"}),_vm._v(" "),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.studentsFilter)}}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column pb-0"},[_c('b-field',{attrs:{"label":"Ingrese RUT"}},[_c('ValidationProvider',{attrs:{"rules":{
                  required: _vm.email === '' && _vm.name === '' && _vm.lastname === '' && _vm.surname === '',
                  regex: /\b\d{1,3}(\.\d{3})*-[0-9kK]\b/,
                }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-input',{attrs:{"type":"text","placeholder":"Ingrese RUT (ej: 12.345.678-k)"},model:{value:(_vm.formattedRut),callback:function ($$v) {_vm.formattedRut=$$v},expression:"formattedRut"}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Ingrese Nombres"}},[_c('ValidationProvider',{attrs:{"rules":{
                      at_least_two_filled: { name: _vm.name, lastname: _vm.lastname, surname: _vm.surname },
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-input',{attrs:{"placeholder":"Ingrese Nombre"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_vm._v(" "),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Ingrese Primer Apellido"}},[_c('ValidationProvider',{attrs:{"rules":{
                  at_least_two_filled: { name: _vm.name, lastname: _vm.lastname, surname: _vm.surname },
                }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-input',{attrs:{"placeholder":"Ingrese Primer Apellido"},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_vm._v(" "),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Ingrese Segundo Apellido"}},[_c('ValidationProvider',{attrs:{"rules":{
                  at_least_two_filled: { name: _vm.name, lastname: _vm.lastname, surname: _vm.surname },
                }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-input',{attrs:{"placeholder":"Ingrese Segundo Apellido"},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column pb-0"},[_c('b-field',{attrs:{"label":"Ingrese Correo Electrónico"}},[_c('ValidationProvider',{attrs:{"rules":{
                      required: _vm.rut === '' && _vm.name === '' && _vm.lastname === '' && _vm.surname === '',
                      email: true,
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-input',{attrs:{"type":"text","placeholder":"Ingrese Correo Electrónico (ej: nombre.apellido@usach.cl)"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('span',{staticClass:"validation-alert"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"level-right buttons"},[_c('b-button',{staticClass:"is-secondary",attrs:{"icon-left":"search","native-type":"submit"}},[_vm._v("\n            Buscar\n          ")])],1)])]}}])}),_vm._v(" "),(_vm.students)?_c('div',[_c('StudentListCards',{attrs:{"students":_vm.students}})],1):_vm._e()],1),_vm._v(" "),_c('b-loading',{attrs:{"is-full-page":true,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }