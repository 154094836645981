<template>
  <div style="background-color: white">
    <img src="/gsd/home_student_images/headers/header.png" class="responsive" />

    <div class="columns is-centered">
      <div class="column is-9">
        <!--solicitudes-->
        <div class="section" style="background-color: white">
          <!-- Titulo de la sección -->
          <div class="is-flex is-justify-content-center">
            <h1
              class="subtitle is-2 has-text-secondary has-text-weight-bold is-family-secondary"
            >
              ¡BIENVENID@!
            </h1>
          </div>
          <div
            class="is-size-6 has-text-secondary has-text-centered is-family-secondary has-text-weight-bold"
          >
            <p>
              <br />
              Les damos la bienvenida al nuevo portal web de solicitudes de
              Docencia de la Facultad de Ingeniería, en el cual se podrán
              realizar de manera más rápida estos trámites estudiantiles. Te
              recomendamos utilizar el portal a través de una computadora o
              tablet para facilitar tus procesos.<br /><br />
              Recuerde que las solicitudes que se realizan en este portal, son
              dirigidas para estudiantes de la FING USACH de la modalidad
              DIURNO.
            </p>
          </div>

          <!-- Separador -->
          <hr class="solid" />
          <div class="columns is-multiline is-desktop">
            <div
              v-if="canCreateRequest"
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-one-third-fullhd"
            >
              <a
                class="button is-customgrey is-large is-fullwidth"
                :href="list_requests_path"
              >
                <span>
                  <i class="fas fa-file-alt mx-2"></i>
                </span>
                <span> Solicitudes </span>
              </a>
            </div>

            <div
              class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-one-third-fullhd"
            >
              <a
                class="button is-customgrey is-large is-fullwidth"
                :href="my_requests_path"
              >
                <span>
                  <i class="fas fa-file-invoice"> </i>
                </span>
                <span> Mis Solicitudes </span>
              </a>
            </div>
          </div>
        </div>
        <!--Video Youtube-->

        <!--Carrusel de imagenes-->

        <b-carousel :pause-info="false">
          <b-carousel-item v-for="(item, i) in items" :key="i">
            <b-image class="image" :src="item.src"></b-image>
          </b-carousel-item>
        </b-carousel>
        <!--mapa-->

        <div class="map-responsive" align="center">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6658.140749411799!2d-70.682722!3d-33.447473!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c45848597283%3A0xe2156c9c42e38433!2sAv.%20Libertador%20Bernardo%20O&#39;Higgins%203363%2C%20Santiago%2C%20Estaci%C3%B3n%20Central%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1ses!2sus!4v1662082872808!5m2!1ses!2sus"
            width="600"
            height="450"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            eferrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>

    <!-- Footer -->

    <div style="background-color: #003b71" align="center">
      <img src="logo_sin_texto.png" />
      <div>
        <a href="https://www.fing.usach.cl/" target="_blank">
          <h1
            class="is-size-6 has-text-warning has-text-centered is-family-secondary has-text-weight-bold"
          >
            WEB OFICIAL FING USACH | Entra aquí
          </h1>
        </a>
        <br />
      </div>
    </div>
    <div style="background-color: #fac633" align="center">
      <br />
      <h1
        class="is-size-6 has-text-black has-text-centered is-family-secondary"
      >
        Universidad de Santiago de Chile. Avenida Libertador Bernardo O'Higgins
        nº 3363. Estación Central. Santiago. Chile.
      </h1>
      <a href="https://www.usach.cl/contacto" target="_blank">
        <h1
          class="is-size-6 has-text-secondary has-text-centered is-family-secondary has-text-weight-bold"
        >
          Oficina de Informaciones, Reclamos y Sugerencias (OIRS)
        </h1>
      </a>
      <br />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Youtube from "../Tools/Youtube";

export default {
  name: "GsdHome",
  components: { Youtube },
  props: ["canListRequests", "canCreateRequest"],
  data: function () {
    return {
      list_requests_path: "/msd/request_types",

      search_requests_path: "/requests/search",
      my_requests_path: "/requests/my_requests",
      isModalSelectRequestTypeActive: false,
      requestTypes: ["", "Nombramiento"],
      selectedRequestType: null,
      items: [
        {
          src: require("../../../../public/gsd/home_student_images/carousel_images/Imagen1.png"),
        },
        {
          src: require("../../../../public/gsd/home_student_images/carousel_images/Imagen2.png"),
        },
        {
          src: require("../../../../public/gsd/home_student_images/carousel_images/Imagen3.png"),
        },
        {
          src: require("../../../../public/gsd/home_student_images/carousel_images/Imagen4.png"),
        },
        {
          src: require("../../../../public/gsd/home_student_images/carousel_images/Imagen5.png"),
        },
      ],
    };
  },

  created() {
    document.title = "MMSD | Inicio";
    this.getRequestTypes();
  },

  methods: {
    async getRequestTypes() {
      await axios
        .get("/request_types.json")
        .then((response) => {
          this.requestTypes = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.requestTypes = [];
        });
    },

    debug() {},
    listRequests() {
      //This functions is called when button "Listar Solicitudes" is pressed
      //do something
      //call backend
    },
    openModalSelectRequestType() {
      //This functions is called when button "crear Solicitud" is pressed
      //do something
      this.isModalSelectRequestTypeActive = true;
    },
    redirectToNewRequest() {
      //This functions is called when button "Buscar Solicitud" is pressed
      if (this.selectedRequestType == null) {
        // There's no type request selected
        console.log("No se ha seleccionado Tipo de Solicitud");
      } else {
        window.location.href = "/requests/request_types";
      }
    },
  },
};
</script>

<style>
.map-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.map-responsive iframe {
  margin: 2rem;
  width: 600px;
  height: 450px;
}

.responsive {
  width: 100%;
  height: auto;
}

/* Solid border */
hr.solid {
  border-top: 2px solid #bbb;
  width: 100%;
}
</style>
