<template>
  <div>
    <div class="container box section mt-5">
      <div class="title is-2">Búsqueda de Estudiantes SIAC</div>
      <hr class="solid mb-5" />
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(studentsFilter)">
          <div class="columns">
            <div class="column pb-0">
              <b-field label="Ingrese RUT">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{
                    required: email === '' && name === '' && lastname === '' && surname === '',
                    regex: /\b\d{1,3}(\.\d{3})*-[0-9kK]\b/,
                  }"
                >
                  <b-input
                    v-model="formattedRut"
                    type="text"
                    placeholder="Ingrese RUT (ej: 12.345.678-k)"
                  ></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <b-field label="Ingrese Nombres">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{
                        at_least_two_filled: { name, lastname, surname },
                      }"
                >
                  <b-input
                    v-model="name"
                    placeholder="Ingrese Nombre"
                  ></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Ingrese Primer Apellido">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{
                    at_least_two_filled: { name, lastname, surname },
                  }"
                >
                  <b-input
                    v-model="lastname"
                    placeholder="Ingrese Primer Apellido"
                  ></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Ingrese Segundo Apellido">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{
                    at_least_two_filled: { name, lastname, surname },
                  }"
                >
                  <b-input
                    v-model="surname"
                    placeholder="Ingrese Segundo Apellido"
                  ></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column pb-0">
              <b-field label="Ingrese Correo Electrónico">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{
                        required: rut === '' && name === '' && lastname === '' && surname === '',
                        email: true,
                      }"
                >
                  <b-input
                    v-model="email"
                    type="text"
                    placeholder="Ingrese Correo Electrónico (ej: nombre.apellido@usach.cl)"
                  ></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>

          <div class="level-right buttons">
            <b-button
              class="is-secondary"
              icon-left="search"
              native-type="submit"
            >
              Buscar
            </b-button>
          </div>
        </form>
      </ValidationObserver>

      <div v-if="students">
        <StudentListCards :students="students"></StudentListCards>
      </div>
    </div>

    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import axios from "axios";
import StudentListCards from "./StudentListCards.vue";
export default {
  components: { StudentListCards },
  data() {
    return {
      rut: "",
      name: "",
      lastname: "",
      surname: "",
      email: "",
      isLoading: false,
      students: null,
    };
  },
  methods: {
    searchByRUT() {
      this.isLoading = true;
      this.studentsByRut = null;
      axios
        .get(`/people/students/by-rut.json`, {
          params: {
            rut: this.rut.slice(0, -1),
          },
        })
        .then((response) => {
          this.studentsByRut = response.data.students;
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.status === 404) {
            this.$buefy.dialog.alert({
              title: "Atención",
              message:
                "No se encontraron estudiantes con la información ingresada.",
              hasIcon: true,
              type: "is-warning",
              confirmText: "Ok",
            });
          } else {
            this.$buefy.dialog.alert({
              title: "Error",
              message: "Error al realizar la búsqueda, por favor reintente.",
              hasIcon: true,
              type: "is-danger",
              confirmText: "Ok",
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    searchByNames() {
      this.isLoading = true;
      this.studentsByName = null;
      axios
        .get(`/people/students/by-name.json`, {
          params: {
            name: this.name,
            lastname: this.lastname,
            surname: this.surname,
          },
        })
        .then((response) => {
          this.studentsByName = response.data.students;
        })
        .catch((error) => {
          console.error(error);
          if (error.response.data.status === 404) {
            this.$buefy.dialog.alert({
              title: "Atención",
              message:
                "No se encontraron estudiantes con la información ingresada.",
              hasIcon: true,
              type: "is-warning",
              confirmText: "Ok",
            });
          } else {
            this.$buefy.dialog.alert({
              title: "Error",
              message: "Error al realizar la búsqueda, por favor reintente.",
              hasIcon: true,
              type: "is-danger",
              confirmText: "Ok",
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    searchByEmail() {
      this.isLoading = true;
      this.studentsByEmail = null;
      axios
        .get(`/people/students/by-email.json`, {
          params: {
            email: this.email,
          },
        })
        .then((response) => {
          this.studentsByEmail = response.data.students;
        })
        .catch((error) => {
          console.error(error);
          if (error.response.data.status === 404) {
            this.$buefy.dialog.alert({
              title: "Atención",
              message:
                "No se encontraron estudiantes con la información ingresada.",
              hasIcon: true,
              type: "is-warning",
              confirmText: "Ok",
            });
          } else {
            this.$buefy.dialog.alert({
              title: "Error",
              message: "Error al realizar la búsqueda, por favor reintente.",
              hasIcon: true,
              type: "is-danger",
              confirmText: "Ok",
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    studentsFilter() {
      this.isLoading = true;
      this.students = null;
      axios
        .get(`/people/students/filter.json`, {
          params: {
            rut: this.rut.slice(0, -1),
            name: this.name,
            lastname: this.lastname,
            surname: this.surname,
            email: this.email,
          },
        })
        .then((response) => {
          this.students = response.data.students;
        })
        .catch((error) => {
          console.error(error);
          if (error.response.data.status === 404) {
            this.$buefy.dialog.alert({
              title: "Atención",
              message:
                "No se encontraron estudiantes con la información ingresada.",
              hasIcon: true,
              type: "is-warning",
              confirmText: "Ok",
            });
          } else {
            this.$buefy.dialog.alert({
              title: "Error",
              message: "Error al realizar la búsqueda, por favor reintente.",
              hasIcon: true,
              type: "is-danger",
              confirmText: "Ok",
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    formattedRut: {
      get() {
        if (this.rut.length > 1) {
          let cleanedRut = this.rut.replace(/[^0-9kK]/g, "");
          let rutBody = cleanedRut.slice(0, -1);
          let dv = cleanedRut.slice(-1).toUpperCase();
          return rutBody.replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "-" + dv;
        }
      },
      set(value) {
        this.rut = value.replace(/[^0-9kK]/g, "").toUpperCase();
      },
    },
    formattedRutVerification: {
      get() {
        if (this.rutVerification.length > 1) {
          let cleanedRut = this.rutVerification.replace(/[^0-9kK]/g, "");
          let rutBody = cleanedRut.slice(0, -1);
          let dv = cleanedRut.slice(-1).toUpperCase();
          return rutBody.replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "-" + dv;
        }
      },
      set(value) {
        this.rutVerification = value.replace(/[^0-9kK]/g, "").toUpperCase();
      },
    },
  },
  created() {
    document.title = "Búsqueda de Estudiantes SIAC";
  },
};
</script>

<style lang="scss" scoped></style>
