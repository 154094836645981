var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"white"}},[_c('img',{staticClass:"responsive",attrs:{"src":"/gsd/home_student_images/headers/header.png"}}),_vm._v(" "),_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-9"},[_c('div',{staticClass:"section",staticStyle:{"background-color":"white"}},[_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('hr',{staticClass:"solid"}),_vm._v(" "),_c('div',{staticClass:"columns is-multiline is-desktop"},[(_vm.canCreateRequest)?_c('div',{staticClass:"column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-one-third-fullhd"},[_c('a',{staticClass:"button is-customgrey is-large is-fullwidth",attrs:{"href":_vm.list_requests_path}},[_vm._m(2),_vm._v(" "),_c('span',[_vm._v(" Solicitudes ")])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-one-third-fullhd"},[_c('a',{staticClass:"button is-customgrey is-large is-fullwidth",attrs:{"href":_vm.my_requests_path}},[_vm._m(3),_vm._v(" "),_c('span',[_vm._v(" Mis Solicitudes ")])])])])]),_vm._v(" "),_c('b-carousel',{attrs:{"pause-info":false}},_vm._l((_vm.items),function(item,i){return _c('b-carousel-item',{key:i},[_c('b-image',{staticClass:"image",attrs:{"src":item.src}})],1)}),1),_vm._v(" "),_vm._m(4)],1)]),_vm._v(" "),_vm._m(5),_vm._v(" "),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"is-flex is-justify-content-center"},[_c('h1',{staticClass:"subtitle is-2 has-text-secondary has-text-weight-bold is-family-secondary"},[_vm._v("\n            ¡BIENVENID@!\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"is-size-6 has-text-secondary has-text-centered is-family-secondary has-text-weight-bold"},[_c('p',[_c('br'),_vm._v("\n            Les damos la bienvenida al nuevo portal web de solicitudes de\n            Docencia de la Facultad de Ingeniería, en el cual se podrán\n            realizar de manera más rápida estos trámites estudiantiles. Te\n            recomendamos utilizar el portal a través de una computadora o\n            tablet para facilitar tus procesos."),_c('br'),_c('br'),_vm._v("\n            Recuerde que las solicitudes que se realizan en este portal, son\n            dirigidas para estudiantes de la FING USACH de la modalidad\n            DIURNO.\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"fas fa-file-alt mx-2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"fas fa-file-invoice"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map-responsive",attrs:{"align":"center"}},[_c('iframe',{staticStyle:{"border":"0"},attrs:{"src":"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6658.140749411799!2d-70.682722!3d-33.447473!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c45848597283%3A0xe2156c9c42e38433!2sAv.%20Libertador%20Bernardo%20O'Higgins%203363%2C%20Santiago%2C%20Estaci%C3%B3n%20Central%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1ses!2sus!4v1662082872808!5m2!1ses!2sus","width":"600","height":"450","allowfullscreen":"","loading":"lazy","eferrerpolicy":"no-referrer-when-downgrade"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"#003b71"},attrs:{"align":"center"}},[_c('img',{attrs:{"src":"logo_sin_texto.png"}}),_vm._v(" "),_c('div',[_c('a',{attrs:{"href":"https://www.fing.usach.cl/","target":"_blank"}},[_c('h1',{staticClass:"is-size-6 has-text-warning has-text-centered is-family-secondary has-text-weight-bold"},[_vm._v("\n          WEB OFICIAL FING USACH | Entra aquí\n        ")])]),_vm._v(" "),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"#fac633"},attrs:{"align":"center"}},[_c('br'),_vm._v(" "),_c('h1',{staticClass:"is-size-6 has-text-black has-text-centered is-family-secondary"},[_vm._v("\n      Universidad de Santiago de Chile. Avenida Libertador Bernardo O'Higgins\n      nº 3363. Estación Central. Santiago. Chile.\n    ")]),_vm._v(" "),_c('a',{attrs:{"href":"https://www.usach.cl/contacto","target":"_blank"}},[_c('h1',{staticClass:"is-size-6 has-text-secondary has-text-centered is-family-secondary has-text-weight-bold"},[_vm._v("\n        Oficina de Informaciones, Reclamos y Sugerencias (OIRS)\n      ")])]),_vm._v(" "),_c('br')])
}]

export { render, staticRenderFns }