<template>
    <div>
        <section class="pt-6">
            <div class="columns is-centered">
                <div class="column is-three-quarters has-background-white">
                    <!-- Titulo de la sección -->
                    <div class="columns is-vcentered ">
                        <div class="column is-flex is-justify-content-flex-start">
                            <h1 class="subtitle is-1 has-text-secondary">
                                Administración de códigos de documentos

                            </h1>
                        </div>
                    </div>
                    <!-- Separador -->
                    <hr class="solid">
                  <ValidationObserver ref="observer"  v-slot="{ handleSubmit}">
                    <form @submit.prevent="handleSubmit(applyFilters)">
                      <div class="columns is-multiline my-2 px-5">
                        <div class="column is-half">
                          <b-field label="Búsqueda por código de solicitud">
                            <ValidationProvider rules="" v-slot="{errors}">
                              <b-input
                                  v-model="filters.request_code"
                                  placeholder="Ej: SRC-15"
                              ></b-input>
                              <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>
                        <div class="column is-half">

                          <b-field label="Búsqueda por RUT">
                            <ValidationProvider rules="runValidation" v-slot="{errors}">
                              <b-input
                                  v-model="filters.run"
                                  maxlength="11"
                                  placeholder="Ej: 12345678-k"
                              ></b-input>
                              <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>

                        <!-- StartDate filter -->
                        <div class="column is-half">
                          <b-field label="Búsqueda por Fecha de creación desde">
                            <b-datepicker
                                placeholder="Selecciona una Fecha"
                                icon="calendar-alt"
                                trap-focus
                                v-model="filters.start_created_at"
                                :icon-right="filters.start_created_at ? 'close-circle' : ''"
                                icon-right-clickable
                                @icon-right-click="clearStartDate"
                                :date-formatter="(date) => date.toLocaleDateString('es-ES')"
                                locale="es"
                            >
                            </b-datepicker>
                          </b-field>
                        </div>

                        <!-- EndDate filter -->
                        <div class="column is-half">
                          <b-field label="Búsqueda por Fecha de creación hasta">
                            <b-datepicker
                                placeholder="Selecciona una Fecha"
                                icon="calendar-alt"
                                icon-pack="fas"
                                trap-focus
                                v-model="filters.end_created_at"
                                :icon-right="filters.end_created_at ? 'close-circle' : ''"
                                icon-right-clickable
                                @icon-right-click="clearEndDate"
                                :date-formatter="(date) => date.toLocaleDateString('es-ES')"
                                locale="es"
                            >
                            </b-datepicker>
                          </b-field>
                        </div>

                        <!-- Button to Apply filters -->
                        <div class="column is-flex is-align-items-end is-justify-content-end">
                          <b-button native-type="submit"
                                    type="is-secondary"
                                    icon-left="search"
                          >Buscar
                          </b-button>
                        </div>
                      </div>
                    </form>
                  </ValidationObserver>


                </div>

            </div>

            <div class="columns is-centered is-multiline ">
                <div class="column is centered is-three-quarters has-background-white">
                    <b-table
                            :data="tokens"
                            :detailed="true"
                            :paginated="isPaginated"
                            :per-page="perPage"
                            :current-page.sync="currentPage"
                            :pagination-position="paginationPosition"
                            :default-sort-direction="defaultSortDirection"
                            :sort-icon="sortIcon"
                            :sort-icon-size="sortIconSize"
                            default-sort="created_at"
                            aria-next-label="Next page"
                            aria-previous-label="Previous page"
                            aria-page-label="Page"
                            aria-current-label="Current page"
                            selectable

                    >
                      <b-table-column field="token" label="Código de documento" sortable searchable>
                        <template slot="searchable" slot-scope="props">
                          <b-input
                              v-model="props.filters[props.column.field]"
                              placeholder="Buscar por nombre"
                              icon="search"
                              size="is-small"
                          />
                        </template>
                        <template  v-slot="props">
                          {{ props.row.token }}
                        </template>

                      </b-table-column>
                      <b-table-column field="request" label="Código de solicitud" sortable searchable>
                        <template slot="searchable" slot-scope="props">
                          <b-input
                              v-model="props.filters[props.column.field]"
                              placeholder="Buscar por id"
                              icon="search"
                              size="is-small"
                          />
                        </template>
                        <template  v-slot="props">
                          {{ props.row.request.code }}
                        </template>

                      </b-table-column>
                      <b-table-column field="applicant.full_name" label="Solicitante" sortable searchable>
                        <template slot="searchable" slot-scope="props">
                          <b-input
                              v-model="props.filters[props.column.field]"
                              placeholder="Buscar por nombre"
                              icon="search"
                              size="is-small"
                          />
                        </template>
                        <template  v-slot="props">
                          {{ props.row.applicant.full_name }}
                        </template>

                      </b-table-column>
                      <b-table-column field="applicant.run" label="RUT solicitante" sortable searchable>
                        <template slot="searchable" slot-scope="props">
                          <b-input
                              v-model="props.filters[props.column.field]"
                              placeholder="Buscar por run"
                              icon="search"
                              size="is-small"
                          />
                        </template>
                        <template  v-slot="props">
                          {{ props.row.applicant.run }}
                        </template>

                      </b-table-column>
                      <b-table-column field="created_at" label="Fecha de emisión" sortable searchable
                                      :custom-sort="customSortCreatedAt">
                        <template slot="searchable" slot-scope="props">
                          <b-input
                              v-model="props.filters[props.column.field]"
                              placeholder="Buscar por fecha"
                              icon="search"
                              size="is-small"
                          />
                        </template>
                        <template  v-slot="props">
                          {{ props.row.created_at }}
                        </template>
                      </b-table-column>

                      <b-table-column label="Acciones" v-slot="props" width="30%">
                        <b-button v-if="props.row.active" class= "is-danger is-light is-border-darkred" @click="toggleToken(props.row.id)">
                          <b-icon            type="is-danger"
                                             icon="times"
                                             size="is-medium"
                                             class="mr-1"
                          >
                          </b-icon> Deshabilitar
                        </b-button>
                        <b-button v-else class= "is-success is-light is-border-darkgreen" @click="toggleToken(props.row.id)">
                          <b-icon            type="is-success"
                                             icon="check"
                                             size="is-medium"
                                             class="mr-1"

                          >
                          </b-icon> Habilitar
                        </b-button>
                        <b-button  class= " is-secondary is-light is-border-cornflowerblue"  @click="goToRequest(props.row.request.id)">
                          <b-icon            type="is-secondary"
                                             icon="eye"
                                             size="is-medium"
                                             class="mr-1"

                          >
                          </b-icon> Solicitud asociada
                        </b-button>
                      </b-table-column>
                      <template #detail="props">
                        <article class="media">
                          <div class="media-content">
                            <div class="content">
                              <p>
                                  Carrera del solicitante: {{ props.row.career}}
                                <br/>
                                  Unidad de la solicitante: {{ props.row.unit.name }}
                                <br>
                                Tipo de solicitud: {{props.row.request_type.name}}
                              </p>
                            </div>
                          </div>
                        </article>
                      </template>
                      <template #empty>
                        <div class="has-text-centered">No se encontraron coincidencias de códigos</div>
                      </template>
                    </b-table>

                </div>
            </div>

        </section>


        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
</template>
<script>

import axios from "axios";
import moment from "moment/moment";
import ModalEditUser from './ModalEditUser.vue'
import ModalAssignCargo from './ModalAssignCargo.vue'
import {openBuefyAlertDialog, openBuefyConfirmDialog, dateToString} from "../../packs/utilities";

export default {
    name: "TokenManagement",
    data() {
        return {
            validationErrors: {},
            isPaginated: true,
            paginationPosition: 'bottom',
            defaultSortDirection: 'desc',
            sortIcon: 'arrow-up',
            sortIconSize: 'is-small',
            currentPage: 1,
            perPage: 10,
            isLoading: false,
            isModalActive: false,
            tokens: [],
            filters:{
                request_code:null,
                applicant_rut:null,
                start_created_at:null,
                end_created_at:null
            }



        }
    },
   async  created() {
      document.title="Administración de códigos"
       await this.getTokenInfo();



    },
    components: {
    },
    methods: {
      clearStartDate() {
        this.filters.start_created_at = null;
      },
      clearEndDate() {
        this.filters.end_created_at = null;
      },
      goToRequest(requestId){
        window.location.href="/requests/"+requestId;
      },
      async getTokenInfo(){
        this.isLoading=true
        return await axios.get('/dashboard/token-management.json')
            .then(response => {
              this.tokens = response.data
              this.tokens = this.tokens.map(token => {
                var newToken = token
                newToken["created_at"] = moment(newToken["created_at"], "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY");
                return newToken;
              });
            })
            .catch(error => {
              console.log(error);

            }).finally(()=>{
              this.isLoading=false;
        });
      },

      toggleToken(tokenId,active) {

        let title = "Deshabilitar código"
        let message = "Esta acción deshabilitará el código de validación del documento."
        if (active) {
          title = "Habilitar código"
          message = "Esta acción habilitará el código de validación del documento."
        }
        this.$buefy.dialog.confirm({
          title: title,
          message: message,
          cancelText: "Cancelar",
          confirmText: "Aceptar",
          type: "is-success",
          onConfirm: () =>{
            this.isLoading=true
            axios
                .post("/document_tokens/toggle-token/"+tokenId)
                .then(()=> {

                  openBuefyConfirmDialog(this,"Listo","Se ha cambiado la validez del código ")
                  window.location.reload()
                      }
                ).catch( (error) => {
                    openBuefyAlertDialog(this,"Error","No se ha podido "+ title.toLowerCase())
                  console.log(error)
                }
            ).finally(()=>{
              this.isLoading=false
            }
            );
          }
        });
      },
      applyFilters(){
        let params= { ...this.filters}
        params.start_created_at = params.start_created_at ?  dateToString(params.start_created_at) : null;
        params.end_created_at = params.end_created_at ?  dateToString(params.end_created_at): null;

        this.isLoading=true;
        axios.get('token-management.json',{
                params
          }
        )
            .then(response => {
              this.tokens = response.data
              this.tokens = this.tokens.map(token => {
                var newToken = token
                newToken["created_at"] = moment(newToken["created_at"], "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY");
                return newToken;
              });
            })
            .catch(error => {
              console.error(error);

            }).finally(()=>{
          this.isLoading=false;
        });
      },
      compareDate(a, b) {
        if (a == null) {
          return -1;
        }
        if (b == null) {
          return 1;
        }
        const dateA = new Date(a.split('-').reverse().join('-'));
        const dateB = new Date(b.split('-').reverse().join('-'));
        return dateA - dateB;
      },

      customSortCreatedAt(a, b, isAsc) {
        return isAsc ? this.compareDate(a.created_at, b.created_at) : this.compareDate(b.created_at, a.created_at);
      },

    }

}
</script>