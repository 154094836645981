<template>
  <div class="card is-border-light-grey">
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
    <div class="card-content">
      <div class="content">
        <div class="has-text-centered">
          <div class="my-1"><b>Nombre completo:</b> {{ student.fullname }}</div>
          <div class="my-1"><b>RUT:</b> {{ student.rut }}</div>
          <div class="my-1"><b>Correo SIAC:</b> {{ student.email }}</div>
          <div class="my-1"><b>Carreras cursadas:</b></div>
          <div v-for="career in student.careers" class="my-1">
            {{ formatCareerName(career) }}
            <b-icon
              v-if="career.jornada === 'Diurno'"
              class="mr-1"
              icon="sun"
            ></b-icon>
            <b-icon
              v-else-if="career.jornada === 'Vespertino'"
              class="mr-1"
              icon="moon"
            ></b-icon>
            <b-icon v-else class="mr-1" icon="question"></b-icon>
            Ingreso {{ career.semestre_ingreso }}-{{ career.agno_ingreso }}
          </div>
          <div class="columns is-centered mt-2">
            <div class="column">
              <b-button
                class="is-secondary is-light is-border-cornflowerblue"
                icon-left="copy"
                @click="copyToClipboard(student.rut)"
              >
                Copiar RUT al portapapeles
              </b-button>
              <b-button
                  class="is-primary is-light is-border-darkgoldenrod"
                  icon-left="external-link-alt"
                  @click="openSegic(student.rut)"
              >
                Verificar Correo USACH en SEGIC
              </b-button>
            </div>
          </div>
          <div v-if="segicEmail !== null" class="has-text-centered">
            <div class="columns is-centered mt-2">
              <div class="column is-6 is-border-black">
                <div v-if="segicEmail !== ''" class="title is-6 my-2">
                  Correo USACH encontrado en SEGIC:
                  <div class="subtitle is-6 my-3">
                    {{ segicEmail }}
                    <b-tooltip
                      v-if="segicEmail === student.email"
                      type="is-info"
                      label="Correo en SEGIC coincide con SIAC"
                      multilined
                      position="is-top"
                    >
                      <b-icon
                        size="is-medium"
                        icon="check-circle"
                        type="is-success"
                      ></b-icon>
                    </b-tooltip>
                    <b-tooltip
                      v-else
                      type="is-info"
                      label="Correo en SEGIC no coincide con SIAC"
                      multilined
                      position="is-top"
                    >
                      <b-icon
                        size="is-medium"
                        icon="times-circle"
                        type="is-danger"
                      ></b-icon>
                    </b-tooltip>
                  </div>
                </div>
                <div v-else class="title is-6 my-2">
                  No se encontró correo USACH asociado en SEGIC.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  props: ["student"],
  data() {
    return {
      isLoading: false,
      segicEmail: null,
    };
  },
  methods: {
    openSegic(rut) {
      this.copyToClipboard(rut);
      window.open("http://usuariocorreo.usach.cl", "_blank");
    },
    formatCareerName(career) {
      let jornada = career.jornada;
      if (career.jornada === "") jornada = "Jornada indefinida";
      return `[${career.codigo}] ${career.nombre_carrera} - ${career.tipo}, ${jornada} `;
    },
    copyToClipboard(rut) {
      navigator.clipboard.writeText(rut);
      this.$buefy.toast.open({
        message: `RUT ${rut} copiado al portapapeles`,
        type: "is-success",
        position: "is-bottom",
        duration: 5000,
      });
    },
    verifyEmailByRut() {
      this.isLoading = true;
      this.segicEmail = null;
      axios
        .get(`/people/students/email-from-segic.json`, {
          params: {
            rut: this.student.rut.replace(/[.-]/g, ""),
          },
        })
        .then((response) => {
          this.segicEmail = response.data.email;
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === 404) {
            this.segicEmail = "";
            this.$buefy.dialog.alert({
              title: "Atención",
              message: "Este RUT no posee correo USACH asociado en SEGIC.",
              hasIcon: true,
              type: "is-warning",
              confirmText: "Ok",
            });
          } else {
            this.$buefy.dialog.alert({
              title: "Error",
              message: "Error al realizar la búsqueda, por favor reintente.",
              hasIcon: true,
              type: "is-danger",
              confirmText: "Ok",
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
