<template>
  <div class="container" style="padding: 2rem; background-color: #f2f2f2">
    <div class="section" style="background-color: white; height: 100%">
      <!-- Titulo de la sección -->
      <div class="is-flex is-justify-content-flex-start">
        <h1 class="subtitle is-1 has-text-secondary">Mis Solicitudes</h1>

      </div>

      <!-- Separador -->
      <hr class="solid" />
      <ValidationObserver ref="observer"  v-slot="{ handleSubmit}">
        <form @submit.prevent="handleSubmit(applyFilters)">
      <!-- Filters Section -->
          <div class="columns is-multiline my-2 px-5">
            <!-- State filter -->
            <div class="column is-full">
              <div class="card">
                <div class="card-content">
                  <div class="content">
                    <div class="columns is-multiline my-2 px-5">
                      <div class="column is-half">
                        <b>Nombre Completo:</b> {{ user.full_name }}
                      </div>
                      <div class="column is-half">
                        <b>Correo:</b> {{ user.email }}
                      </div>
                      <div class="column is-half"><b>RUN:</b> {{ run }}</div>
                      <div v-if="user.alt_email" class="column is-half">
                        <b>Correo Alternativo:</b> {{ user.alt_email }}
                      </div>
                    </div>
                    <b-notification v-if="user && user.alt_email && !user.corporate_email_active" :closable="false" type="is-warning" has-icon>
                      Usted posee un correo alternativo habilitado para acceder a su cuenta en el sistema SAS y recibir
                      las notificaciones. <br> El correo alternativo es el siguiente: <b>{{ user.alt_email }}</b>.
                    </b-notification>
                    <b-notification v-else-if="user.alt_email && user.corporate_email_active" :closable="false" type="is-warning" has-icon>
                      Usted posee su <b>correo institucional</b> habilitado para recibir las notificaciones e ingresar al sistema SAS.
                      El correo alternativo no está habilitado para recibir notificaciones ni ingresar al sistema.
                    </b-notification>

                  </div>
                </div>
              </div>
            </div>
            <div class="column is-half">
              <b-field label="Búsqueda por Código">
                <ValidationProvider rules="" v-slot="{errors}">
                  <b-input
                      v-model="request_id"
                      placeholder="Ej: SRC-15"
                  ></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
            <div class="column is-full-mobile is-full-tablet is-half-desktop">
              <b-field label="Filtrar por Estado">
                <multiselect
                  v-model="selectedProgressOption"
                  :options="progress"
                  :custom-label="
                    (opt) => progress.find((x) => x.id === opt.id).real_name
                  "
                  :multiple="false"
                  placeholder="Seleccione un estado"
                  selectLabel="Presione para seleccionar"
                  selectedLabel="Seleccionado"
                  deselectLabel="Presione para deseleccionar"
                  track-by="id"
                  label="name"
                >
                  <template v-slot:noOptions> No existen datos</template>
                  <span slot="noResult"> No se encontraron elementos. </span>
                </multiselect>
              </b-field>
            </div>

            <!-- Unit filter -->
            <div
              v-if="canFilterUnit"
              class="column is-full-mobile is-full-tablet is-half-desktop"
            >
              <b-field label="Filtrar por Unidad">
                <multiselect
                  v-model="selectedUnitOption"
                  :options="units"
                  :custom-label="(opt) => units.find((x) => x.id === opt.id).name"
                  :multiple="false"
                  placeholder="Seleccione una unidad"
                  selectLabel="Presione para seleccionar"
                  selectedLabel="Seleccionado"
                  deselectLabel="Presione para deseleccionar"
                  track-by="id"
                  label="name"
                >
                  <template v-slot:noOptions> No existen datos</template>
                  <span slot="noResult"> No se encontraron elementos. </span>
                </multiselect>
              </b-field>
            </div>


            <!-- StartDate filter -->
            <div class="column is-full-mobile is-full-tablet is-half-desktop">
              <b-field label="Busqueda por Fecha desde">
                <b-datepicker
                  placeholder="Selecciona una Fecha"
                  icon="calendar-alt"
                  trap-focus
                  v-model="selectedStartDate"
                  :icon-right="selectedStartDate ? 'close-circle' : ''"
                  icon-right-clickable
                  @icon-right-click="clearStartDate"
                  :date-formatter="(date) => date.toLocaleDateString('es-ES')"
                  locale="es"
                >
                </b-datepicker>
              </b-field>
            </div>

            <!-- EndDate filter -->
            <div class="column is-full-mobile is-full-tablet is-half-desktop">
              <b-field label="Filtrar por Fecha hasta">
                <b-datepicker
                  placeholder="Selecciona una Fecha"
                  icon="calendar-alt"
                  icon-pack="fas"
                  trap-focus
                  v-model="selectedEndDate"
                  :icon-right="selectedEndDate ? 'close-circle' : ''"
                  icon-right-clickable
                  @icon-right-click="clearEndDate"
                  :date-formatter="(date) => date.toLocaleDateString('es-ES')"
                  locale="es"
                >
                </b-datepicker>
              </b-field>
            </div>

            <!-- Button to Apply filters -->
            <div class="column is-flex is-align-items-end is-justify-content-end">
              <b-button type="is-secondary" native-type="submit"
                >Aplicar Filtros
              </b-button>
            </div>
          </div>
          <!-- End Filters Section -->
        </form>
      </ValidationObserver>
      <!-- Separator -->
      <hr class="solid" />

      <div class="columns  is-vcentered my-2 px-5">
        <div class="column">
          <b-collapse
              class="card "
              animation="slide"
              :open="isOpen"
              :aria-id="'contentIdForA11y5'">
            <template #trigger="props">
              <div
                  class="card-header"
                  role="button"
                  :aria-controls="'contentIdForA11y5'"
                  :aria-expanded="props.open">
                <p class="card-header-title is-flex is-justify-content-left subtitle is-3-desktop is-5-mobile has-text-secondary has-text-weight-bold is-family-secondary ">
                  <b-icon
                      icon="info-circle">
                  </b-icon> Iconografía de Estados
                </p>
                <a class="card-header-icon">
                  <b-icon
                      :icon="props.open ? 'minus-circle' : 'plus-circle'">
                  </b-icon>
                </a>
              </div>
            </template>

            <div class="card-content" >
              <div class="columns is-multiline is-vcentered">
                <div class="column is-full">
                  <p class="title">
                    Estados
                  </p>
                </div>
                <div class="column is-full" >
                  <b-tag
                      :class="PROGRESSES['Enviada'].color"
                  >
                    <b-icon :icon="PROGRESSES['Enviada'].icon" class="mr-1">  </b-icon>Enviada
                  </b-tag> {{ getProgressFromId(PROGRESSES['Enviada'].id) ? getProgressFromId(PROGRESSES['Enviada'].id).description : ""}}
                </div>
                <template  v-for="(state,key,index) of PROGRESSES">
                  <div v-if="index>0" class="column is-half">
                    <b-tag
                        :class="state.color"
                    >
                      <b-icon :icon="state.icon" class="mr-1">  </b-icon>{{ key}}
                    </b-tag> {{ getProgressFromId(state.id) ? getProgressFromId(state.id).description : ""}}
                  </div>
                </template>
              </div>

            </div>

          </b-collapse>
        </div>
      </div>

      <table-list-requests
        :applicant_information="false"
        :actions="false"
        :requests="requests"
        :loading="isLoading"
      ></table-list-requests>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import axios from "axios";
import TableListRequests from "../TableListRequests.vue";
import moment from "moment/moment";
import {PROGRESSES} from "../../../packs/utilities.js";

export default {
  name: "MyRequests",
  computed: {
    PROGRESSES() {
      return PROGRESSES
    },
  },
  props: ["canFilterType", "canFilterUnit", "user", "run"],
  components: {
    TableListRequests,
  },
  data: function () {
    return {
      states: [
        "",
        "Depto. Académico",
        "Secretaria FING",
        "Secretaria Académica",
        "Decano",
      ],
      progress: [],
      selectedProgressOption: null,
      selectedStateOption: null,
      units: ["", "Depto. Informatica", "Facultad de Ingeniería"],
      selectedUnitOption: null,
      requestTypes: ["", "Nombramiento"],
      selectedRequestTypeOption: null,
      selectedStartDate: null,
      selectedEndDate: null,
      isLoading: false,
      requests: [],
      request_id:null,
      isOpen: false,
    };
  },
  created() {
    document.title = "Mis solicitudes"
    this.isLoading = true;

    this.getFilters();

    axios
      .get("/requests/my_requests.json")
      .then((response) => {
        this.requests = response.data.requests;
        this.requests = this.requests.map((request) => {
          var newRequests = request;

          newRequests["start_date"] = moment(
            request["start_date"],
            "YYYY-MM-DD HH:mm:ss"
          ).format("DD-MM-YYYY");
          newRequests["end_date"] = newRequests["end_date"]!= null ? moment(
              request["end_date"],
              "YYYY-MM-DD HH:mm:ss"
          ).format("DD-MM-YYYY") : null;
          return newRequests;
        });
        this.isLoading = false;
      })
      .catch((error) => {
        this.isLoading = false;
      });
  },
  methods: {
    createCustomLabelProgress() {
      this.progress.forEach((e) => {
        if (e.name === "Aprobado") {
          e["real_name"] = "Finalizada";
        } else if (e.name === "Detenida") {
          e["real_name"] = "Detenida";
        } else {
          e["real_name"] = e.name;
        }
      });
    },

    async getFilters() {
      await axios
        .get("/progresses.json")
        .then((response) => {
          this.progress = response.data;
          this.createCustomLabelProgress();
        })
        .catch((error) => {
          console.error(error);
          this.progress = [];
        });


      await axios
        .get("/request_types.json")
        .then((response) => {
          this.requestTypes = response.data;
        })
        .catch((error) => {
          //console.log(error);
          this.requestTypes = [];
        });
    },

    applyFilters() {

      this.isLoading = true;
      let params = {
        request_id: this.request_id,
        progress_id:
          this.selectedProgressOption == null
            ? null:
              this.selectedProgressOption.id,

        unit:
            this.selectedUnitOption == null
                ? null :
                this.selectedUnitOption.name,
        request_type:
          this.selectedRequestTypeOption == null
            ? null
            : this.selectedRequestTypeOption.name,
        start_date:
          this.selectedStartDate == null
            ? null
            : this.selectedStartDate.getFullYear() +
              "-" +
              (this.selectedStartDate.getMonth() + 1) +
              "-" +
              this.selectedStartDate.getDate(),
        end_date:
          this.selectedEndDate == null
            ? null
            : this.selectedEndDate.getFullYear() +
              "-" +
              (this.selectedEndDate.getMonth() + 1) +
              "-" +
              this.selectedEndDate.getDate(),
        my_requests: true,
      };

      axios
        .get("/requests/apply-filters.json", {
          params,
        })
        .then((response) => {

          this.requests = response.data.requests;
          this.requests = this.requests.map((request) => {
            var newRequests = request;

            newRequests["start_date"] = moment(
              request["start_date"],
              "YYYY-MM-DD HH:mm:ss"
            ).format("DD-MM-YYYY");
            return newRequests;
          });
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    clearStartDate() {
      this.selectedStartDate = null;
    },
    getProgressFromId(id){
      return this.progress.find(
          (x) => x.id === id
      ) ?  this.progress.find((x) => x.id === id): null;
    },
    clearEndDate() {
      this.selectedEndDate = null;
    },
  },
};
</script>
