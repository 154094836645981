<template>
  <div>
    <b-loading
      v-model="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></b-loading>



    <div class="container box my-5">
      <h1 class="title">Firma Digital Simple <br>
        Masivo - Mismo Tipo Documento </h1>
      <p class="mb-4">
        Esta herramienta permite insertar una firmar digital simple a múltiples documentos
        PDF simultáneamente. La firma debe estar en
        formato PNG y tener un fondo transparente.

      </p>

        <b-notification
            class="is-primary is-light"
            has-icon
            icon="exclamation-circle"
        >
          <b>¡IMPORTANTE!</b> <br>
          - Se recomienda utilizar este módulo con el navegador
          <b>Google Chrome</b> para evitar problemas de rendimiento o bloqueos de su computador. <br>
          - Se recomienda seleccionar <b>solo un  texto a buscar para firmar</b>, para evitar la repetición o cercanía de ubicación de firma.

        </b-notification>


      <b-steps v-model="activeStep" class="pt-4">
        <b-step-item
          step="1"
          label="Subir Archivos"
          :type="step1Complete ? 'is-success' : 'is-primary'"
        >
          <!--
          <b-field label="Clase de documentos a Firmar:">
            <multiselect
                v-model="documentType"
                :options="documentTypes"
                placeholder="Seleccione tipos de documentos a firmar"
                selectLabel="Presione para seleccionar"
                selectedLabel="Seleccionado"
                deselectLabel="No se puede deseleccionar"
                :allow-empty="false"
            >
              <template v-slot:noOptions> No existen datos</template>
              <span slot="noResult"> No se encontraron elementos. </span>
            </multiselect>
          </b-field>
          <b-message
              class="is-info is-light"
              has-icon
              title="información de clase de documentos a firmar"
              icon="info-circle"
          >
            - <b>Mismo Tipo de Documentos</b>: Se asume que la posición seleccionada para la firma en un documento, es la misma para todos. <br>
            - <b>Diferente Tipo de Documento</b>: Se deberá selecionar por cada documento la posición de la firma.
          </b-message>
          <hr />
        -->
          <div class="columns is-flex align-items-stretch">
            <div class="column">
              <b-field extended label="Firma (formato PNG)*">
                <b-upload
                  v-model="signatureFile"
                  expanded
                  required
                  accept=".png"
                  drag-drop
                  @input="onAddSignatureImage"
                >
                  <div class="content has-text-centered p-4">
                    <p>
                      <b-icon icon="image" size="is-large"></b-icon>
                    </p>
                    <p>Arrastra tu firma o haz clic para subir.</p>
                    <b-tag
                      v-if="signatureFile"
                      icon="image"
                      type="is-success is-light"
                    >
                      {{ shortTextFile(signatureFile.name, 48) }}
                    </b-tag>
                  </div>
                </b-upload>
              </b-field>
            </div>
            <div class="column" v-if="signatureFile">
              <div class="has-text-weight-bold mb-2">
                Firma a insertar (vista previa)
              </div>
              <div class="signature-container">
                <img
                  :src="signatureFileUrl"
                  class="signature-preview"
                  :style="{ height: `${signatureImageHeight}px` }"
                />
                <div>
                  <div class="columns is-desktop is-centered">
                    <div
                      class="column has-text-centered is-size-7 mt-2 mb-0 pb-0"
                    >
                      Altura: {{ signatureImageHeight }}px
                      <div
                        :style="{
                          color:
                            signatureImageHeight === 60 ? 'green' : 'red',
                          fontSize: '0.75rem',
                        }"
                      >
                        {{ signatureImageHeight === 60 ? "(Sugerida)" : "" }}
                      </div>
                    </div>
                  </div>

                  <div class="columns is-mobile is-centered">
                    <div class="column is-narrow has-text-right">
                      <b-button
                        icon-left="minus"
                        type="is-primary is-outlined is-small"
                        :disabled="signatureImageHeight === 50"
                        @click="scaleDown"
                      >
                        Disminuir
                      </b-button>
                    </div>
                    <div class="column is-narrow has-text-left">
                      <b-button
                        icon-right="plus"
                        type="is-primary is-outlined is-small"
                        :disabled="signatureImageHeight === 100"
                        @click="scaleUp"
                      >
                        Aumentar
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column" v-else>
              <div class="has-text-weight-bold mb-2">
                Firma a insertar (vista previa)
              </div>
              <div class="signature-container-signature-off">
                    Sin Firma Ingresada
              </div>
            </div>
          </div>
          <div class="columns mt-4">
            <div class="column">
              <b-field extended label="Subir Archivos en formato PDF (Máx. 50)*">
                <b-upload
                  v-model="pdfFiles"
                  expanded
                  drag-drop
                  multiple
                  accept=".pdf"
                  required
                  validation-message="El archivo debe tener extensión PDF"
                >
                  <div class="content has-text-centered p-4">
                    <p>
                      <b-icon icon="file-pdf" size="is-large"></b-icon>
                    </p>
                    <p>Arrastra tu PDF o haz clic para subir.</p>
                    <span v-for="(file, index) in pdfFiles"
                        :key="index"
                        class="tag is-primary mr-1" >
                        {{ shortTextFile(file.name, 48) }}
                        <button class="delete is-small"
                            type="button"
                            @click="deleteDropFile(index)">
                        </button>
                        <br>
                    </span>
                  </div>
                </b-upload>
              </b-field>
            </div>
          </div>
          <hr/>
          <br>
          <b-field label="Seleccione pie de firma a buscar con reconocedor de texto:">
            <div>
              <div class="columns">
                <b-checkbox class="column" v-model="checkboxGroup"
                            :native-value="cargoActivo">
                  {{cargoActivo}}
                </b-checkbox>
                <b-checkbox class="column" v-if="cargoActivo.split(' ').length>1" v-model="checkboxGroup"
                            :native-value="cargoActivo.split(' ')[0]">
                  {{cargoActivo.split(" ")[0]}}
                </b-checkbox>
                <b-checkbox class="column" v-model="checkboxGroup"
                            :native-value="user.name">
                  {{user.name}}
                </b-checkbox>

              </div>
              <div class="columns">
                <b-checkbox class="column" v-model="checkboxGroup"
                            native-value="Firma">
                  Firma
                </b-checkbox>
                <b-checkbox class="column" v-model="checkboxGroup"
                            native-value="Timbre">
                  Timbre
                </b-checkbox>
                <b-checkbox class="column" v-model="checkboxGroup"
                            native-value="Firma y Timbre">
                  Firma y Timbre
                </b-checkbox>


              </div>
              <div class="columns">
                <b-checkbox class="column" v-model="checkboxGroup"
                            native-value="Directivo">
                  Directivo
                </b-checkbox>

                <b-checkbox class="column" v-model="checkboxGroup"
                            native-value="Jefe superior">
                  Jefe superior
                </b-checkbox>
                <div class="column"></div>


              </div>
            </div>
          </b-field>

          <br>

          <div v-if="
                customLoadingPercentage > 0 &&
                customLoadingPercentage < 100
              ">
            <br>
            <br>
            <b-progress
                :value="customLoadingPercentage"
                type="is-primary"
                size="is-medium"
                :show-value="true"
            >
              {{ customLoadingMessage }}
            </b-progress>
          </div>
          <br>


          <div class="columns">
            <div class="column is-centered">
              <b-button
                  class="button is-primary"
                  icon-left="search"
                  icon-pack="fas"
                  expanded
                  :disabled="pdfFileCount < 1 || signatureFile == null || pdfFileCount>limitFiles"
                  @click="onAddPdfFile(pdfFiles)"
              >Buscar Pie de Firma en Documentos [{{pdfFileCount}} / {{limitFiles}}]
              </b-button>
            </div>
          </div>
          
          
          <div v-if="pdfSource.length>0">
            <br>
            <hr />
            <br>
            <div class="columns">
              <div class="column is-5">

                <b-field label="Desplazar firma Verticalmente:">
                  <b-slider v-model="moveUp" :min="100" :max="260" :step="40"  aria-label="Fan" size="is-medium" :tooltip="false">
                    <b-slider-tick :value="100">Muy Abajo</b-slider-tick>
                    <b-slider-tick :value="140">Abajo</b-slider-tick>
                    <b-slider-tick :value="180">Al medio</b-slider-tick>
                    <b-slider-tick :value="220">Arriba</b-slider-tick>
                    <b-slider-tick :value="260">Muy Arriba</b-slider-tick>
                  </b-slider>
                </b-field>
                
              </div>
              <div class="column is-offset-1">
                <b-field label="Desplazar firma Horizontalmente:">
                  <b-slider v-model="moveRight" :min="0" :max="60" :step="15"  aria-label="Fan" :tooltip="false">
                    <b-slider-tick :value="0">Muy a la Izquierda</b-slider-tick>
                    <b-slider-tick :value="15">Izquierda</b-slider-tick>
                    <b-slider-tick :value="30">Al medio</b-slider-tick>
                    <b-slider-tick :value="45">Derecha</b-slider-tick>
                    <b-slider-tick :value="60">Muy a la Derecha</b-slider-tick>
                  </b-slider>
                </b-field>
              </div>
              
            </div>
            <br>
            <div class="has-text-weight-bold mb-2">
              <h2 class="mt-4 mb-2">
                Se han identificado
                <span class="has-text-weight-bold">{{ pdfSource.length }} documentos posibles a firmar </span>
                . Por favor, seleccione la ubicación <b> en cada documento</b> donde desee aplicar su firma.
              </h2>
            </div>
            <br>
            <br>
            <div class="columns" v-for="(file, index_doc) in pdfSource" :key="index_doc">
              <div class="column is-12">
                <b-collapse
                    aria-id="contentIdForA1l"
                    class='custom-collapse'
                    animation="slide"
                    v-model="isOpenGrafico[index_doc]">
                  <template #trigger="props">
                    <div
                        class="card-header"
                        :class="!imageSelected[index_doc].includes(-1) && imageSelected[index_doc].length>0 ? 'custom-success-collapse' : 'custom-incomplete-collapse'"
                        role="button"
                        aria-controls="contentId"
                        :aria-expanded="props.open">
                      <h2 v-if="!imageSelected[index_doc].includes(-1) && imageSelected[index_doc].length>0" class="card-header-title custom-header-title-subplots">
                       <span> <b-icon icon="check-circle"> </b-icon> [{{index_doc+1}}] {{pdfCorrectFiles[index_doc].name}} - pie de firma <b> seleccionado </b> en  </span>
                      </h2>
                      <h2 v-else class="card-header-title custom-header-title-subplots ">
                       <span> <b-icon icon="exclamation-circle"> </b-icon> [{{index_doc+1}}] {{pdfCorrectFiles[index_doc].name}} - <b>{{signatureImages[index_doc].length}} pie(s) de firma detectado(s): </b> </span>
                      </h2>


                      <a class="card-header-icon" style="padding: 0rem 0rem" 
                        @click.stop="dropFileColapse(index_doc)"
                      >
                      <b-button rounded type="is-danger" style="border-radius: 50%;"
                                  icon-left="trash">
                      </b-button>
                      </a>

                      <a class="card-header-icon">
                        <b-icon class="is-info" size="is-large" :icon="props.open ? 'minus-circle' : 'plus-circle'"></b-icon>
                      </a>
                    </div>
                  </template>
                  <div class="hero-body">
                    <div class="columns">
                      <div class="column pdf-container " style="border: 2px solid lightgrey; padding: 10px;">
                        <h3 class="is-size-4 has-text-weight-bold has-text-centered">Documento</h3>                        
                        <div class="columns is-mobile is-centered">
                          <div
                            class="column is-half has-text-centered is-size-7 mt-2"
                          >
                            Página: {{ currentPage[index_doc] }} de {{ pageCount[index_doc] }}
                          </div>
                        </div>
                        <div class="columns is-mobile is-centered">
                          <div class="column is-narrow has-text-right">
                            <b-button
                              icon-left="arrow-left"
                              type="is-primary is-small"
                              :disabled="currentPage[index_doc] === 1"
                              @click="backPage(index_doc)"
                            >
                              Página anterior
                            </b-button>
                          </div>
                          <div class="column is-narrow has-text-left">
                            <b-button
                              icon-right="arrow-right"
                              type="is-primary is-small"
                              :disabled="currentPage[index_doc] === pageCount[index_doc]"
                              @click="nextPage(index_doc)"
                            >
                              Página siguiente
                            </b-button>
                          </div>
                        </div>
                        <div class="columns has-text-centered my-0">
                          <div class="column has-text-centered my-0">
                            <b-button
                                icon-left="undo"
                                type="is-primary is-light"
                                outlined
                                @click="rotatePdf('left',index_doc)"
                            >
                            </b-button>
                            <b-button
                                icon-right="redo"
                                type="is-primary is-light"
                                outlined
                                @click="rotatePdf('right',index_doc)"
                            >
                            </b-button>
                            <b-button
                                icon-left="search-plus"
                                type="is-primary is-light"
                                outlined
                                @click="zoomIn(index_doc)"
                                :disabled="zoomInDisabled[index_doc]"
                            >
                            </b-button>
                            <b-button
                                icon-right="search-minus"
                                type="is-primary is-light"
                                outlined
                                @click="zoomOut(index_doc)"
                                :disabled="zoomOutDisabled[index_doc]"
                            >
                            </b-button>
                          </div>
                        </div>
                        <div style="display: flex; justify-content: center;">
                          <pdf
                            :src="file"
                            :page="currentPage[index_doc]"                          
                            :ref="`pdfViewer-${index_doc}`"
                            @num-pages="pageCount[index_doc] = $event"
                            :rotate="angle[index_doc]"
                            :style="{ width: `${zoom[index_doc]}%` }"
                          />
                        </div>
                      </div>
                      <div class="column" style="border: 2px solid lightgrey; padding: 10px;">
                        <h3 class="is-size-4 has-text-weight-bold has-text-centered">Ubicaciones para Firmar</h3> <br>
                        <div class="columns is-variable is-2 is-multiline" >                        
                          <div
                            v-for="(image, index) in signatureImages[index_doc]"
                            :key="index"
                            class="column is-one-widescreen is-half-desktop is-half-tablet is-full-mobile"
                          >
                            <div v-if="imageSelected[index_doc].includes(index)" class="image-container">
                              <img
                                :src="image"
                                alt="Vista previa de ubicación para la firma"
                                class="signature-preview"
                                :style="{padding: '1rem',
                                        border: '2px solid #EE8600',
                                        cursor: 'pointer',
                                        borderRadius: '8px',}"
                                @click="deselectImageStepTwo(index_doc,index)"
                              />
                              <span class="image-index">{{ index + 1 }}</span>
                            </div>
                            <div v-else class="image-container">
                              <img
                                :src="image"
                                alt="Vista previa de ubicación para la firma"
                                class="signature-preview"
                                :style="{padding: '1rem',
                                        border: '1px solid #dbdbdb',
                                        cursor: 'pointer',
                                        borderRadius: '8px',}"
                                @click="selectImageStepTwo(index_doc,index)"
                              />
                              <span class="image-index">{{ index + 1 }}</span>
                            </div>
                          </div>
                        </div>
                      </div> 
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>




        </b-step-item>
        <b-step-item
          step="2"
          label="Consentimiento y Descarga"
          :clickable="step2Complete"
          :type="consentGiven ? 'is-success' : 'is-primary'"
        >
          <b-message
            title="Acuerdo de Consentimiento y Exención de Responsabilidad"
            type="is-warning"
            has-icon
            class="my-4"
            :closable="false"
          >
            <p>
              Al marcar la siguiente casilla, usted reconoce y acepta como
              usuario la responsabilidad de verificar la precisión de los documentos
              tanto de entrada como de salida, incluyendo la correcta
              incrustación y ubicación de la firma, utilizando esta herramienta.
              Es esencial que revise cuidadosamente cada etapa del proceso antes
              de confirmar su descarga, ya que el uso del archivo generado es de
              su exclusiva responsabilidad. Asimismo, SAS declara no asumir
              responsabilidad por errores, omisiones o imprecisiones que puedan
              presentarse en el resultado final del documento. Por último, en
              alineación con nuestro compromiso con su privacidad y seguridad,
              le informamos que el sistema no guarda ni los archivos de entrada
              y salida, ni la firma utilizada.
            </p>
          </b-message>
          <b-checkbox v-model="consentGiven">
            He leído y acepto los términos y condiciones de uso.
          </b-checkbox>

          <br>
          <br>
          <br>

          <div class="columns">
            <div class="column">
              <b-field grouped >
                <template #label>
                  Código de autenticación de 6 digitos*
                  <b-tooltip
                      label="Abra su aplicación 'Google Authenticathor' para ver su código de autenticación."
                      position="is-right"
                      size="is-medium"
                      multilined>

                    <b-icon
                        pack="fas"
                        icon="info-circle"
                        size="is-small"
                        type="is-info">
                    </b-icon>
                  </b-tooltip>
                </template>

                <b-input placeholder="XXXXXX" v-model="autenticatorQr" maxlength="6"></b-input>
                <p class="control">
                  <b-tooltip label="Configure su App 'Google Authenticator' para generar códigos escaneando el siguiente código QR">
                    <b-button type="is-primary" icon-pack="fas" icon-left="qrcode" v-if="show_qr"
                    @click.prevent="openQr" >
                    Ver QR
                  </b-button>
                </b-tooltip>
              </p>
              </b-field>
            </div>
          </div>

          <div
            class="mb-4 has-text-right"
          >
            <b-button
                type="is-warning"
                icon-pack="fas"
                icon-left="sync"
                @click.prevent="reloadDocument"
            >
              Subir nuevos documentos para firmar
            </b-button>
            <b-button
              type="is-success"
              icon-pack="fas"
              icon-left="file-archive"
              :disabled="!consentGiven || autenticatorQr.length == 0"
              @click.prevent="verifyCode(true)"
            >
              Firmar y descargar documentos comprimidos
            </b-button>
            <!--
            <b-button
                type="is-success"
                icon-pack="fas"
                icon-left="file-signature"
                :disabled="!consentGiven || autenticatorQr.length==0"
                @click.prevent="verifyCode(false)"
            >
              Firmar y descargar documentos individualmente
            </b-button>
            -->
          </div>
        </b-step-item>
        <template #navigation="{ previous, next }">
          <hr />
          <div class="right-button has-text-right is-grouped">
            <b-tooltip
              v-if="isButtonDisabled"
              :label="
                activeStep === 0
                  ? 'Debes subir al menos un archivo PDF, una firma y seleccionar una ubicación de firma para continuar'
                  : 'Debes subir al menos un archivo PDF, una firma y seleccionar una ubicacion de firma para continuar'
              "
              position="is-left"
              type="is-danger"
              size="is-small"
              multilined
            >
              <b-button
                outlined
                icon-pack="fas"
                icon-right="chevron-right"
                class=" button left-button is-primary"
                :disabled="next.disabled || isButtonDisabled"
                @click.prevent="next.action"
              >
                {{ navigationFowardText() }}
              </b-button>
            </b-tooltip>

            <b-button
              v-else-if="!next.disabled"
              outlined
              icon-pack="fas"
              icon-right="chevron-right"
              class="is-primary  button left-button"
              :disabled="next.disabled || isButtonDisabled"
              @click.prevent="next.action"
            >
              {{ navigationFowardText() }}
            </b-button>
          </div>
          <div class="right-button">
            <b-button
              outlined
              v-if="activeStep!=0"
              icon-pack="fas"
              icon-left="chevron-left"
              class="button right-button  is-primary"
              :disabled="previous.disabled"
              @click.prevent="previous.action"
            >
              {{ navigationBackText() }}
            </b-button>
          </div>
        </template>
      </b-steps>
    </div>
    <b-modal  has-modal-card 
              v-model="showmodalQR"
              trap-focus
              :destroy-on-hide="true"
              aria-role="dialog"
              aria-modal
              :can-cancel="true"
              width="100%"
    >
      <template #default="props">
         <div class="modal-card">
          <header class="modal-card-head">            
            <p class="title">Código QR</p>
          </header>
          <section class="modal-card-body">
            <div class="columns">
              <div class="column is-8 is-offset-2">
                <b-message
                title="Aplicación de autenticación"
                type="is-warning"
                aria-close-label="Close message"
                has-icon
                :closable="false"
              >
                <p>
                  - Utilice la app de autenticación 'Google Authenticator' para escanear el siguiente código QR.<br>
                  - Los códigos generados por esta aplicación serán solicitados <b> cada vez </b> que requiera firmar documentación masivamente.<br>
                  - El código QR que se muestra a continuación solo se mostrara una vez.<br>
                  - Una vez escaneado, cerrar esta ventana e ingresar el código generado en la aplicación.
                </p>
              </b-message>
              </div>
            </div>
            <br>
            <div class="columns has-text-centered is-centered">
              <div class="column is-centered">

                <b-image
                    :src="qr_link"
                    alt="codigo qr"
                    style="max-width: 300px; justify-content: center; margin: auto;"                    
                ></b-image>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot is-flex is-justify-content-space-between">
            <b-button @click="closeModal">Cerrar</b-button>
          </footer>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { getDocument } from "pdfjs-dist/es5/build/pdf.js";
import { createWorker, createScheduler } from "tesseract.js";
import { PDFDocument } from "pdf-lib";
import pdf from "vue-pdf";
import Vue from 'vue';
import axios from "axios";
import { saveAs } from "file-saver";
const JSZip = require("jszip");

export default {
  name: "MassiveMultipleSimpleDigitalSignatureView",
  components: {
    pdf,
  },
  props: ['user','qr_link','show_qr', 'cargoActivo'],
  data() {
    return {
      activeStep: 0,
      pdfFiles: [],
      pdfCorrectFiles: [],
      pdfBinary: [],
      pdfSource: [],
      currentPage: [],
      pageCount: [],
      angle: [],
      zoom: [],
      zoomOutDisabled: [],
      zoomInDisabled: [],
      width: [],

      pdfSourceUnsigned: [],
      currentPageUnsigned: [],
      pageCountUnsigned: [],
      angleUnsigned: [],
      zoomUnsigned: [],
      zoomOutDisabledUnsigned: [],
      zoomInDisabledUnsigned: [],
      widthUnsigned: [],
      isOpenGraficoUnsigned: [],


      signatureFile: null,
      signatureFileUrl: null,
      signatureImageHeight: 60,
      signatureImages: [],
      signaturePositions: [],
      potencialSignatures: [],
      OCROffset: 200,
      imageSelected: [],
      consentGiven: false,
      isLoading: false,
      customLoadingMessage: null,
      customLoadingPercentage: 0,
      isOpenGrafico: [],
      cargo: "",
      showmodalQR: false,
      autenticatorQr: "",
      checkboxGroup: [],
      moveRight: 30,
      moveUp: 180,
      nomFiles: "",
      limitFiles: 50,
      insertSignatura: true,
      pdfFileCount: 0,
      documentTypes: ["Mismo tipo de Documentos", "Diferentes tipo de Documento"],
      documentType: "Mismo tipo de Documentos",
    };
  },
  computed: {
    step1Complete() {
      if (this.imageSelected.length == 0) return false
      return this.imageSelected.every(item => !item.includes(-1) && item.length>0);
    },
    step2Complete() {
      if (this.imageSelected.length == 0) return false
      return this.imageSelected.every(item => !item.includes(-1) && item.length>0);
    },
    isButtonDisabled() {
      if (this.activeStep === 0) {
        return !this.step1Complete;
      } else if (this.activeStep === 1) {
        return !this.step2Complete;
      }
      return false;
    },
  },
  watch: {
    step1Complete(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.showSuccessNotification(this.activeStep);
        }, 1000);
      }
    },    
    pdfFiles(newValue) {
      this.pdfFileCount = newValue.length;
    },
    signatureFile(newValue) {
      if (newValue) {
        this.customLoadingMessage = null;
        this.consentGiven = false;
      }
    },
    imageSelected(newValue) {
      this.consentGiven = false;
      this.customLoadingMessage = null;
    },
    signatureImageHeight(newValue) {
      if (newValue) {
        this.customLoadingMessage = null;
        this.consentGiven = false;
      }
    },
  },
  created() {
    window.addEventListener("keydown", this.handleKeydown, null);
  },
  errorCaptured(){
    return false;
  },
  methods: {
    showSuccessNotification(step) {
      return
    },
    openQr(){
      this.showmodalQR = true;
    },
    closeModal(){
      this.showmodalQR = false;
    },
    async updateUnsignedFiles(){
      this.isLoading = true;
      this.pdfSourceUnsigned = [];
      this.currentPageUnsigned = [];
      this.pageCountUnsigned = [];
      this.angleUnsigned = [];
      this.zoomUnsigned = [];
      this.zoomOutDisabledUnsigned = [];
      this.zoomInDisabledUnsigned = [];
      this.widthUnsigned = [];
      this.isOpenGraficoUnsigned = [];
      if (this.pdfFiles.length === 0) {
        this.isLoading = false;
        return
      }
      let count = 0;
      for(const file of this.pdfFiles){
        const buffer = await file.arrayBuffer();
        //Vue.set(this.pdfSourceUnsigned, count, [new Uint8Array(buffer)].at(-1));   
        this.pdfSourceUnsigned.push([new Uint8Array(buffer)].at(-1));     
        this.currentPageUnsigned.push(1);
        this.isOpenGraficoUnsigned.push(false)
        const documentLoad = await PDFDocument.load(buffer);
        Vue.set(this.pageCountUnsigned, count, documentLoad.getPageCount());
        Vue.set(this.angleUnsigned, count, 0);
        Vue.set(this.zoomUnsigned, count, 70);
        Vue.set(this.zoomOutDisabledUnsigned, count, false);
        Vue.set(this.zoomInDisabledUnsigned, count, false);
        Vue.set(this.widthUnsigned, count, 1100);
        count += 1;
      }
      this.isLoading = false;


    },
    async onAddPdfFile(files) {
      if (files.length === 0 || files.length>this.limitFiles) return;

      if (this.checkboxGroup.length < 1 ){
        this.$buefy.dialog.alert({
          message: 'Debe seleccionar al menos un texto a buscar para firmar.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        return;
      }
      this.signatureImages = [];
      this.signaturePositions = [];
      this.potencialSignatures = [];
      this.isOpenGrafico = [];
      this.currentPage = [];
      this.pdfCorrectFiles = [];
      this.pdfBinary = [];
      this.pdfSource = [];
      this.isLoading = true;
      this.insertSignatura = true;
      await this.getPotencialSignatures();
      //se eliminan los indices con documentos que no poseen firmas o fallaron por algun motivo
      this.signatureImages = this.signatureImages.filter((_, index) => this.pdfCorrectFiles[index] !== -1);
      this.potencialSignatures = this.potencialSignatures.filter((_, index) => this.pdfCorrectFiles[index] !== -1);
      this.imageSelected = this.imageSelected.filter((_, index) => this.pdfCorrectFiles[index] !== -1);
      this.pdfCorrectFiles = this.pdfCorrectFiles.filter(item => item !== -1);
      let count = 0;
      for(const file of this.pdfCorrectFiles){
        const buffer = await file.arrayBuffer();
        this.pdfBinary.push(buffer);
        this.currentPage.push(1);
        this.isOpenGrafico.push(false)
        const documentLoad = await PDFDocument.load(buffer);
        Vue.set(this.pageCount, count, documentLoad.getPageCount());
        Vue.set(this.angle, count, 0);
        Vue.set(this.zoom, count, 85);
        Vue.set(this.zoomOutDisabled, count, false);
        Vue.set(this.zoomInDisabled, count, false);
        Vue.set(this.width, count, 1100);
        this.pdfSource.push([new Uint8Array(buffer)].at(-1));
        if(count == 0){
          this.nomFiles = file.name;
        }
        else{
          this.nomFiles = this.nomFiles + ", "+ file.name;
        }
        count = count + 1;
      }
      this.customLoadingMessage = "Documentos procesados.";
      this.isLoading = false;
    },

    async onAddSignatureImage(File) {
      if (File === null) return;
      this.isLoading = true;
      const buffer = await this.signatureFile.arrayBuffer();
      this.signatureFileSource = new Uint8Array(buffer);
      this.signatureFileUrl = URL.createObjectURL(this.signatureFile);
      this.$buefy.toast.open({
        message: `Firma: ${this.shortTextFile(
          this.signatureFile.name,
          24
        )} cargada correctamente`,
        type: "is-success",
        position: "is-bottom",
        duration: 3000,
      });
      this.isLoading = false;
    },

    shortTextFile(text, maxLength) {
      if (text.length <= maxLength) return text;
      return text.slice(0, maxLength) + "...";
    },

    backPage(index) {
      if (this.pageCount[index] === null) return;
      if (this.currentPage[index] - 1 < 1) return;
      Vue.set(this.currentPage, index, this.currentPage[index] - 1)
    },
    nextPage(index) {
      if (this.pageCount[index] === null) return;
      if (this.currentPage[index] + 1 > this.pageCount[index]) return;
      Vue.set(this.currentPage, index, this.currentPage[index] + 1)
    },

    rotatePdf(rotationType, index ) {
      this.$refs[`pdfViewer-${index}`][0].$el.style.pointerEvents = "none";
      if (this.width[index] === 1100) {
        Vue.set(this.width, index, 1200);
      } else {
        Vue.set(this.width, index, 1100);
      }
      if (rotationType === "left") {
        Vue.set(this.angle, index, this.angle[index] - 90);
      } else if (rotationType === "right") {
        Vue.set(this.angle, index, this.angle[index] + 90);
      }
      if (this.angle[index] > 360) {
        Vue.set(this.angle, index, this.angle[index] - 360);
      }
      if (this.angle[index] < 0) {
        Vue.set(this.angle, index, this.angle[index] + 360);
      }
      const pdfViewerRef = this.$refs[`pdfViewer-${index}`][0];
      if (pdfViewerRef) {
        const pdfRender = pdfViewerRef.pdfRender;
        if (pdfRender && typeof pdfRender.cancel === "function") {
          pdfRender.cancel();
        } 
      } 
    },
    zoomIn(index) {
      if (this.zoom[index] < 100) {
        Vue.set(this.zoom, index, this.zoom[index] + 5);
        if (this.zoomOutDisabled[index]) {
          Vue.set(this.zoomOutDisabled, index, false);
        }
      } else {
        Vue.set(this.zoomInDisabled, index, true);
      }
    },
    zoomOut(index) {
      if (this.zoom[index] > 30) {
        Vue.set(this.zoom, index, this.zoom[index] - 5);
        if (this.zoomInDisabled[index]) {
          Vue.set(this.zoomInDisabled, index, false);
        }
      } else {
        Vue.set(this.zoomOutDisabled, index, true);
      }
    },



    backPageUnsigned(index) {
      if (this.pageCountUnsigned[index] === null) return;
      if (this.currentPageUnsigned[index] - 1 < 1) return;
      Vue.set(this.currentPageUnsigned, index, this.currentPageUnsigned[index] - 1)
    },
    nextPageUnsigned(index) {
      if (this.pageCountUnsigned[index] === null) return;
      if (this.currentPageUnsigned[index] + 1 > this.pageCountUnsigned[index]) return;
      Vue.set(this.currentPageUnsigned, index, this.currentPageUnsigned[index] + 1)
    },

    rotatePdfUnsigned(rotationType, index ) {
      this.$refs[`pdfViewerUnsigned-${index}`][0].$el.style.pointerEvents = "none";
      if (this.widthUnsigned[index] === 1100) {
        Vue.set(this.widthUnsigned, index, 1200);
      } else {
        Vue.set(this.widthUnsigned, index, 1100);
      }
      if (rotationType === "left") {
        Vue.set(this.angleUnsigned, index, this.angleUnsigned[index] - 90);
      } else if (rotationType === "right") {
        Vue.set(this.angleUnsigned, index, this.angleUnsigned[index] + 90);
      }
      if (this.angleUnsigned[index] > 360) {
        Vue.set(this.angleUnsigned, index, this.angleUnsigned[index] - 360);
      }
      if (this.angleUnsigned[index] < 0) {
        Vue.set(this.angleUnsigned, index, this.angleUnsigned[index] + 360);
      }
      const pdfViewerRef = this.$refs[`pdfViewerUnsigned-${index}`][0];
      if (pdfViewerRef) {
        const pdfRender = pdfViewerRef.pdfRender;
        if (pdfRender && typeof pdfRender.cancel === "function") {
          pdfRender.cancel();
        } 
      } 
    },
    zoomInUnsigned(index) {
      if (this.zoomUnsigned[index] < 100) {
        Vue.set(this.zoomUnsigned, index, this.zoomUnsigned[index] + 5);
        if (this.zoomOutDisabledUnsigned[index]) {
          Vue.set(this.zoomOutDisabledUnsigned, index, false);
        }
      } else {
        Vue.set(this.zoomInDisabledUnsigned, index, true);
      }
    },
    zoomOutUnsigned(index) {
      if (this.zoomUnsigned[index] > 30) {
        Vue.set(this.zoomUnsigned, index, this.zoomUnsigned[index] - 5);
        if (this.zoomInDisabledUnsigned[index]) {
          Vue.set(this.zoomInDisabledUnsigned, index, false);
        }
      } else {
        Vue.set(this.zoomOutDisabledUnsigned, index, true);
      }
    },

    scaleUp() {
      if (this.signatureImageHeight + 10 > 100) return;
      this.signatureImageHeight += 10;
    },
    scaleDown() {
      if (this.signatureImageHeight - 10 < 50) return;
      this.signatureImageHeight -= 10;
    },

    navigationFowardText() {
      if (this.activeStep === 0) return "Continuar con el Consentimiento y Descarga";
    },

    navigationBackText() {
      if (this.activeStep === 1) return "Volver ";
      if (this.activeStep === 2) return "Volver a la Ubicación de Firma";
    },

    async getPotencialSignatures() {
      if (this.pdfFiles.length == 0) return;
      this.isLoading = true;
      const countFiles = this.pdfFiles.length;
      let num_workers = 5;
      if (this.pdfFiles.length < 5){
        num_workers = this.pdfFiles.length;
      }
      const workers = await Promise.all(
            Array.from({ length: num_workers }, () => createWorker("spa"))
        );
      this.signatureImages = Array(countFiles).fill([]);
      this.potencialSignatures = Array(countFiles).fill([]);
      this.pdfCorrectFiles = Array(countFiles).fill(-1);
      this.imageSelected = Array(countFiles).fill([-1]);
      this.customLoadingMessage = "Procesando documentos";
      this.customLoadingPercentage = 1;
      try {
        const concurrencyLimit = 5; // Número máximo de procesos paralelos
        let completedProcesses = 0;
        let failedProcesses = 0;

        const updateProgress = () => {
          this.customLoadingPercentage = Math.round((completedProcesses / countFiles) * 100);
          this.customLoadingMessage = `Documentos procesados ${completedProcesses} de ${countFiles}`;
        };

        const processDocument = async (file, index, worker) => {
          let signatureImagesAux = [];
          try {
            const pdfBuffer = await file.arrayBuffer();
            const pdfDoc = await this.loadPdfDocument(pdfBuffer);
            //Busqueda de ubicacion de palabra clave hoja por hoja
            for (let pageNum = 1; pageNum <= pdfDoc.numPages; pageNum++) {
              const { canvas, viewport } = await this.renderPageWithCanvas({
                pdfDoc,
                pageNumber: pageNum,
                scale: 3,
              });

              const textMatches = await this.findSignatureTextMatches(worker, canvas);
              //Se encuentra coincidencian en la hoja num pageNum
              if (textMatches.length > 0) {
                const mappElement = textMatches.map((bbox) => ({
                  pageNum,
                  bbox,
                }));

                const auxConcat = this.potencialSignatures[index].concat(mappElement);
                Vue.set(this.potencialSignatures, index, auxConcat);

                const posSignature = this.getSignatureImages(textMatches, index, {
                  canvas,
                  width: viewport.width / 2,
                  height: 300,
                });
                signatureImagesAux = signatureImagesAux.concat(posSignature);
              }
              //se encuentra en la ultima hoja y el documento tiene coincidencias
              if (pageNum === pdfDoc.numPages && signatureImagesAux.length > 0) {
                Vue.set(this.signatureImages, index, signatureImagesAux);
                Vue.set(this.pdfCorrectFiles, index, file);
              }
            }
          } catch (error) {
            failedProcesses++;
          } finally {
            completedProcesses++;
            updateProgress();
          }
        };

        const processDocumentsInBatches = async (batch) => {
          await Promise.allSettled(
              batch.map(({ file, index }, idx) => {
                const worker = workers[idx % workers.length]; // Asigna worker de la lista circularmente
                return processDocument(file, index, worker);
              })
          );
          //await Promise.allSettled(batch.map(({ file, index }) => processDocument(file, index)));
        };

        // Dividir la cola en lotes y procesarlos
        let queue = this.pdfFiles.map((file, index) => ({ file, index }));
        while (queue.length > 0) {
          const batch = queue.splice(0, concurrencyLimit);
          await processDocumentsInBatches(batch);
        }

        this.customLoadingPercentage = 100;
        this.$buefy.toast.open({
          message: `Documentos cargados correctamente: ${this.pdfCorrectFiles.filter(f => f !== -1).length} <br> Documentos sin firma: ${countFiles - this.pdfCorrectFiles.filter(f => f !== -1).length}`,
          type: "is-success",
          position: "is-bottom",
          duration: 3000,
        });

      } catch (error) {
        console.log("Error procesando PDF:", error);
      }
    },

    deselectImageStepTwo(index_doc,index){
      let lista = this.imageSelected[index_doc].filter(item => item !== index);
      Vue.set(this.imageSelected, index_doc, lista);
    },
    selectImageStepTwo(index_doc,index){
      if (this.insertSignatura == true && this.documentType == "Mismo tipo de Documentos"){
        let lista = this.imageSelected[index_doc];
        lista.push(index);
        lista = lista.filter(item => item !== -1);
        Vue.set(this.imageSelected, index_doc, lista)
        this.automaticSignature(index_doc, index);
        this.insertSignatura = false;
      }
      else{
        let lista = this.imageSelected[index_doc];
        lista.push(index);
        lista = lista.filter(item => item !== -1);
        Vue.set(this.imageSelected, index_doc, lista)
      }
      this.isOpenGrafico[index_doc] = false;

    },
    automaticSignature(index_doc,index){
      const elementoBase = this.potencialSignatures[index_doc][index].bbox;
      const x0 = elementoBase.x0;
      let x0Aux = 0;
      for (let i = 0; i < this.potencialSignatures.length; i++) {
        if (i === index_doc) {
          continue;
        }

        this.potencialSignatures[i].forEach((elemento,indice) => {
          x0Aux = elemento.bbox.x0
          if ( x0-50<=x0Aux && x0+50 >= x0Aux){
            let lista = this.imageSelected[i];
            lista.push(indice);
            lista = lista.filter(item => item !== -1);
            Vue.set(this.imageSelected, i, lista)
          }
        });
      }
    },
    async renderPageWithCanvas({ pdfDoc, pageNumber, scale }) {
      const page = await pdfDoc.getPage(pageNumber);
      const viewport = page.getViewport({ scale });
      const canvas = document.createElement("canvas");
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      const ctx = canvas.getContext("2d", { willReadFrequently: true });
      await page.render({
        canvasContext: ctx,
        viewport: viewport,
      }).promise;
      return { canvas, viewport };
    },

    getSignatureImages(textMatches, index,{ canvas, width, height }) {
      const signatureImages = [];
      textMatches.forEach((bbox) => {
        const signatureImage = this.cropImageFromCanvas({
          canvas,
          x: bbox.x0 - this.OCROffset,
          y: bbox.y0 - this.OCROffset,
          width,
          height,
        });
        signatureImages.push(signatureImage);
      });
      return signatureImages;
    },

    cropImageFromCanvas({ canvas, x, y, width, height }) {
      const ctx = canvas.getContext("2d", { willReadFrequently: true });
      const imageData = ctx.getImageData(x, y, width, height);
      const croppedCanvas = document.createElement("canvas");
      croppedCanvas.width = width;
      croppedCanvas.height = height;
      const croppedCtx = croppedCanvas.getContext("2d", {
        willReadFrequently: true,
      });
      croppedCtx.putImageData(imageData, 0, 0);
      return croppedCanvas.toDataURL();
    },

    async loadPdfDocument(pdfBuffer) {
      return await getDocument({ data: pdfBuffer }).promise;
    },

    async findSignatureTextMatches(worker, canvas) {
      var signaturesTextLower = this.checkboxGroup;
      //const signatureTextLower = this.user.name.toLowerCase(); 
      const firstPageResult = await worker.recognize(canvas.toDataURL());
      return firstPageResult.data.lines
        .filter((line) => signaturesTextLower.some( signatureTextLower => line.text.toLowerCase().includes(signatureTextLower.toLowerCase())))
        .flatMap((line) => line.words)
        .filter((word) => signaturesTextLower.some( signatureTextLower => word.text.toLowerCase().includes(signatureTextLower.toLowerCase())))
        .map((word) => word.bbox);
    },

    async verifyCode(format_zip){
      let vm = this;
      if (vm.autenticatorQr.length < 6){
        this.$buefy.dialog.alert({
              message: 'Código de verificación no valido',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
        return false
      }
      vm.isLoading = true;
      await axios
          .post("/verify-code-autenticator.json", 
            {"code": vm.autenticatorQr, 
            "amount": vm.pdfCorrectFiles.length,
            "name_files": vm.nomFiles
            })
          .then(async (res) => {
            if(res.data == true){
              await this.insertSignaturesInPDF(format_zip);
            }
            else{
              this.$buefy.dialog.alert({
                message: 'Código de verificación no válido o expirado',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
            }
            vm.errors_validations = null;
          })
          .catch((error) => {
            this.$buefy.dialog.alert({
              message: 'Hubo un error en su solicitud',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          })
          .finally(() => {
            vm.isLoading = false;
          });

    },
    reloadDocument(){
      this.pdfFiles = [];
      this.signatureImages = [];
      this.signaturePositions = [];
      this.potencialSignatures = [];
      this.isOpenGrafico = [];
      this.currentPage = [];
      this.pdfCorrectFiles = [];
      this.imageSelected = [];
      this.pdfBinary = [];
      this.pdfSource = [];
      this.autenticatorQr = "";
      this.activeStep = 0;
    },
    async insertSignaturesInPDF(format_zip) {
      if (this.pdfBinary.length == 0) return;
      this.isLoading = true;
      var x = 0;
      var y = 0;
      const zip = new JSZip();
      var index = 0;
      for(const file of this.pdfCorrectFiles){
        this.signaturePositions = [];
        const pdfDoc = await PDFDocument.load(this.pdfBinary[index]);

        for (const position of this.imageSelected[index]){
          this.signaturePositions.push(this.potencialSignatures[index][position]);
        }
        //this.updateSignature(index);
        const desiredHeight = this.signatureImageHeight;
        const scaleFactor = 3; // El mismo factor de escala usado en el OCR
        const signatureImage = await pdfDoc.embedPng(this.signatureFileSource);

        // Mantiene la relación de aspecto de la imagen
        const aspectRatio = signatureImage.height / signatureImage.width;
        const width = desiredHeight / aspectRatio;
        const height = desiredHeight;

        for (const position of this.signaturePositions) {
          const page = pdfDoc.getPages()[position.pageNum - 1];
          const bbox = position.bbox;

          // Ajuste para coincidir con la posición vertical de la firma en el OCR.
          const ocrVerticalOffset = this.OCROffset;

          // Ajuste para centrar la firma horizonalmente.
          // (En el valor 0 la imagen se posiciona en el inicio de la palabra firma)
          const horizonalPositionFix = desiredHeight+this.moveRight; // Desplazamiento de la imagen hacia la derecha.

          // Ajuste para centrar la firma verticalmente
          // (Esto varia dependiendo del texto que se encuentre sobre la palabra Firma).
          const verticalPositionFix = this.moveUp; // Desplazamiento de la imagen hacia arriba.

          const x =
            (bbox.x0 +
              horizonalPositionFix) /
              scaleFactor -
            width / 2;
          const y =
            page.getHeight() -
            (bbox.y0 -
              verticalPositionFix) /
              scaleFactor -
            height;
          page.drawImage(signatureImage, { x, y, width, height });


        }

        const modifiedPdfBytes = await pdfDoc.save();
        if(format_zip) {
          const blob = new Blob([modifiedPdfBytes], {type: "application/pdf"});
          await zip.file(`${this.pdfCorrectFiles[index].name.slice(0, -4)}_FIRMADO.pdf`, blob);
        }
        else {
          this.downloadModifiedPDF(modifiedPdfBytes, index);
        }
        index += 1;
      }
      if(format_zip) {
        await zip.generateAsync({type: "blob"})
          .then((blob) => {
            // Guardar el archivo ZIP en el sistema de archivos
            saveAs(blob, "Documentos-firmados.zip");
          })
          .catch((error) => {
            console.error("Error al generar el archivo ZIP:", error);
          });
      }
    },
    downloadModifiedPDF(pdfBytes, index) {
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      // Crear un enlace para descargar
      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.setAttribute('download',`${this.pdfCorrectFiles[index].name.slice(0, -4)}_FIRMADO.pdf`);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(downloadLink);
    },
    deleteDropFile(index) {
      this.pdfFiles.splice(index, 1)
    },
    dropFileColapseUnsigned(index){
      this.pdfFiles.splice(index, 1)
    },
    dropFileColapse(index){
      this.pdfFiles.splice(index, 1)
      this.pdfCorrectFiles.splice(index, 1)
      this.pdfBinary.splice(index, 1)
      this.pdfSource.splice(index, 1)
      this.currentPage.splice(index, 1)
      this.isOpenGrafico.splice(index, 1)
      this.pageCount.splice(index, 1)
      this.angle.splice(index, 1)
      this.zoom.splice(index, 1)
      this.zoomOutDisabled.splice(index, 1)
      this.zoomInDisabled.splice(index, 1)
      this.width.splice(index, 1)
    }
  },
};
</script>
<style scoped>
  .pdf-container {
    border: 1px solid #dbdbdb;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    background-color: #f5f5f5;
    padding: 16px;
    overflow-y: auto;
  }
  .signature-container-signature-off{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
    border: 1px solid #dbdbdb;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #f5f5f5;
    padding: 16px;
    width: 100%;
    height: 80%;
  }
  .signature-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
    border: 1px solid #dbdbdb;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #f5f5f5;
    padding: 16px;
    width: 100%;
  }

  .signature-preview {
    max-height: 300px;
    max-width: 100%;
    width: 100%;
    border: white 4px solid;
    background-color: white;
    object-fit: contain;
  }
  .image-container {
    position: relative;
    display: inline-block;
  }
  .image-index {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 50%;
    font-weight: bold;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
  }
  .image-not-select{
    padding: 1rem;
    border: 1px solid #dbdbdb;
    cursor: pointer;
    borderRadius: 8px;
  }

  .image-select{
    padding: 1rem;
    border: 2px solid #EE8600;
    cursor: pointer;
    borderRadius: 8px;
  }

  .custom-collapse {
    background-color: #F9F9F9 !important;
  }
  .custom-success-collapse {
    background-color: #48C78E !important;
    color: white;
  }
  .custom-none-find-collapse {
    background-color: #BCBCBC !important;
    color: white;
  }
  .custom-incomplete-collapse {
    background-color: #ee8600 !important;
    color: white;
  }
  .custom-header-title {
    font-size: 20px; 
    font-weight: bold; 
    text-align: center !important; 
    margin: 0; 
    color: white;
  }
  .custom-header-title-subplots {
    font-size: 21px;
    font-weight: bold; 
    text-align: left !important;
    margin: 0; 
    color: white;
  }
  .right-button{
    margin-left: auto;
  }
  .left-button{
    margin-right: auto;
  }
</style>
