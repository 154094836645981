<template>
    <div class="container is-fullheight" style="padding: 2rem;background-color: #F2F2F2;">
        <div class="section" style="background-color: white; height: 100%">
            <!-- Titulo de la sección -->
            <div class="is-flex is-justify-content-flex-start is-align-items-center">
                <div class="is-flex is-flex-wrap-nowrap">
                    <h1 class="subtitle is-1 has-text-secondary mb-0 mr-5">
                        <b>Tipo de Solicitud: </b>{{ requestType.real_name }}
                    </h1>
                </div>
            </div>
            <!-- Separador -->
            <hr class="solid">
            <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
                <b-message type="is-danger" has-icon>
                    <div v-for="(item,index) in errores_validacion" :key="index">
                        {{ item[0] }}
                    </div>
                </b-message>
            </div>
<div class="card">
  <div class="card-content">
            <div class="columns is-multiline my-2 px-5">
                <div class="column is-half">
                    <b>Fecha de Creación:</b> {{ formatDate(requestType.created_at) }}
                </div>
                <div class="column is-half">
                    <b>Descripción pequeña:</b>
                  <span v-if="requestType.description"
                        style="white-space: pre-line">{{ requestType.description }}</span>
                  <span v-else style="white-space: pre-line; color: darkgrey">Esta solicitud no tiene descripción.</span>
                </div>
                <div class="column is-half">
                    <b>Origen de la solicitud:</b>
                  <span v-if="origins.origin" style="white-space: pre-line">{{
                      origins.origin.real_name + " (" + origins.origin.name + ")"
                    }}</span>
                  <span v-else style="white-space: pre-line; color: darkgrey">No se ha fijado un origen para esta solicitud</span>
                </div>
                <div class="column is-half">
                    <b>Tipo de estudiante:</b>
                  <span v-if="origins.role_type " style="white-space: pre-line">{{
                      origins.role_type.name
                    }}</span>
                  <span v-else style="white-space: pre-line; color: darkgrey">Esta solicitud no requiere un tipo de estudiante</span>
                </div>
                <div class="column is-half">
                    <b>Estado del estudiante:</b>
                  <span v-if="origins.role_state "
                        style="white-space: pre-line">{{ origins.role_state.name }}</span>
                  <span v-else style="white-space: pre-line; color: darkgrey">Esta solicitud no requiere un estado específico</span>
                </div>
                <div class="column is-half">
                  <b>¿Es almacenado en Google Drive?:</b>
                  <b-tooltip v-if="requestType.save_response_in_drive" type="is-info" label="Los documentos adjuntados en la respuesta final serán almacenados en Google Drive.">
                    <b-icon type="is-success" icon="check-circle"></b-icon>
                  </b-tooltip>
                  <b-tooltip v-else type="is-info" label="Los documentos adjuntados en la respuesta final no serán almacenados en Google Drive.">
                    <b-icon type="is-warning" icon="times-circle"></b-icon>
                  </b-tooltip>
                </div>
                <div class="column is-half">
                  <b>¿Crea código de verificación?:</b>
                  <b-tooltip v-if="requestType.needs_token" type="is-info" label="Los documentos adjuntados en la respuesta final serán generados con un código de verificación.">
                    <b-icon type="is-success" icon="check-circle"></b-icon>
                  </b-tooltip>
                  <b-tooltip v-else type="is-info" label="Los documentos adjuntados en la respuesta final no serán generados con un código de verificación.">
                    <b-icon type="is-warning" icon="times-circle"></b-icon>
                  </b-tooltip>
                </div>
                <div class="column is-full">
                    <b>Descripción larga (bienvenida):</b>
                  <span v-if="requestType.long_description"
                        style="white-space: pre-line">{{ requestType.long_description }}</span>
                  <span v-else style="white-space: pre-line; color: darkgrey">Esta solicitud no tiene bienvenida.</span>
                </div>
            </div>
  </div>
</div>
            <div v-if="documentTemplates.length > 0" class="columns is-multiline my-2 px-5">
                <div class="column is-full">
                    <h1 class="subtitle is-2 has-text-secondary">
                        Plantillas
                    </h1>
                </div>
            </div>
            <div v-for="(template, template_index) in documentTemplates" :key="template_index">
                <div class="card is-flex is-align-items-center is-justify-content-space-between is-flex-wrap-wrap my-1">
                    <div class="is-flex card-content">
                        {{ template.name }}
                    </div>
                    <div class="is-flex m-2">
                        <button class="button is-primary mr-2 is-small is-inverted"
                                @click="openModalShowDocument(template)">
                            Ver Documento
                            <i class="fas fa-eye ml-1"></i>
                        </button>
                        <button class="button is-secondary mr-2 is-small is-inverted"
                                @click="downloadFile(template.id_document_drive)">
                            Descargar
                            <i class="fas fa-download ml-1"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="columns is-multiline my-2 px-5">
                <div class="column is-full">
                    <h1 class="subtitle is-2 has-text-secondary">
                        Pasos
                    </h1>
                </div>
            </div>

            <div class=" my-2" v-for="(state, state_index) in states" :key="state_index">
                <b-collapse class="card" animation="slide" aria-id="contentIdForA11y3">
                    <template #trigger="props">
                        <div class="card-header" :class="state_index === 0 ? 'has-background-secondary' : ''"
                             role="button" aria-controls="contentIdForA11y3">
                            <p class="card-header-title" :class="state_index === 0 ? 'has-text-white' : ''">
                                {{ state.real_name }}
                                <a v-if="false" @click="editState(state_index)"><i class="fas fa-pencil-alt mx-3"
                                                                                   :class="state_index === 0 ? 'has-text-white' : ''"></i>
                                </a>
                                <a v-if="false">
                                <span class="icon has-text-danger ">
                                    <i class="fas fa-trash-alt is-danger mx-3">
                                    </i>
                                </span>
                                </a>
                            </p>
                            <a class="card-header-icon" :class="state_index === 0 ? 'has-text-white' : ''">
                                <b-icon icon-pack="fas" :icon="props.open ? 'caret-down' : 'caret-up'">
                                </b-icon>
                            </a>
                        </div>
                    </template>

                    <div class="card-content">
                        <div class="content">
                            {{ state.information }}
                            <br>
                            <br>
                            <div class="is-flex mb-1">
                                <p style="color:gray;font-size:14px">Duración:
                                    {{ state.duration === 0 ? 'No tiene' : state.duration + ' días' }}</p>
                            </div>
                            <div class="is-flex is-flex-wrap-wrap">
                                <p class="my-auto" style="color:gray;font-size:14px">Revisores: </p>
                                <b-tag class="m-1" v-for="(ip, index) in state.internal_positions" :key="index"
                                       type="is-primary" :closable="false" aria-close-label="Close tag"
                                       @close="() => {state.internal_positions.splice(index, 1)}">
                                    {{ ip.name }}
                                </b-tag>
                            </div>
                            <div class="is-flex is-flex-wrap-wrap">
                                <p class="my-auto" style="color:gray;font-size:14px">Documentos: </p>
                                <b-tag class="is-secondary m-1 " v-for="(ip, index) in state.documents" :key="index"
                                       type="is-primary" :closable="false" aria-close-label="Close tag"
                                       @close="() => {state.documents.splice(index, 1)}">
                                    {{ ip.name }}
                                </b-tag>
                            </div>
                            <div class="is-flex is-flex-wrap-wrap">
                                <p class="my-auto" style="color:gray;font-size:14px">Formularios: </p>
                                <b-tag class="is-info m-1" v-for="(ip, index) in state.google_forms" :key="index"
                                       type="is-primary" :closable="false" aria-close-label="Close tag"
                                       @close="() => {state.forms.splice(index, 1)}">
                                    {{ ip.name }}
                                </b-tag>
                            </div>
                        </div>
                    </div>
                    <footer v-if="false" class="card-footer is-flex is-flex-wrap-wrap">
                        <a class="card-footer-item" @click="addItemsToState(state_index, 'Revisores')">Añadir
                            Revisores</a>
                        <a class="card-footer-item" @click="addItemsToState(state_index, 'Documentos')">Añadir
                            Documentos</a>
                        <a class="card-footer-item" @click="addItemsToState(state_index, 'Formularios')">Añadir
                            Formularios</a>
                    </footer>
                </b-collapse>
            </div>

            <b-modal v-model="isModalShowDocumentActive" has-modal-card trap-focus :destroy-on-hide="true"
                     aria-role="dialog" aria-modal>
                <template #default="props">
                    <modal-show-document modal-header="Ver Documento" :documentId="currentDocumentIdDrive"
                                         @close="props.close">
                    </modal-show-document>
                </template>
            </b-modal>
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import ModalShowDocument from "../Documents/ModalShowDocument";

export default {
    name: "ShowRequestType",
    props: ['requestTypeId'],
    components: {
        ModalShowDocument
    },
    data: () => {
        return {
            isLoading: false,
            requestType: {},
            documentTemplates: [],
            states: [],
            errores_validacion: [],
            origins: [],
            currentDocumentIdDrive: "",
            isModalShowDocumentActive: false
        }
    },
    created() {
      document.title="Ver detalles de un tipo de solicitud"
        this.isLoading = true;
        return axios
            .get("/request_types/show-admin/" + this.requestTypeId + ".json")
            .then(response => {
                this.requestType = response.data.request_type;
                this.documentTemplates = response.data.templates;
                this.origins = response.data.origins;
                this.states = response.data.states;
                this.isLoading = false;
            })
            .catch(e => {
                console.log(e);
                this.isLoading = false;
            });
    },
    methods: {
        openModalShowDocument(document) {
            this.currentDocumentIdDrive = document.id_document_drive;
            this.isModalShowDocumentActive = true;
        },
        downloadFile(document_id) {

            this.isLoading = true;

            axios.request({
                url: '/documents/download_file/' + document_id,
                responseType: 'blob',
            })
                .then(response => {
                        //var blobUrl = URL.createObjectURL(response.data);
                        //window.open(blobUrl)

                        let info = response.headers["content-disposition"]
                        let name = info.split('"')[1]
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', name); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        this.isLoading = false;
                        this.$buefy.dialog.alert({
                            message: 'Se descargo con exito.',
                            type: 'is-success',
                            hasIcon: true,
                            icon: 'check-circle',
                            iconPack: 'fa',
                            ariaRole: 'alertdialog',
                            ariaModal: true
                        })
                    }
                )
                .catch(e => {
                    this.isLoading = false;
                    this.$buefy.dialog.alert({
                        message: 'Fallo la descarga.',
                        type: 'is-danger',
                    });
                })

        },

        formatDate(date) {
            if (date != null && date !== "" && date !== undefined) {
                date = date.substring(0, 10)
                var arreglo = date.split("-")
                return arreglo[2] + "-" + arreglo[1] + "-" + arreglo[0]
            } else {
                return ""
            }
        }

    }
}
</script>
