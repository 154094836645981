<template>
  <div class="custom-container">
    <b-carousel
      icon-size="is-large"
      :interval="6000"
      indicator-style="is-lines"
      :pause-info="false"
    >
      <template v-for="(carousel, i) in carousels">
        <b-carousel-item v-if="carousel.condition && !carousel.isVideo" :key="i">
          <a :href="carousel.link">
            <section
              :class="`hero is-medium is-bold`"
              :style="`background-color: ${carousel.color};`"
            >
              <b-image class="image" :src="carousel.image"></b-image>
            </section>
          </a>
        </b-carousel-item>
        <b-carousel-item v-if="carousel.isVideo" :key="i">
          <div class="has-background-black">
            <div class="columns">
              <div class="column">
                <Youtube
                    class="mt-5"
                    :videoId="carousel.videoId"
                    max-width="40em"
                ></Youtube>
              </div>
            </div>
          </div>
        </b-carousel-item>
      </template>
    </b-carousel>
    <div class="columns is-centered has-text-centered mt-6">
      <div class="column">
        <b-button
          class="is-primary"
          icon-left="search"
          @click="navigateToVerifyCode"
        >
          Verificar Emisión de Documentos con Código de Verificación
        </b-button>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column has-text-centered pt-0">
        <b-button
          class="is-secondary"
          icon-left="info-circle"
          @click="() => (isModalHowToUseOpen = true)"
        >
          ¿Cómo usar el Sistema de Administración de Solicitudes (SAS)?
        </b-button>
      </div>
    </div>
    <b-modal
      v-model="isModalHowToUseOpen"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      width="90%"
    >
      <template #default="props">
        <ModalHowToUse @close="props.close"></ModalHowToUse>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Youtube from "./Tools/Youtube.vue";

export default {
  components: {
    Youtube,
    ModalHowToUse: () => import("./Tutorials/ModalHowToUse.vue"),
  },
  data() {
    return {
      isModalHowToUseOpen: false,
      carousels: [
        {
          isVideo: false,
          image: "shorcuts_images/default.png",
          link: "/#",
          condition: true,
          color: "#EE8600",
        },
        {
          isVideo: false,
          image: "shorcuts_images/reviewer/listado_solicitudes.png",
          link: "/requests",
          condition: this.canListRequests,
          color: "#4F8FDE",
        }, //azul
        {
          isVideo: false,
          image: "shorcuts_images/reviewer/estadisticas_solicitudes.png",
          link: "/request_types/src/statistics",
          condition: this.canViewStatistics,
          color: "#7957D5",
        }, //mo4rado
        {
          isVideo: false,
          image: "shorcuts_images/reviewer/nuevo_tipo_solicitud.png",
          link: "/request_types/new",
          condition: this.canCreateRequestType,
          color: "#009D72",
        }, // verde
        {
          isVideo: true,
          videoId: "wKnLDKv0qOE",
        }, // Video SAS YouTube
      ],
    };
  },
  methods: {
    navigateToVerifyCode() {
      window.location.href = "/public/verify-code";
    },
  },
};
</script>

<style>
@import "../stylesheets/estilos.scss";

.columns.is-vcentered {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.custom-container {
  position: relative; /* needed for footer positioning*/
  height: auto !important; /* real browsers */
  min-height: 100%; /* real browsers */
}

.responsive-media {
  width: 100%;
  max-height: 400px;
}
</style>
