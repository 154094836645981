<template>
  <div style="background-color: white">
    <div class="has-background-src" style="text-align: center">
      <img src="../../../../public/GCC/1. Banner - Inicio.png" />
    </div>

    <div class="columns is-centered mt-0">
      <div class="column is-9">
        <!--solicitudes-->
        <div class="section pt-1" style="background-color: white">
          <!-- Titulo de la sección -->
          <div class="is-flex is-justify-content-center">
            <h1
              class="subtitle is-2 has-text-secondary has-text-weight-bold is-family-secondary"
            >
              ¡BIENVENID@!
            </h1>
          </div>
          <div
            class="has-text-secondary has-text-centered is-family-secondary mb-6"
          >
            <p class="is-size-5">
              <br />
              Les damos la bienvenida al nuevo módulo de solicitudes de
              documentación del Registro Curricular, en el cual estudiantes y
              ex-estudiantes de la Facultad de Ingeniería de la Universidad de
              Santiago de Chile (FING USACH) pueden realizar solicitudes de
              certificados, planes y programas.
            </p>
          </div>

          <!-- Separador -->
          <hr class="solid" />
          <div class="columns is-multiline is-centered">
            <div v-if="canCreateRequest" class="column">
              <a
                class="button is-customgrey is-border-light-grey is-large is-fullwidth"
                :href="new_requests_path"
              >
                <span>
                  <i class="fas fa-file-alt mx-2"></i>
                </span>
                <span> Iniciar una solicitud </span>
              </a>
            </div>
            <div class="column">
              <a
                class="button is-customgrey is-border-light-grey is-large is-fullwidth"
                :href="my_requests_path"
              >
                <span>
                  <i class="fas fa-file-invoice mx-2"> </i>
                </span>
                <span> Mis Solicitudes </span>
              </a>
            </div>
            <!--                        <div class="column is-one-third">-->
            <!--                            <a class="button is-customgrey is-large is-fullwidth" :href="search_requests_path">-->
            <!--                      <span>-->
            <!--                          <i class="fas fa-search mx-2"></i>-->
            <!--                      </span>-->
            <!--                                <span>-->
            <!--                          Buscar Solicitud-->
            <!--                      </span>-->
            <!--                            </a>-->
            <!--                        </div>-->
          </div>
          <div class="columns is-multiline is-centered">
            <div v-if="canCreateRequest" class="column">
              <a
                class="button is-customgrey is-border-light-grey is-large is-fullwidth"
                :href="faq_path"
              >
                <span>
                  <i class="fas fa-question mx-2"></i>
                </span>
                <span> Preguntas frecuentes </span>
              </a>
            </div>
            <div class="column">
              <a
                class="button is-customgrey is-border-light-grey is-large is-fullwidth"
                :href="contact_path"
              >
                <span>
                  <i class="fas fa-address-book mx-2"></i>
                </span>
                <span> Contactos </span>
              </a>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <a
                class="button is-customgrey is-border-light-grey is-large is-fullwidth"
                href="https://www.fresha.com/es/a/registro-curricular-fing-santiago-registro-curricular-ingenieria-usach-estacion-central-jz8uopks/booking"
                target="_blank"
              >
                <span>
                  <i class="fas fa-clock mx-2"></i>
                </span>
                <span> Reserva de hora </span>
              </a>
            </div>
            <div class="column has-text-centered">
              <a
                class="button has-background-src has-text-white is-large is-fullwidth"
                style="border: 2px solid black; border-radius: 10px"
                @click="isModalPaymentInfoOpen = !isModalPaymentInfoOpen"
              >
                <span>
                  <i class="fas fa-shopping-cart mx-2"></i>
                </span>
                <span> Información de Pagos </span>
              </a>
            </div>
          </div>
        </div>

        <!--Carrusel de imagenes-->

        <b-carousel :pause-info="false">
          <b-carousel-item v-for="(item, i) in items" :key="i">
            <b-image class="image" :src="item.src"></b-image>
          </b-carousel-item>
        </b-carousel>
        <!--mapa-->

        <div class="mt-5" align="center">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6658.140749411799!2d-70.682722!3d-33.447473!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c45848597283%3A0xe2156c9c42e38433!2sAv.%20Libertador%20Bernardo%20O&#39;Higgins%203363%2C%20Santiago%2C%20Estaci%C3%B3n%20Central%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1ses!2sus!4v1662082872808!5m2!1ses!2sus"
            width="600"
            height="450"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            eferrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>

    <!-- Footer -->

    <div style="background-color: #003b71" align="center">
      <img src="../../../../public/logo_sin_texto.png" />
      <div>
        <a href="https://www.fing.usach.cl/" target="_blank">
          <h1
            class="is-size-6 has-text-warning has-text-centered is-family-secondary has-text-weight-bold"
          >
            WEB OFICIAL FING USACH | Entra aquí
          </h1>
        </a>
        <br />
      </div>
    </div>
    <div class="has-background-src" align="center">
      <br />
      <h1
        class="is-size-6 has-text-white has-text-centered is-family-secondary"
      >
        Universidad de Santiago de Chile. Avenida Libertador Bernardo O'Higgins
        nº 3363. Estación Central. Santiago. Chile.
      </h1>
      <a href="https://www.usach.cl/contacto" target="_blank">
        <h1
          class="is-size-6 has-text-secondary has-text-centered is-family-secondary has-text-weight-bold"
        >
          Oficina de Informaciones, Reclamos y Sugerencias (OIRS)
        </h1>
      </a>
      <br />
    </div>

    <b-modal
      v-model="isModalPaymentInfoOpen"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      width="90%"
    >
      <template #default="props">
        <PaymentInfoModal @close="props.close"></PaymentInfoModal>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import Youtube from "../Tools/Youtube";
import PaymentProgramInfo from "../RequestType/PaymentProgramInfo.vue";
import PaymentAllInfo from "../RequestType/PaymentAllInfo.vue";

export default {
  name: "GccHome",
  components: {
    PaymentInfoModal: () => import("../RequestType/PaymentInfoModal.vue"),
    Youtube,
    PaymentProgramInfo,
    PaymentAllInfo,
  },
  props: ["canListRequests", "canCreateRequest"],
  data: function () {
    return {
      list_requests_path: "/requests",
      faq_path: "/src/faq",
      contact_path: "/src/contact_us",
      new_requests_path: "/src/request_types",
      search_requests_path: "/requests/search",
      my_requests_path: "/requests/my_requests",
      isModalSelectRequestTypeActive: false,
      requestTypes: ["", "Nombramiento"],
      selectedRequestType: null,
      isModalPaymentInfoOpen: false,
      items: [
        {
          src: require("../../../../public/gsd/home_student_images/carousel_images/Imagen1.png"),
        },
        {
          src: require("../../../../public/gsd/home_student_images/carousel_images/Imagen2.png"),
        },
        {
          src: require("../../../../public/gsd/home_student_images/carousel_images/Imagen3.png"),
        },
        {
          src: require("../../../../public/gsd/home_student_images/carousel_images/Imagen4.png"),
        },
        {
          src: require("../../../../public/gsd/home_student_images/carousel_images/Imagen5.png"),
        },
      ],
    };
  },

  created() {
    document.title = "SRC | Inicio";

    this.getRequestTypes();
  },

  methods: {
    async getRequestTypes() {
      await axios
        .get("/request_types.json")
        .then((response) => {
          this.requestTypes = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.requestTypes = [];
        });
    },

    debug() {},
    listRequests() {
      //This functions is called when button "Listar Solicitudes" is pressed
      //do something
      //call backend
    },
    openModalSelectRequestType() {
      //This functions is called when button "crear Solicitud" is pressed
      //do something
      this.isModalSelectRequestTypeActive = true;
    },
    redirectToNewRequest() {
      //This functions is called when button "Buscar Solicitud" is pressed
      if (this.selectedRequestType == null) {
        // There's no type request selected
        console.log("No se ha seleccionado Tipo de Solicitud");
      } else {
        window.location.href = "/requests/request_types";
      }
    },
  },
};
</script>

<style>
.map-responsive {
}

.map-responsive iframe {
}

.responsive {
  width: 100%;
  height: auto;
}

/* Solid border */
hr.solid {
  border-top: 2px solid #bbb;
  width: 100%;
}
</style>
