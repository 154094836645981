<template>
  <div class="container" style="padding: 1rem; background-color: #f2f2f2">
    <div class="section" style="background-color: white; height: 100%">
      <!-- Titulo de la sección -->
      <div class="is-flex is-justify-content-flex-start">
        <h1 class="subtitle is-1 has-text-secondary">Gestión de Solicitudes</h1>
      </div>

      <!-- Separador -->
      <hr class="solid" />

      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(applyFilters)">
          <div class="columns is-vcentered px-5">
            <div class="column is-4">
              <b-field label="Búsqueda por Código">
                <ValidationProvider rules="" v-slot="{ errors }">
                  <b-input
                    v-model="request_id"
                    placeholder="Ej: SRC-15"
                  ></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>

            <div class="column is-4">
              <b-field label="Búsqueda por RUT">
                <ValidationProvider rules="runValidation" v-slot="{ errors }">
                  <b-input v-model="run" placeholder="Ej: 12345678-k"></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>

            <div class="column is-4">
              <b-field label="Búsqueda por Nombre">
                <ValidationProvider rules="" v-slot="{ errors }">
                  <b-input
                    v-model="fullname"
                    placeholder="Ingrese Nombre"
                  ></b-input>
                  <span class="validation-alert">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
            </div>
          </div>
          <div class="columns is-vcentered px-5">
            <!-- Request Type filter -->
            <div class="column is-12">
              <b-field label="Búsqueda por Tipo de Solicitud">
                <multiselect
                  v-model="selectedRequestTypeOption"
                  :options="filteredRequestTypes"
                  :custom-label="
                    (opt) => requestTypes.find((x) => x.id === opt.id).real_name
                  "
                  :multiple="false"
                  placeholder="Seleccione un tipo de solicitud"
                  selectLabel=""
                  selectedLabel=""
                  deselectLabel=""
                  track-by="id"
                  label="name"
                  @search-change="updateSearch"
                  :internal-search="false"
                >
                  <template slot="singleLabel" slot-scope="props">
                    {{ props.option.real_name }}
                    <b-tag
                      v-if="props.option.role_type"
                      size="is-small is-secondary is-light"
                    >
                      <b>{{ props.option.role_type }}</b>
                    </b-tag>
                  </template>
                  <!-- Personalización de cómo se muestran las opciones -->
                  <template v-slot:option="{ option }">
                    <div class="tags">
                      <span class="mr-1">{{ option.real_name }}</span>
                      <b-tag
                        v-if="option.role_type"
                        size="is-small is-secondary is-light"
                      >
                        <b>{{ option.role_type }}</b>
                      </b-tag>
                    </div>
                  </template>
                  <template v-slot:noOptions> No existen datos</template>
                  <span slot="noResult"> No se encontraron elementos. </span>
                </multiselect>
              </b-field>
            </div>
          </div>
          <!-- Filtros de búsqueda -->
          <div class="columns is-vcentered px-5">
            <div class="column">
              <b-collapse
                class="card"
                animation="slide"
                :open="isOpen"
                :aria-id="'contentIdForA11y5'"
              >
                <template #trigger="props">
                  <div
                    class="card-header"
                    role="button"
                    :aria-controls="'contentIdForA11y5'"
                    :aria-expanded="props.open"
                  >
                    <p
                      class="card-header-title is-flex is-justify-content-left subtitle is-3-desktop is-5-mobile has-text-secondary has-text-weight-bold is-family-secondary my-2"
                    >
                      <b-icon icon="sliders-h" class="mr-2" />
                      Más Filtros de Búsqueda
                    </p>
                    <a class="card-header-icon">
                      <b-icon
                        :icon="props.open ? 'minus-circle' : 'plus-circle'"
                        type="is-secondary"
                        size="is-medium"
                      >
                      </b-icon>
                    </a>
                  </div>
                </template>

                <div class="card-content columns is-multiline">
                  <!-- Progress filter -->
                  <div class="column is-6">
                    <b-field label="Búsqueda por Estado">
                      <multiselect
                        v-model="selectedProgressOption"
                        :options="progress"
                        :custom-label="
                          (opt) => progress.find((x) => x.id === opt.id).name
                        "
                        :multiple="false"
                        placeholder="Seleccione un estado"
                        selectLabel="Presione para seleccionar"
                        selectedLabel="Seleccionado"
                        deselectLabel="Presione para deseleccionar"
                        track-by="id"
                        label="name"
                      >
                        <template v-slot:noOptions> No existen datos</template>
                        <span slot="noResult">
                          No se encontraron elementos.
                        </span>
                      </multiselect>
                    </b-field>
                  </div>

                  <!-- Unit filter -->
                  <div class="column is-6">
                    <b-field label="Búsqueda por Unidad">
                      <multiselect
                        v-model="selectedUnitOption"
                        :options="units"
                        :custom-label="
                          (opt) => units.find((x) => x.id === opt.id).name
                        "
                        :multiple="false"
                        placeholder="Seleccione una unidad"
                        selectLabel="Presione para seleccionar"
                        selectedLabel="Seleccionado"
                        deselectLabel="Presione para deseleccionar"
                        track-by="id"
                        label="name"
                      >
                        <template v-slot:noOptions> No existen datos</template>
                        <span slot="noResult">
                          No se encontraron elementos.
                        </span>
                      </multiselect>
                    </b-field>
                  </div>

                  <!-- StartDate filter -->
                  <div class="column is-half">
                    <b-field label="Búsqueda por Fecha desde">
                      <b-datepicker
                        placeholder="Selecciona una Fecha"
                        icon="calendar-alt"
                        trap-focus
                        v-model="selectedStartDate"
                        :icon-right="selectedStartDate ? 'close-circle' : ''"
                        icon-right-clickable
                        @icon-right-click="clearStartDate"
                        :date-formatter="
                          (date) => date.toLocaleDateString('es-ES')
                        "
                        locale="es"
                      >
                      </b-datepicker>
                    </b-field>
                  </div>

                  <!-- EndDate filter -->
                  <div class="column is-half">
                    <b-field label="Búsqueda por Fecha hasta">
                      <b-datepicker
                        placeholder="Selecciona una Fecha"
                        icon="calendar-alt"
                        icon-pack="fas"
                        trap-focus
                        v-model="selectedEndDate"
                        :icon-right="selectedEndDate ? 'close-circle' : ''"
                        icon-right-clickable
                        @icon-right-click="clearEndDate"
                        :date-formatter="
                          (date) => date.toLocaleDateString('es-ES')
                        "
                        locale="es"
                      >
                      </b-datepicker>
                    </b-field>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>

          <div class="level-right buttons px-5">
            <b-button
              type="is-warning"
              icon-left="eraser"
              @click="clearFilters"
            >
              Limpiar Filtros de Búsqueda
            </b-button>
            <b-button type="is-primary" icon-left="sync" native-type="submit">
              Recargar Solicitudes
            </b-button>
            <b-button
              native-type="submit"
              type="is-secondary"
              icon-left="search"
              >Buscar
            </b-button>
          </div>

          <!--Iconografía-->
          <div class="columns is-vcentered px-5">
            <div class="column">
              <b-collapse
                class="card"
                animation="slide"
                :open="isOpen"
                :aria-id="'contentIdForA11y5'"
              >
                <template #trigger="props">
                  <div
                    class="card-header"
                    role="button"
                    :aria-controls="'contentIdForA11y5'"
                    :aria-expanded="props.open"
                  >
                    <p
                      class="card-header-title is-flex is-justify-content-left subtitle is-3-desktop is-5-mobile has-text-secondary has-text-weight-bold is-family-secondary my-2"
                    >
                      <b-icon class="mr-2" icon="info-circle"> </b-icon>
                      Iconografía
                    </p>
                    <a class="card-header-icon">
                      <b-icon
                        :icon="props.open ? 'minus-circle' : 'plus-circle'"
                        type="is-secondary"
                        size="is-medium"
                      >
                      </b-icon>
                    </a>
                  </div>
                </template>

                <div class="card-content">
                  <div class="columns is-multiline is-vcentered">
                    <div class="column is-full">
                      <p class="title">Acciones</p>
                    </div>
                    <div class="column is-full">
                      <b-button
                        class="mr-1 is-info is-light is-border-cornflowerblue"
                      >
                        <b-icon type="is-secondary" icon="eye" size="is-medium">
                        </b-icon> </b-button
                      >Ver: Permite ver y solo ver los detalles de la solicitud.
                    </div>
                    <div class="column is-full">
                      <b-button
                        class="mr-1 is-primary is-light is-border-orange"
                      >
                        <b-icon
                          type="is-primary"
                          icon="file-signature"
                          size="is-medium"
                        >
                        </b-icon>
                      </b-button>
                      Asignar Paso: Permite asignarse un paso para poder añadir
                      observaciones, revisar documentación o cambiar el estado
                      de una solicitud.
                    </div>
                    <div class="column is-full">
                      <b-button
                        class="mr-1 is-success is-light is-border-darkgreen"
                      >
                        <b-icon
                          type="is-green"
                          icon="file-signature"
                          size="is-medium"
                        ></b-icon>
                      </b-button>
                      Revisar paso: Permite entrar a revisar el paso actual de
                      una solicitud. Esta acción esta disponible una vez se ha
                      asignado un paso y es usted la persona asignada.
                    </div>

                    <div class="column is-full">
                      <b-icon
                        type="is-secondary"
                        icon="arrow-circle-right"
                        size="is-medium"
                      ></b-icon>
                      Ver información del paso de la solicitud.
                    </div>
                    <div class="column is-full">
                      <p class="title">Estados</p>
                    </div>
                    <div class="column is-full">
                      <b-tag :class="PROGRESSES['Enviada'].color">
                        <b-icon
                          :icon="PROGRESSES['Enviada'].icon"
                          class="mr-1"
                        ></b-icon
                        >Enviada
                      </b-tag>
                      {{
                        getProgressFromId(PROGRESSES["Enviada"].id)
                          ? getProgressFromId(PROGRESSES["Enviada"].id)
                              .description
                          : ""
                      }}
                    </div>
                    <template v-for="(state, key, index) of PROGRESSES">
                      <div
                        v-if="index > 0 && state.id !== 9"
                        class="column is-half"
                      >
                        <b-tag :class="state.color">
                          <b-icon :icon="state.icon" class="mr-1"> </b-icon
                          >{{ key }}
                        </b-tag>
                        {{
                          getProgressFromId(state.id)
                            ? getProgressFromId(state.id).description
                            : ""
                        }}
                      </div>
                      <div v-else-if="state.id === 9" class="column is-half">
                        <b-tag :class="state.color">
                          <b-icon
                            icon-pack="far"
                            :icon="state.icon"
                            class="mr-1"
                          >
                          </b-icon
                          >{{ key }}
                        </b-tag>
                        {{
                          getProgressFromId(state.id)
                            ? getProgressFromId(state.id).description
                            : ""
                        }}
                      </div>
                    </template>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </form>
      </ValidationObserver>

      <!-- Separator -->
      <hr class="solid" />
      <b-tabs
        v-if="requests"
        v-model="selectedTab"
        @input="selectProgress()"
        position="is-centered"
        class="block mb-0 pb-0"
        :multiline="true"
      >
        <b-tab-item label="Prioritarias" value="-3" icon="star"></b-tab-item>
        <b-tab-item
          label="Todas"
          value="0"
          icon="list"
          style="margin-right: 100px"
        ></b-tab-item>
        <b-tab-item
          label="Detenciones respondidas"
          value="-4"
          icon="comments"
        ></b-tab-item>
        <b-tab-item
          label="Vencidas"
          value="-2"
          icon="exclamation-triangle"
        ></b-tab-item>
        <b-tab-item label="Por Vencer" value="-1" icon="stopwatch"></b-tab-item>
        <b-tab-item label="Detenidas" value="4" icon="stop"></b-tab-item>
        <b-tab-item label="En Curso" value="2" icon="clock"></b-tab-item>
        <b-tab-item label="Enviadas" value="5" icon="paper-plane"></b-tab-item>
        <b-tab-item
          label="Aceptadas"
          value="6"
          icon="check-double"
        ></b-tab-item>
        <b-tab-item label="Rechazadas" value="7" icon="times"></b-tab-item>
        <b-tab-item label="Desistidas" value="8" icon="user-times"></b-tab-item>
        <b-tab-item
          label="No Corresponden"
          value="9"
          icon="file-excel"
        ></b-tab-item>
        <b-tab-item
          label="No Procesadas"
          value="10"
          icon="comment-slash"
        ></b-tab-item>
      </b-tabs>

      <table-list-requests
        v-if="!isLoading || totalRequests != null"
        :applicant_information="true"
        :actions="true"
        :requests="requests"
        :backend-pagination="true"
        :total-requests="totalRequests"
        @pageChange="onPageChange"
        :loading="isLoading"
      ></table-list-requests>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import axios from "axios";
import TableListRequests from "./TableListRequests";
import moment from "moment";
import { extend } from "vee-validate";
import { PROGRESSES } from "../../packs/utilities";
import _ from "lodash";

extend("runValidation", {
  validate(value, args) {
    return /^\d+(?:-\d+)?(?:-[1-9k])?$/i.test(value);
  },
  message: "Ingrese un run válido, por favor",
});
export default {
  name: "ListRequests",
  computed: {
    PROGRESSES() {
      return PROGRESSES;
    },
    filteredRequestTypes() {
      const normalizedSearch = this.normalizeString(this.searchRequestType);
      return this.requestTypes.filter((option) => {
        const normalizedOption = this.normalizeString(option.name);
        return normalizedOption.includes(normalizedSearch);
      });
    },
  },
  props: [],
  components: {
    TableListRequests,
  },
  data: function () {
    return {
      fullname: "",
      //Filters Options and Selected Variables
      states: [
        "",
        "Depto. Académico",
        "Secretaria FING",
        "Secretaria Académica",
        "Decano",
      ],
      progress: [],
      selectedProgressOption: null,
      selectedStateOption: null,
      units: [],
      selectedUnitOption: null,
      requestTypes: [],
      selectedRequestTypeOption: null,
      selectedStartDate: null,
      selectedEndDate: null,
      isLoading: true,
      requests: [],
      selectedTab: "0",
      run: "",
      overdue: false,
      close_overdue: false,
      priority: false,
      pending_appeal: false,
      request_id: null,
      isOpen: false,
      backendPage: 1,
      backendPerPage: 30,
      loadedPages: [],
      totalRequests: null,

      codeSent: null,
      fullnameSent: "",
      runSent: "",
      stateSent: null,
      unitSent: null,
      requestTypeSent: null,
      startDateSent: null,
      endDateSent: null,
      progressSent: null,
      searchRequestType: "",

      requestStructure: {
        id: 0,
        code: "-",
        request_state_reviewer: "-",
        reviewer_name: "-",
        applicant: "-",
        run: "-",
        request_type: "-",
        unit: "-",
        progress_of_request: "Enviada",
        start_date: null,
        end_date: null,
        state: "-",
        state_information: "-",
        description: "-",
        request_state_id: null,
        request_states: [],
      },
    };
  },

  created() {
    document.title = "Listado de solicitudes";
    this.fetchFilterDataAndRequests();
  },

  methods: {
    fetchFilterDataAndRequests() {
      let vm = this;
      vm.isLoading = true;
      axios
        .all([
          axios.get("/progresses.json").then((response) => {
            vm.progress = response.data;
          }),
          axios.get("/units.json").then((response) => {
            vm.units = response.data;
          }),
          axios.get("/request_types.json").then((response) => {
            vm.requestTypes = response.data.map((item) => {
              if (
                item.role_type === "No Titulado" ||
                item.role_type === "Titulado"
              ) {
                item.role_type = `Pregrado`;
              }
              return item;
            });
            vm.requestTypes = _.uniqBy(
              response.data,
              (item) => `${item.real_name}-${item.role_type}`
            );
          }),
          this.fetchRequests(),
        ])
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (vm.isLoading = false));
    },
    fetchRequests() {
      let vm = this;
      vm.isLoading = true;
      let params = {};
      params.page = Math.floor(
        (vm.backendPage - 1) / (vm.backendPerPage / 10) + 1
      ); // Aquí el 10 es el tamaño de página de la tabla del front
      params.per_page = vm.backendPerPage;

      return axios
        .get("/requests.json", {
          params,
        })
        .then((response) => {
          vm.totalRequests = response.data.total_requests;
          vm.mapRequests(response.data.requests);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (vm.isLoading = false));
    },

    getProgressFromId(id) {
      return this.progress.find((x) => x.id === id)
        ? this.progress.find((x) => x.id === id)
        : null;
    },
    generateName(opt) {
      let requestType = this.requestTypes.find((x) => x.id === opt.id);
    },

    runValidation() {
      return [
        (value) => {
          if (!/^d+(?:-d+)?(?:-[1-9k])?$/.test(value)) {
            return "Por favor,ingrese un run válido.";
          }
          return true;
        },
      ];
    },
    selectProgress() {
      this.backendPage = 1;
      this.loadedPages = [];
      this.requests = [];
      this.totalRequests = null;
      let option = parseInt(this.selectedTab, 10);
      this.overdue = false;
      this.priority = false;
      this.close_overdue = false;
      this.pending_appeal = false;
      this.selectedProgressOption = null;
      if (option >= 0) {
        this.selectedProgressOption = this.progress.find(
          (x) => x.id === parseInt(this.selectedTab, 10)
        );
      } else {
        switch (option) {
          case -2:
            this.overdue = true;
            break;
          case -1:
            this.close_overdue = true;
            break;
          case -3:
            this.priority = true;
            break;
          case -4:
            this.pending_appeal = true;
            break;
        }
      }
      this.applyFilters();
    },

    hasNewFilters() {
      return (
        this.codeSent !== this.request_id ||
        this.fullnameSent !== this.fullname ||
        this.runSent !== this.run ||
        this.unitSent !== this.selectedUnitOption ||
        this.requestTypeSent !== this.selectedRequestTypeOption ||
        this.startDateSent !== this.selectedStartDate ||
        this.endDateSent !== this.selectedEndDate ||
        this.progressSent !== this.selectedProgressOption
      );
    },

    storeSentFilters() {
      this.codeSent = this.request_id;
      this.fullnameSent = this.fullname;
      this.runSent = this.run;
      this.unitSent = this.selectedUnitOption;
      this.requestTypeSent = this.selectedRequestTypeOption;
      this.startDateSent = this.selectedStartDate;
      this.endDateSent = this.selectedEndDate;
      this.progressSent = this.selectedProgressOption;
    },

    applyFilters() {
      this.isLoading = true;

      if (this.hasNewFilters()) {
        this.backendPage = 1;
        this.loadedPages = [];
        this.requests = [];
        this.totalRequests = null;
      }
      this.storeSentFilters();

      let params = {
        request_id: this.request_id,
        progress_id:
          this.selectedProgressOption == null
            ? null
            : this.selectedProgressOption.id,
        unit_id:
          this.selectedUnitOption == null ? null : this.selectedUnitOption.id,
        request_type_id:
          this.selectedRequestTypeOption == null
            ? null
            : this.selectedRequestTypeOption.id,
        start_date:
          this.selectedStartDate == null
            ? null
            : this.selectedStartDate.getFullYear() +
              "-" +
              (this.selectedStartDate.getMonth() + 1) +
              "-" +
              this.selectedStartDate.getDate(),
        end_date:
          this.selectedEndDate == null
            ? null
            : this.selectedEndDate.getFullYear() +
              "-" +
              (this.selectedEndDate.getMonth() + 1) +
              "-" +
              this.selectedEndDate.getDate(),
        run: this.run == "" || this.run == null ? null : this.run,
        fullname: this.fullname,
        close_overdue: this.close_overdue,
        overdue: this.overdue,
        priority: this.priority,
        pending_appeal: this.pending_appeal,
        page: Math.floor(
          (this.backendPage - 1) / (this.backendPerPage / 10) + 1
        ),
        per_page: this.backendPerPage,
      };

      axios
        .get("/requests/apply-filters.json", {
          params,
        })
        .then((response) => {
          this.totalRequests = response.data.total_requests;
          this.mapRequests(response.data.requests);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.isLoading = false));
    },
    clearStartDate() {
      this.selectedStartDate = null;
    },
    clearEndDate() {
      this.selectedEndDate = null;
    },
    onPageChange(page) {
      if (this.selectedTab === "0") {
        this.backendPage = page;
        if (this.loadedPages.includes(page)) {
          return;
        }
        this.fetchRequests();
      } else {
        this.backendPage = page;
        if (this.loadedPages.includes(page)) {
          return;
        }
        this.applyFilters();
      }
    },
    mapRequests(requests) {
      let backendPage = Math.floor(
        (this.backendPage - 1) / (this.backendPerPage / 10) + 1
      );
      if (this.requests.length === 0) {
        this.requests = Array(this.totalRequests).fill(this.requestStructure);
      }
      for (let i = 0; i < requests.length; i++) {
        this.requests.splice(
          (backendPage - 1) * this.backendPerPage + i,
          1,
          requests[i]
        );
      }
      this.loadedPages = this.loadedPages.concat(
        Array.from({ length: this.backendPerPage / 10 }, (_, i) =>
          Math.min(
            ((backendPage - 1) * this.backendPerPage) / 10 + i + 1,
            Math.ceil(this.totalRequests / 10)
          )
        )
      );

      this.requests = this.requests.map((request) => {
        if (request === this.requestStructure) {
          return request;
        }
        let newRequests = request;
        // Si la fecha tiene T, es porque viene de la base de datos
        if (newRequests.start_date.includes("T")) {
          newRequests.start_date = moment(
            request.start_date,
            "YYYY-MM-DD HH:mm:ss"
          ).format("DD-MM-YYYY");

          newRequests.end_date =
            newRequests.end_date != null
              ? moment(request.end_date, "YYYY-MM-DD HH:mm:ss").format(
                  "DD-MM-YYYY"
                )
              : null;
        }
        return newRequests;
      });
    },
    normalizeString(str) {
      return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toUpperCase();
    },
    updateSearch(search) {
      this.searchRequestType = search;
    },
    clearFilters() {
      this.request_id = null;
      this.run = "";
      this.fullname = "";
      this.selectedRequestTypeOption = null;
      this.selectedProgressOption = null;
      this.selectedUnitOption = null;
      this.selectedStartDate = null;
      this.selectedEndDate = null;
      this.selectedTab = "0";
      this.overdue = false;
      this.close_overdue = false;
      this.priority = false;
      this.pending_appeal = false;
      this.applyFilters();
    },
  },
};
</script>

<style></style>
